import type { IJsonForm } from "../../models/IDetailsCertificates";
import { signal } from "./signal";

export const electricalConductorsRetired: IJsonForm[] = [
    {
        label: "Conductores señal de corriente",
        name: "current_signal",
        type: "signal",
        json: signal,
    },
    {
        label: "Conductores señal de tensión",
        name: "voltage_signal",
        type: "signal",
        json: signal,
    },
];
