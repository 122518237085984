/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Grid } from "@enerbit/base";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import type { ItemGetOrders } from "../../../models";
import {
    getDetailsOrder,
    useAppDispatch,
    useAppSelector,
} from "../../../store";
import {
    CancellationReason,
    OrderCancellationReason,
} from "../cancellation-reason";
import { DetailsOrder, InfoOrder } from "../info-order";
import { LeadPartnerContent } from "../lead-partner";
import Notifications from "../nofitifications/Notifications";
import { Observations } from "../observations";

export const ConsultProvider = () => {
    const { selectedOrder, action } = useAppSelector(
        (state) => state.initialForm,
    );
    const dispatch = useAppDispatch();
    const methods = useForm<ItemGetOrders>();

    const { watch, reset } = methods;

    useEffect(() => {
        if (selectedOrder) {
            dispatch(getDetailsOrder(selectedOrder));
            reset(selectedOrder);
        }
    }, [selectedOrder]);

    return (
        <FormProvider {...methods}>
            <Box component="form" noValidate>
                <Grid container rowSpacing={3}>
                    <DetailsOrder />
                    <LeadPartnerContent />
                    {(action?.activity?.code === "CC" ||
                        action?.activity?.code === "REMOV_CE_NO_INST") && (
                        <OrderCancellationReason />
                    )}
                    <InfoOrder />
                    {!!watch("order_cancel") && <CancellationReason />}
                    <Observations />
                    <Notifications />
                </Grid>
            </Box>
        </FormProvider>
    );
};
