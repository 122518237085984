import { api } from "@enerbit/base";
import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import type { IHistory } from "../../../models";

export const getHistory = createAsyncThunk(
    "history/getHistory",
    async ({ id, page }: { id: string; page: number }, { rejectWithValue }) => {
        try {
            const { data } = await api.get<IHistory>(
                `service-orders/order-historical?order_id=${id}&page=${
                    page - 1
                }&size=10`,
            );

            return {
                items: data.items.sort(
                    (a, b) =>
                        moment(b.created_at).valueOf() -
                        moment(a.created_at).valueOf(),
                ),
                total: Math.ceil(data.total / 10),
            };
        } catch (error: any) {
            return rejectWithValue(error.response?.status);
        }
    },
);
