import type { IJsonForm } from "../../models/IDetailsCertificates";

export const cabinet: IJsonForm[] = [
    {
        name: "cabinet_id",
        label: "Id del gabinete",
        type: "text",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "distribution",
        label: "Distribución del gabinete",
        type: "select",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "temporal_meters",
        label: "Distribución del gabinete",
        type: "matrix",
    },
    {
        name: "picture_url",
        label: "Fotografía del gabinete",
        type: "image",
    },
];

export const aditionalInfo: IJsonForm[] = [
    {
        name: "main_meter_installed.name",
        label: "Número del apartamento, local o casa",
        type: "text",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "assignee_id",
        label: "Lead",
        type: "select",
        sx: {
            xs: 12,
            md: 6,
        },
        items: [],
    },
];
