import {
    Box,
    Button,
    CloseIcon,
    Modal,
    type SxProps,
    Typography,
    enerbitColors,
} from "@enerbit/base";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

interface Props {
    open: boolean;
    handleClose: () => void;
}

const style: SxProps = {
    position: "absolute" as const,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    p: 2,
    borderRadius: "10px",
    width: "400px",
    height: "265px",
    overflow: "auto",
};

const MechanismSuspensionModal = ({ open, handleClose }: Props) => {
    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={style}>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "end",
                        mb: 1,
                    }}
                >
                    <Button
                        onClick={handleClose}
                        variant="outlined"
                        sx={{
                            minWidth: "unset",
                            minHeight: "unset",
                            width: "40px",
                            height: "40px",
                            borderRadius: "50% !important",
                        }}
                    >
                        <CloseIcon />
                    </Button>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                    }}
                >
                    <Box
                        sx={{
                            backgroundColor: "rgba(247, 144, 9, 1)",
                            width: "75px",
                            height: "75px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "50% !important",
                        }}
                    >
                        <ErrorOutlineIcon
                            sx={{ color: "white", fontSize: "48px" }}
                        />
                    </Box>
                    <Typography
                        sx={{
                            textAlign: "center",
                            mt: 2,
                            color: enerbitColors.primary.main,
                            fontSize: "18px",
                        }}
                    >
                        Recuerda que el cliente puede ser reconectado o
                        suspendido remotamente.
                    </Typography>
                </Box>
            </Box>
        </Modal>
    );
};

export default MechanismSuspensionModal;
