import {
    Box,
    CloseIcon,
    ContentPasteSearchOutlinedIcon,
    EditOutlinedIcon,
    Grid,
    RemoveRedEyeOutlinedIcon,
    Stack,
    Tooltip,
    Typography,
    motion,
} from "@enerbit/base";
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { FC } from "react";
import { useNavigate } from "react-router-dom";
import { StyledChip, StyledIconButton } from "../../../common";
import { type EEntity, formatDate } from "../../../helpers";
import { enabledCertificates } from "../../../helpers/enabledCertificate";
import type { ActionType, ItemGetOrders, OrderType } from "../../../models";
import {
    selectOrder,
    setAction,
} from "../../../store/slices/initial-form/initialForm";
import { useAppDispatch } from "../../../store/store";

interface Props {
    order: ItemGetOrders;
    entity: EEntity;
    index: number;
}

export const OrderItem: FC<Props> = ({ order, entity, index }) => {
    const { order_type, planned_date_begin, status } = order;

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleAction = (action: ActionType, activity: OrderType) => {
        dispatch(selectOrder(order));
        const actionsToRoutes: Record<ActionType, string> = {
            Consult: `/${order.id}`,
            Cancel: `/cancel/${order.id}`,
            Edit: `/edit/${order.id}`,
            Certifications: `/certifications/${order.id}`,
            Create: "",
        };

        dispatch(
            setAction({
                type: action,
                entity,
                activity,
                isFromSearch: true,
            }),
        );

        navigate(actionsToRoutes[action]);
    };

    return (
        <Grid item xs={12} md={6} lg={4}>
            <motion.div
                initial={{ opacity: 0, x: -10 }} // El estado inicial del elemento: transparente y un poco arriba
                animate={{ opacity: 1, x: 0 }} // El estado final del elemento: opaco y en su posición original
                exit={{ opacity: 0, x: -10 }} // El estado de salida, si lo necesitas, sería desaparecer y moverse un poco arriba
                transition={{ delay: index * 0.25 }} // El delay hace que cada elemento empiece su animación medio segundo después del anterior
                style={{ display: "flex", height: "100%" }}
            >
                <Box
                    sx={{
                        p: 3,
                        border: "1px solid #E4E7EC",
                        borderRadius: "12px",
                        color: "#667085",
                        display: "flex",
                        height: "100%", // Asegura que tome toda la altura disponible
                        width: "100%",
                    }}
                >
                    <Box width={"60%"}>
                        <Typography fontSize={"18px"} fontWeight="bold">
                            {order_type.name}
                        </Typography>
                        <Typography fontSize={"14px"}>
                            Fecha: {formatDate(planned_date_begin)}
                        </Typography>
                        <Box mt={2}>
                            {status === "done" && (
                                <StyledChip
                                    label="Completada"
                                    sx={{
                                        backgroundColor: "success.100",
                                        color: "success.main",
                                    }}
                                />
                            )}
                            {status === "pending" && (
                                <StyledChip
                                    label="Pendiente"
                                    sx={{
                                        backgroundColor: "warning.200",
                                        color: "warning.main",
                                    }}
                                />
                            )}
                            {status === "cancel" && (
                                <StyledChip
                                    label="Cancelada"
                                    sx={{
                                        backgroundColor: "error.100",
                                        color: "error.main",
                                    }}
                                />
                            )}
                            {status === "partial_done" && (
                                <StyledChip
                                    label="Completada parcial"
                                    sx={{
                                        backgroundColor: "info.100",
                                        color: "info.main",
                                        border: "2px dashed",
                                        borderColor: "info.main",
                                    }}
                                />
                            )}
                            {status === "pending_assignment" && (
                                <StyledChip
                                    label="En preparación"
                                    sx={{
                                        backgroundColor: "neutral.200",
                                        color: "neutral.main",
                                        border: "2px dashed",
                                        borderColor: "neutral.main",
                                    }}
                                />
                            )}
                        </Box>
                    </Box>
                    <Stack
                        direction="row"
                        spacing={1}
                        sx={{
                            width: "40%",
                            justifyContent: "end",
                            alignItems: "center",
                        }}
                    >
                        {(status === "pending" ||
                            status === "pending_assignment") && (
                            <>
                                <Tooltip title="Editar orden de servicio">
                                    <StyledIconButton
                                        color="warning"
                                        onClick={() =>
                                            handleAction("Edit", order_type)
                                        }
                                    >
                                        <EditOutlinedIcon />
                                    </StyledIconButton>
                                </Tooltip>
                                <Tooltip title="Cancelar orden de servicio">
                                    <StyledIconButton
                                        color="error"
                                        onClick={() =>
                                            handleAction("Cancel", order_type)
                                        }
                                    >
                                        <CloseIcon />
                                    </StyledIconButton>
                                </Tooltip>
                            </>
                        )}
                        <Tooltip title="Detalle de la orden">
                            <StyledIconButton
                                color="primary"
                                onClick={() =>
                                    handleAction("Consult", order_type)
                                }
                            >
                                <RemoveRedEyeOutlinedIcon />
                            </StyledIconButton>
                        </Tooltip>
                        {(status === "done" || status === "partial_done") &&
                            enabledCertificates(order_type.code) && (
                                <Tooltip title="Ver acta">
                                    <StyledIconButton
                                        color="info"
                                        onClick={() =>
                                            handleAction(
                                                "Certifications",
                                                order_type,
                                            )
                                        }
                                    >
                                        <ContentPasteSearchOutlinedIcon />
                                    </StyledIconButton>
                                </Tooltip>
                            )}
                    </Stack>
                </Box>
            </motion.div>
        </Grid>
    );
};
