/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
    Box,
    Button,
    Grid,
    RemoveRedEyeOutlinedIcon,
    Typography,
    enerbitColors,
    useSnackbar,
} from "@enerbit/base";
import { type FC, useState } from "react";
import { useFormContext } from "react-hook-form";
import type { IJsonForm } from "../../../../models/IDetailsCertificates";
import { loadImage } from "../../../../services";
import { PreviewImageModal } from "../../modals";

export const ImageForm: FC<IJsonForm> = ({ name, label }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { getValues } = useFormContext();
    const [previewImage, setPreviewImage] = useState("");
    const [openPreviewImage, setOpenPreviewImage] = useState(false);
    const handleOpenPreviewImage = async () => {
        await loadImage(getValues(name ?? ""))
            .then((image) => {
                setPreviewImage(image);
                setOpenPreviewImage(true);
            })
            .catch(() =>
                enqueueSnackbar("No se pudo cargar la imagen.", {
                    variant: "error",
                }),
            );
    };
    const handleClosePreviewImage = () => setOpenPreviewImage(false);

    return (
        <Grid item xs={12}>
            {getValues(name ?? "") ? (
                <Box width="100%" className="box-certification-edit">
                    <Box width={"100%"} p={"16px 32px"}>
                        <Grid container spacing={2}>
                            <Grid
                                item
                                xs={6}
                                xl={8}
                                display="flex"
                                alignItems="center"
                            >
                                <Typography
                                    sx={{
                                        fontWeight: "bold",
                                        color: enerbitColors.primary.main,
                                    }}
                                >
                                    {label}
                                </Typography>
                            </Grid>
                            <Grid
                                container
                                xs={6}
                                xl={4}
                                item
                                spacing={2}
                                display="flex"
                                justifyContent="end"
                            >
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        startIcon={<RemoveRedEyeOutlinedIcon />}
                                        className="edit-button"
                                        onClick={handleOpenPreviewImage}
                                    >
                                        Ver foto
                                    </Button>
                                    <PreviewImageModal
                                        open={openPreviewImage}
                                        handleClose={handleClosePreviewImage}
                                        previewImage={previewImage}
                                        label={label!}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            ) : (
                <Grid container spacing={3}>
                    <Grid item>
                        <Typography
                            sx={{
                                fontWeight: "bold",
                                color: enerbitColors.primary.main,
                            }}
                        >
                            {label}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Box className="border-file" width={"100%"}>
                            <Grid
                                p={4}
                                container
                                columnSpacing={2}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Grid item xs={12}>
                                    <Typography textAlign={"center"}>
                                        Aún no hay foto.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};
