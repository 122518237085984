import type { IJsonForm } from "../../models/IDetailsCertificates";
import { seals } from "./seals";

export const electricalBoxesInstalled: IJsonForm[] = [
    {
        label: "Panel",
        isSection: true,
        type: "array",
        json: [
            {
                label: "La caja/Panel es:",
                type: "select",
                name: "is_installed",
                sx: {
                    xs: 12,
                    md: 6,
                },
                items: [
                    {
                        text: "Instalado",
                        value: "true",
                    },
                    {
                        text: "Encontrado",
                        value: "false",
                    },
                ],
            },
            {
                label: "Elemento al que corresponde",
                type: "select",
                name: "usage",
                sx: {
                    xs: 12,
                    md: 6,
                },
            },
            {
                label: "Fabricante",
                type: "text",
                name: "manufacturer.value",
                sx: {
                    xs: 12,
                    md: 6,
                },
            },
            {
                label: "Año de fabricación",
                name: "manufacture_year.value",
                type: "number",
                sx: {
                    xs: 12,
                    md: 6,
                },
            },
            {
                label: "Foto de la caja",
                name: "manufacturer.picture_url",
                type: "image",
            },
            {
                label: "Foto de la placa (caja)",
                name: "manufacture_year.picture_url",
                type: "image",
                sx: {
                    xs: 12,
                    md: 6,
                },
            },
            {
                label: "Estado visual de la caja",
                name: "state",
                type: "select",
                sx: {
                    xs: 12,
                    md: 6,
                },
            },
            {
                label: "Sello",
                name: "seals",
                type: "seals",
                json: seals,
            },
        ],
    },
];
