import {
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
} from "@enerbit/base";
import type { FC } from "react";
import { StyledDialog, StyledDialogTitle } from "../../../common";

interface Props {
    open: boolean;
    handleClose: () => void;
    deleteItemId: () => void;
    message: string;
}

export const DeleteItemModalId: FC<Props> = ({
    open,
    handleClose,
    deleteItemId,
    message,
}) => {
    const handleCancel = () => {
        handleClose();
    };

    const handleAccept = () => {
        deleteItemId();
        handleClose();
    };

    return (
        <StyledDialog
            open={open}
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            maxWidth="xs"
        >
            <StyledDialogTitle
                id="customized-dialog-title"
                onClose={handleCancel}
            >
                {message}
            </StyledDialogTitle>
            <DialogContent>
                <DialogContentText textAlign="center">
                    Recuerde que una vez lo elimine, no podrá ser recuperado.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button id="back-btn" onClick={handleClose}>
                    Volver
                </Button>
                <Button
                    id="delete-btn"
                    type="button"
                    onClick={handleAccept}
                    autoFocus
                    variant="contained"
                    className="orange-button"
                >
                    Aceptar
                </Button>
            </DialogActions>
        </StyledDialog>
    );
};
