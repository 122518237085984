import {
    Badge,
    Box,
    Button,
    FilterListIcon,
    Menu,
    enerbitColors,
} from "@enerbit/base";
import moment from "moment";
import { type FC, useCallback, useRef, useState } from "react";
import { DateRange } from "react-date-range";
import type { EEntity } from "../../../helpers";
import { useAppDispatch, useAppSelector } from "../../../store";
import { setDateRange } from "../../../store/slices/list-orders/listOrders";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme cs

interface Props {
    entity: EEntity;
    disabled?: boolean;
}

export const DynamicDateRange: FC<Props> = ({ entity, disabled }) => {
    const { dateRange, loadingOrder } = useAppSelector(
        (state) => state.listOrders.orders[entity],
    );
    const dispatch = useAppDispatch();
    const buttonRef = useRef<HTMLButtonElement | null>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleOpen = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
        },
        [],
    );

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const handleSelect = useCallback(
        (ranges: any) => {
            const { startDate, endDate } = ranges.selection;
            const isSameStart = moment(startDate).isSame(
                moment(dateRange.startDate),
                "day",
            );
            const isSameEnd = moment(endDate).isSame(
                moment(dateRange.endDate),
                "day",
            );

            if (isSameStart && isSameEnd) {
                dispatch(
                    setDateRange({
                        range: {
                            startDate: "",
                            endDate: "",
                        },
                        entity,
                    }),
                );
            } else {
                // Update the date range
                dispatch(
                    setDateRange({
                        range: {
                            startDate: moment(startDate).toISOString(),
                            endDate: moment(endDate).toISOString(),
                        },
                        entity,
                    }),
                );
            }
            handleClose();
        },
        [dateRange, entity],
    );

    const hasDateRange = dateRange.startDate && dateRange.endDate;

    return (
        <Box sx={{ width: "100%" }}>
            <Badge
                badgeContent={""}
                className={hasDateRange ? "ping" : ""}
                color="primary"
                variant="dot"
                invisible={!hasDateRange}
                sx={{ width: "100%" }}
            >
                <span className={hasDateRange ? "ping-animation" : ""}></span>
                <Button
                    id="dynamic-date"
                    fullWidth
                    ref={buttonRef}
                    disabled={disabled}
                    size="large"
                    sx={{
                        border: "1px solid #D0D5DD",
                        width: "100%",
                        whiteSpace: "nowrap",
                    }}
                    startIcon={<FilterListIcon sx={{ mt: -0.5 }} />}
                    onClick={handleOpen}
                >
                    Fecha de la Orden
                </Button>
            </Badge>
            <Menu
                anchorEl={anchorEl}
                open={!loadingOrder && Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
                MenuListProps={{ "aria-labelledby": "basic-button" }}
                slotProps={{
                    paper: {
                        style: {
                            minWidth: anchorEl ? anchorEl.clientWidth : "100%",
                        },
                    },
                }}
            >
                <DateRange
                    showDateDisplay={false}
                    editableDateInputs={true}
                    ranges={[
                        {
                            startDate: dateRange.startDate
                                ? new Date(dateRange.startDate)
                                : undefined,
                            endDate: dateRange.endDate
                                ? new Date(dateRange.endDate)
                                : undefined,
                            key: "selection",
                        },
                    ]}
                    onChange={handleSelect}
                    moveRangeOnFirstSelection={false}
                    retainEndDateOnFirstSelection
                    rangeColors={[enerbitColors.primary.main]}
                />
            </Menu>
        </Box>
    );
};
