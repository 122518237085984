/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Box, Grid, LoadingButton, SaveIcon, Typography } from "@enerbit/base";
import { useEffect } from "react";
import { EEntity } from "../../../helpers";
import type { RoleCode } from "../../../models/IRoles";
import {
    getActivities,
    getCertificates,
    getEntities,
    getMeasurementType,
} from "../../../store";
import { setAction } from "../../../store/slices/initial-form/initialForm";
import { createOrderPermissions } from "../../../store/slices/roles/roles";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { EntityFiltered } from "../entity";
// import { useNavigate } from "react-router-dom";

export const ResultsFilter = () => {
    const { entity, loadingCertificates, addedEntities } = useAppSelector(
        (state) => state.initialForm,
    );
    const { orders } = useAppSelector((state) => state.listOrders);
    const { role } = useAppSelector((state) => state.roles);
    const dispatch = useAppDispatch();

    // const navigate = useNavigate();

    const entityDictionary: Record<EEntity, string> = {
        MP: "Cliente",
        LD: "Lead",
        SMB: "smartBit",
    };

    const getData = async () => {
        dispatch(getActivities());
        dispatch(getEntities());
        dispatch(createOrderPermissions({ role: role as RoleCode, entity }));
        dispatch(
            getMeasurementType({
                id: addedEntities[0].id,
                is_lead: entity === EEntity.Lead,
            }),
        );
        if (entity && entity === EEntity.Client)
            dispatch(getCertificates(addedEntities[0].id));
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <Grid container spacing={3} item>
            <Grid item>
                <Typography>Resultados</Typography>
            </Grid>
            <Grid container item>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "end",
                        width: "100%",
                    }}
                >
                    <Typography
                        color="primary"
                        sx={{ fontSize: "28px", fontWeight: 400 }}
                    >
                        {entity && entityDictionary[entity]}
                    </Typography>
                    <LoadingButton
                        id="generate-os"
                        loading={
                            orders.LD.loadingOrder ||
                            orders.MP.loadingOrder ||
                            orders.SMB.loadingOrder ||
                            loadingCertificates
                        }
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        // disabled={EEntity.Smartbit === entity && (!edges || edges.length === 0)}
                        // disabled={
                        //   EEntity.Smartbit === entity &&
                        //   (!nodes ||
                        //     nodes
                        //       .filter(({ type }) => type === "meter")
                        //       .some(({ data }) => !data.meter.validated))
                        // }
                        color="secondary"
                        variant="contained"
                        onClick={() => {
                            if (entity) {
                                dispatch(setAction({ entity, type: "Create" }));
                                // navigate("/create");
                            }
                        }}
                    >
                        {orders.LD.loadingOrder ||
                        orders.MP.loadingOrder ||
                        orders.SMB.loadingOrder
                            ? "Cargando ordenes de servicio..."
                            : "Generar orden de servicio"}
                    </LoadingButton>
                </Box>
            </Grid>
            <EntityFiltered />
        </Grid>
    );
};
