import {
    Autocomplete,
    CircularProgress,
    Grid,
    InputAdornment,
    SearchIcon,
    TextField,
    Typography,
} from "@enerbit/base";
import { SyntheticEvent, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDebouncedCallback } from "use-debounce";
import { getOperators, useAppDispatch, useAppSelector } from "../../../store";
import { resetListOperators } from "../../../store/slices/team-operators/teamOperators";
import { OptionSearchItem } from "./OptionSearchItem";

export const SearchOperator = () => {
    const { loadingOperator, listOperators } = useAppSelector(
        (state) => state.teamOperators,
    );
    const dispatch = useAppDispatch();

    const { control } = useForm({
        defaultValues: {
            search_operator: "",
        },
    });

    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (!open) {
            dispatch(resetListOperators());
        }
    }, [open]);

    const debounced = useDebouncedCallback((value) => {
        value && onSearchOperator(value);
    }, 500);

    const onSearchOperator = (value: string) => {
        dispatch(getOperators(value.toLowerCase()));
    };

    const handleSearchChange = (e: SyntheticEvent<Element, Event>) => {
        const inputValue = (e.target as HTMLInputElement).value;
        if (inputValue) {
            debounced(inputValue);
        } else {
            dispatch(resetListOperators());
        }
    };

    return (
        <>
            {/* <Grid
          item
          xs={4}
          md={2}
          sx={{ display: "flex", alignItems: "end", pb: 0.25 }}
        >
          <DynamicFilter
            isUnSelectable={false}
            fullWidth
            options={[
              {
                key: "Operador",
                value: "operador",
              },
              {
                key: "Cuadrilla",
                value: "cuadrilla",
              },
            ]}
            selected={search}
            onSelect={setSearch}
            label={"Seleccione"}
          />
        </Grid> */}
            <Grid item xs={12}>
                <Typography className="title-input-edition">
                    Operador asignado
                </Typography>
                <Controller
                    name="search_operator"
                    control={control}
                    render={({ field: { onChange, value, ref } }) => (
                        <Autocomplete
                            id="search_operator"
                            loadingText="Cargando..."
                            noOptionsText="No hay operadores asignados."
                            fullWidth
                            open={open}
                            value={
                                value
                                    ? listOperators.find((option) => {
                                          return value === option.id;
                                      }) ?? null
                                    : null
                            }
                            onInputChange={(event, newInputValue) => {
                                if (newInputValue) {
                                    handleSearchChange(event);
                                }
                            }}
                            onChange={(_event, newValue) => {
                                onChange(newValue ? newValue.id : "");
                            }}
                            onOpen={() => {
                                setOpen(true);
                            }}
                            onClose={() => {
                                setOpen(false);
                            }}
                            isOptionEqualToValue={(option, value) =>
                                option.pii.email === value.pii.email
                            }
                            getOptionLabel={(option) => option.pii.email}
                            options={listOperators}
                            loading={loadingOperator}
                            renderOption={(_props, option) => {
                                return (
                                    <OptionSearchItem
                                        option={option}
                                        key={option.id + option.pii.email}
                                    />
                                );
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    ref={ref}
                                    sx={{
                                        "& fieldset": { borderRadius: "14px" },
                                    }}
                                    fullWidth
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {loadingOperator ? (
                                                    <CircularProgress
                                                        size={20}
                                                        thickness={6}
                                                    />
                                                ) : (
                                                    <SearchIcon />
                                                )}
                                                {params.InputProps.endAdornment}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    )}
                />
            </Grid>
        </>
    );
};
