import { Box, Grid, Skeleton } from "@enerbit/base";
import type { FC } from "react";

interface Props {
    count?: number;
}

export const LoadingOrderItem: FC<Props> = ({ count = 9 }) => {
    return (
        <>
            {Array.from({ length: count }).map((_, index) => (
                <Grid item xs={12} md={6} lg={4} key={index.toString()}>
                    <Box
                        sx={{
                            p: 3,
                            border: "1px solid #E4E7EC",
                            borderRadius: "12px",
                        }}
                    >
                        <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Grid item xs>
                                <Grid container direction="column" spacing={2}>
                                    <Grid item>
                                        <Skeleton
                                            variant="text"
                                            width="80%"
                                            height="20px"
                                        />
                                        <Skeleton
                                            variant="text"
                                            width="70%"
                                            height="16px"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Skeleton
                                            variant="rectangular"
                                            width="80px"
                                            height="28px"
                                            sx={{ borderRadius: "12px" }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Box sx={{ gap: 1, display: "flex" }}>
                                    <Skeleton
                                        variant="rectangular"
                                        width={34}
                                        height={34}
                                        sx={{ borderRadius: "6px" }}
                                    />
                                    <Skeleton
                                        variant="rectangular"
                                        width={34}
                                        height={34}
                                        sx={{ borderRadius: "6px" }}
                                    />
                                    <Skeleton
                                        variant="rectangular"
                                        width={34}
                                        height={34}
                                        sx={{ borderRadius: "6px" }}
                                    />
                                    <Skeleton
                                        variant="rectangular"
                                        width={34}
                                        height={34}
                                        sx={{ borderRadius: "6px" }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            ))}
        </>
    );
};
