import { Box, Grid, enerbitColors } from "@enerbit/base";
import { type FC, useRef } from "react";
import { useFormContext } from "react-hook-form";
import type { IJsonForm } from "../../../../models/IDetailsCertificates";

export const SignatureForm: FC<IJsonForm> = ({ name }) => {
    const { watch } = useFormContext();
    const containerRef = useRef<HTMLDivElement | null>(null);

    return (
        <Grid item xs={12}>
            <Box
                width={"100%"}
                ref={containerRef}
                sx={{
                    backgroundColor: enerbitColors.neutral[100],
                    borderRadius: "16px",
                }}
            >
                {watch(name ?? "") && (
                    <img
                        src={watch(name ?? "")}
                        alt="Signature"
                        width="100%"
                        style={{ objectFit: "contain" }}
                        height="200px"
                    />
                )}
            </Box>
        </Grid>
    );
};
