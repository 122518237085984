import { api } from "@enerbit/base";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    IRespWorkGroups,
    IResponseOperators,
    OrderOperatorGetOrders,
} from "../../../models";

export const getOperators = createAsyncThunk(
    "teamOperators/getOperators",
    async (email: string) => {
        const { data } = await api.get<IResponseOperators>(
            `/ops-users/operators/?username=${email}&page=0&size=5&rol_code=operacion_energia_digital,opr&is_active=true`,
        );
        const operators: OrderOperatorGetOrders[] = data.items.map(
            ({ id, created_at, updated_at, pii, rol, username, company }) => ({
                operator_id: id,
                created_at: new Date(created_at).toISOString(), // convertir a string
                updated_at: updated_at
                    ? new Date(updated_at).toISOString()
                    : null, // convertir a string o null
                pii: {
                    full_name: pii ? pii?.full_name : "",
                    legal_id_type: pii ? pii?.legal_id_type : "",
                    legal_id_code: pii ? pii?.legal_id_code : "",
                    phone: pii ? pii?.phone : "",
                    rol: rol.name,
                    email: username,
                    company: {
                        name: company.name,
                        legal_id_code: company.legal_id_code,
                        address: company.address,
                        phone: company.phone,
                        email: company.address,
                        id: company.id,
                        created_at: new Date(company.created_at).toISOString(), // convertir a string
                        updated_at: company.updated_at
                            ? new Date(company.updated_at).toISOString()
                            : null, // convertir a string o null
                    },
                },
            }),
        );
        return operators;
    },
);

export const getTeams = createAsyncThunk(
    "teamOperators/getTeams",
    async (email: string) => {
        const { data } = await api.get<IRespWorkGroups>(
            `/work-groups/work-groups?page=0&size=5&username=${email}`,
        );
        return data.items.filter(({ operators }) => operators);
    },
);

export const addOrderOperators = createAsyncThunk(
    "teamOperators/addOrderOperators",
    async (
        {
            order_id,
            operator,
        }: {
            order_id: string;
            operator: OrderOperatorGetOrders;
        },
        { rejectWithValue },
    ) => {
        try {
            const { data } = await api.post<{ id: string }>(
                `/service-orders/order-operators`,
                {
                    order_id,
                    operator_id: operator.operator_id,
                },
            );
            return { ...operator, id: data.id };
        } catch (error) {
            return rejectWithValue("Ha ocurrido un error.");
        }
    },
);

export const deleteOrderOperators = createAsyncThunk(
    "teamOperators/deleteOrderOperators",
    async (
        { id, operator_id }: { id: string; operator_id: string },
        { rejectWithValue },
    ) => {
        try {
            await api.delete(
                `/service-orders/order-operators/${id}?operator_id=${operator_id}`,
            );
            return id;
        } catch (error) {
            return rejectWithValue("Ha ocurrido un error.");
        }
    },
);
