import { Box, Grid, Typography } from "@enerbit/base";
import { EEntity } from "../../../helpers";
import { useAppSelector } from "../../../store";
import { SmartbitFlow } from "../smarbit";

export const EntityFiltered = () => {
    const { addedEntities, entity } = useAppSelector(
        (state) => state.initialForm,
    );

    return (
        <>
            {EEntity.Smartbit !== entity ? (
                <Grid item xs={12}>
                    <Box className={"box-certification-edit"} p={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography
                                    component="span"
                                    variant="h6"
                                    sx={{
                                        color: "#53358E",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {`${addedEntities[0]?.full_name}`}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant="body1"
                                    sx={{ color: "#344054" }}
                                >
                                    <b>Dirección:</b>{" "}
                                    {addedEntities[0]?.address}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    sx={{ color: "#344054" }}
                                >
                                    <b>Departamento:</b>{" "}
                                    {addedEntities[0]?.department}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    sx={{ color: "#344054" }}
                                >
                                    <b>Ciudad:</b> {addedEntities[0]?.city}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            ) : (
                <SmartbitFlow />
            )}
        </>
    );
};
