import {
    NotiProvider,
    PrivateRoute,
    ThemeConfig,
    initEnviroment,
} from "@enerbit/base";
import { hot } from "react-hot-loader/root";
import { Provider } from "react-redux";
import { Orders } from "./pages/Orders";
import { store } from "./store/store";

initEnviroment({
    baseUrl: process.env.REACT_APP_BASE_URL,
    geopoliticsBaseUrl: process.env.REACT_APP_BASE_GEOPOLITICS_URL,
});

export default hot(function Root() {
    return (
        <PrivateRoute>
            <Provider store={store}>
                <ThemeConfig>
                    <NotiProvider>
                        <Orders />
                    </NotiProvider>
                </ThemeConfig>
            </Provider>
        </PrivateRoute>
    );
});
