import { Box, Button, Grid, Typography } from "@enerbit/base";
import { type FC, useState } from "react";
import type { ItemGetOrders, OrderEntityGetOrders } from "../../../models";
import { AssociatedModal } from "../modals";

interface Props {
    associatedLead: OrderEntityGetOrders;
    order: ItemGetOrders;
}

export const AssociatedItem: FC<Props> = ({ associatedLead }) => {
    const [open, setOpen] = useState(false);

    return (
        <Grid item xs={4}>
            <Box bgcolor={"white"} borderRadius={"14px"} p={2} height={"100%"}>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <Typography fontWeight={"bold"}>
                            {associatedLead.pii.full_name}
                        </Typography>
                        <Typography fontWeight={"light"}>Cliente</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            id="watch-certification"
                            color="secondary"
                            variant="contained"
                            size="small"
                            onClick={() => setOpen(true)}
                        >
                            Ver Acta
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            {open && (
                <AssociatedModal
                    open={open}
                    handleClose={() => setOpen(false)}
                    associatedLead={associatedLead}
                />
            )}
        </Grid>
    );
};
