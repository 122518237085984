import { decodedJwt } from "@enerbit/base";
import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { EEntity } from "../../../helpers";
import { definePermissions } from "../../../helpers/roles";
import type { IRolesState, RoleCode } from "../../../models/IRoles";

const initialState: IRolesState = {
    role: "",
    canCreateOrders: false,
};

const roles = createSlice({
    name: "roles",
    initialState,
    reducers: {
        getRole: (state) => {
            const decodedToken = decodedJwt();
            if (decodedToken) {
                state.role = decodedToken.role;
            }
        },
        createOrderPermissions: (
            state,
            {
                payload,
            }: PayloadAction<{ role: RoleCode; entity: EEntity | null }>,
        ) => {
            state.canCreateOrders = definePermissions(
                payload.role,
                payload.entity,
            );
        },
    },
});

export const { getRole, createOrderPermissions } = roles.actions;

export default roles.reducer;
