import {
    Grid,
    Accordion as MuiAccordion,
    AccordionDetails as MuiAccordionDetails,
    AccordionSummary as MuiAccordionSummary,
    Typography,
    enerbitColors,
    styled,
    theme,
} from "@enerbit/base";
import { type FC, type SyntheticEvent, useState } from "react";
import type { IJsonForm } from "../../../../models/IDetailsCertificates";
import { FormItem } from "../FormItem";

const Accordion = styled(MuiAccordion)`
  border-radius: 16px !important;
`;

const AccordionSummary = styled(MuiAccordionSummary)`
  min-height: 56px;
  border-radius: 16px !important;
  &.Mui-expanded {
    min-height: 56px;
    border-radius: 16px 16px 0px 0px !important;
  }
`;

const AccordionDetails = styled(MuiAccordionDetails)`
  padding: 16px;
`;

export const SignalForm: FC<IJsonForm> = ({ name, json, label }) => {
    const [expanded, setExpanded] = useState<number | null>(null);

    const handleChange =
        (index: number) => (_: SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? index : null);
        };

    return (
        <Grid item xs={12}>
            <Accordion
                expanded={expanded === 0}
                onChange={handleChange(0)}
                sx={{
                    border: `1px solid ${enerbitColors.primary.main}`,
                }}
            >
                <AccordionSummary
                    sx={{
                        background: enerbitColors.primary.main,
                        padding: theme.spacing(1, 2),
                    }}
                >
                    <Typography sx={{ fontWeight: 700, color: "#fff" }}>
                        {label}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails
                    sx={{
                        padding: theme.spacing(3),
                        borderTop: "1px solid rgba(0, 0, 0, .125)",
                    }}
                >
                    <Grid container spacing={3}>
                        {json &&
                            json?.map((item, i) => {
                                const currentField = {
                                    ...item,
                                    name: `${name}.${item.name}`,
                                };
                                return (
                                    <FormItem field={currentField} key={i} />
                                );
                            })}
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid>
    );
};
