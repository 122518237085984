import {
    Box,
    Grid,
    IconButton,
    LoadingButton,
    Typography,
    enerbitColors,
} from "@enerbit/base";
import UpdateIcon from "@mui/icons-material/Update";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { get } from "react-hook-form";
import { stepperList } from "../../../const/stepper.config";
import { getPercentComplete } from "../../../helpers";
import { getOrder } from "../../../store";
import { handleDetails } from "../../../store/slices/detail-progress/detailProgress";
import { useAppDispatch, useAppSelector } from "../../../store/store";

export const DetailsOrder = () => {
    const [percentComplete, setPercentComplete] = useState(0);

    const { loadingDetails, detailsOrder } = useAppSelector(
        (state) => state.detailProgress,
    );
    const { selectedOrder, activity } = useAppSelector(
        (state) => state.initialForm,
    );
    const status = useAppSelector(
        (state) => state.initialForm.selectedOrder?.status,
    );
    const dispatch = useAppDispatch();
    const theme = useTheme();

    const getValidFields = () => {
        const filteredFields: any = {};

        stepperList.forEach(({ sectionName, code }) => {
            const detail = get(detailsOrder, sectionName);

            if (
                detail !== undefined &&
                (activity?.code !== "SSE" || code?.includes(activity.code))
            ) {
                filteredFields[sectionName] = detail;
            }
        });
        return filteredFields;
    };

    useEffect(() => {
        setPercentComplete(
            status === "done" || status === "partial_done"
                ? 100
                : getPercentComplete(getValidFields()),
        );
    }, [percentComplete, detailsOrder]);

    return (
        <Grid container item xs={12} spacing={3}>
            <Grid item xs={12} lg={6}>
                <Box
                    sx={{
                        borderRadius: "14px",
                        p: 3,
                        border: `2px ${
                            status === "partial_done" ||
                            status === "pending_assignment"
                                ? "dashed"
                                : "solid"
                        } ${
                            status === "done"
                                ? enerbitColors.success.main
                                : status === "partial_done"
                                  ? enerbitColors.information.main
                                  : status === "cancel"
                                    ? enerbitColors.error.main
                                    : status === "pending_assignment"
                                      ? enerbitColors.neutral.main
                                      : enerbitColors.warning.main
                        }`,
                        color:
                            status === "done"
                                ? enerbitColors.success.main
                                : status === "partial_done"
                                  ? enerbitColors.information.main
                                  : status === "cancel"
                                    ? enerbitColors.error.main
                                    : status === "pending_assignment"
                                      ? enerbitColors.neutral.main
                                      : enerbitColors.warning.main,
                    }}
                >
                    <Typography variant="h6" sx={{ fontWeight: "400" }}>
                        Estado actual de la orden
                    </Typography>
                    <Typography variant="h4" sx={{ fontWeight: "700" }}>
                        {status === "done"
                            ? "Completada"
                            : status === "partial_done"
                              ? "Completada parcial"
                              : status === "cancel"
                                ? "Cancelada"
                                : status === "pending_assignment"
                                  ? "En preparación"
                                  : "Pendiente"}
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
                <Box
                    display={"flex"}
                    justifyContent="space-between"
                    alignItems={"center"}
                    sx={{
                        borderRadius: "14px",
                        p: 3,
                        border: `2px solid ${enerbitColors.primary.main}`,
                        color: enerbitColors.primary.main,
                    }}
                >
                    <Box>
                        <Typography variant="h6" sx={{ fontWeight: "400" }}>
                            Estado de progreso de la orden
                        </Typography>
                        <Typography variant="h4" sx={{ fontWeight: "700" }}>
                            {percentComplete}%
                        </Typography>
                    </Box>
                    <Box>
                        <Grid container spacing={1}>
                            <Grid item>
                                <LoadingButton
                                    loading={loadingDetails}
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => dispatch(handleDetails())}
                                >
                                    Ver detalle
                                </LoadingButton>
                            </Grid>
                            <Grid item>
                                <IconButton
                                    onClick={() =>
                                        selectedOrder
                                            ? dispatch(
                                                  getOrder(
                                                      selectedOrder?.id ?? "",
                                                  ),
                                              )
                                            : null
                                    }
                                    disabled={loadingDetails}
                                    color="primary"
                                >
                                    <UpdateIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
};
