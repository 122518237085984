import { FormControl, Grid, InputLabel, MenuItem, Select } from "@enerbit/base";
import type { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import type { IJsonForm } from "../../../../models/IDetailsCertificates";

export const SelectForm: FC<IJsonForm> = ({ name, label, items, sx }) => {
    const { control, getValues, watch } = useFormContext();

    const toCapitalizeCase = (str: string) => {
        const words = str.split(" ");
        return words
            .map((word) => {
                if (
                    words.length === 1 &&
                    (/^\d/.test(word) || word.length === 2)
                ) {
                    return word.toUpperCase();
                }
                return (
                    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                );
            })
            .join(" ");
    };

    const normalizedItems = items
        ? items.map((item) => {
              if (
                  typeof item === "object" &&
                  item !== null &&
                  "value" in item &&
                  "text" in item
              ) {
                  return { value: item.value.toString(), display: item.text };
              }
              return { value: item.toString(), display: item.toString() };
          })
        : [];

    const renderMenuItem = (value: string, display: string, index: number) => (
        <MenuItem key={index} value={value}>
            {display}
        </MenuItem>
    );

    return (
        <Grid item xs={sx?.xs} md={sx?.md}>
            <InputLabel className="title-input-edition">{label}</InputLabel>
            <FormControl fullWidth>
                <Controller
                    render={({ field }) => (
                        <Select
                            {...field}
                            inputProps={{ readOnly: true }}
                            sx={{ "& fieldset": { borderRadius: "14px" } }}
                        >
                            {normalizedItems.length
                                ? normalizedItems.map((item, index) =>
                                      renderMenuItem(
                                          item.value,
                                          item.display.toString(),
                                          index,
                                      ),
                                  )
                                : renderMenuItem(
                                      getValues(name ?? "").toString(),
                                      typeof getValues(name ?? "") === "string"
                                          ? toCapitalizeCase(
                                                getValues(name ?? ""),
                                            )
                                          : getValues(name ?? "").toString(),
                                      0,
                                  )}
                        </Select>
                    )}
                    defaultValue={
                        watch(name ?? "")
                            ? getValues(name ?? "").toString()
                            : ""
                    }
                    name={name ?? ""}
                    control={control}
                />
            </FormControl>
        </Grid>
    );
};
