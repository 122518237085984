import { Container } from "@enerbit/base";
import { HashRouter, Route, Routes } from "react-router-dom";
import Search from "../pages/Search";
import { CancelOrder } from "../pages/components/cancel-order";
import { Certifications } from "../pages/components/certifications";
import { ConsultOrder } from "../pages/components/consult-order";
import { EditOrder } from "../pages/components/edit-order";

const Router = () => {
    return (
        <Container sx={{ py: 4 }} maxWidth={"xl"}>
            <HashRouter basename="/orders">
                <Routes>
                    <Route path="/" element={<Search />} />
                    <Route path="/:id" element={<ConsultOrder />} />
                    {/* <Route path='/create' element={<CreateOrder />} /> */}
                    <Route path="/edit/:id" element={<EditOrder />} />
                    <Route path="/cancel/:id" element={<CancelOrder />} />
                    <Route
                        path="/certifications/:id"
                        element={<Certifications />}
                    />
                </Routes>
            </HashRouter>
        </Container>
    );
};

export default Router;
