/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    CheckCircleIcon,
    ErrorOutlineIcon,
    Grid,
    Step,
    StepButton,
    StepContent,
    StepLabel,
    Typography,
} from "@enerbit/base";
import { type FC, useRef } from "react";
import { get, useFormContext } from "react-hook-form";
import type { IStepperList } from "../../../models/IDetailsCertificates";
import { handleStepper } from "../../../store/slices/detail-progress/detailProgress";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { FormItem } from "./FormItem";

interface Props {
    index: number;
    step: IStepperList;
    data: any;
}
export const StepItem: FC<Props> = ({ index, step, data }) => {
    const { watch } = useFormContext();
    const { selectedOrder } = useAppSelector((state) => state.initialForm);
    const dispatch = useAppDispatch();
    const ref = useRef<HTMLDivElement>(null);
    const filteredData = step.data.filter(
        ({ name }) =>
            (step.sectionName || name) &&
            get(data, name ?? step.sectionName) !== null,
    );

    const validateData = () => {
        if (Array.isArray(watch(step.sectionName))) {
            return !!watch(step.sectionName).every((obj: any) => {
                return (
                    obj.hasOwnProperty("isCompletedSection") &&
                    obj.isCompletedSection === true
                );
            });
        } else if (typeof watch(step.sectionName) === "object") {
            return watch(step.sectionName).isCompletedSection;
        }
        return false;
    };

    return (
        <Step index={index}>
            <StepLabel
                ref={ref}
                color="primary"
                icon={
                    selectedOrder?.status === "done" || validateData() ? (
                        <CheckCircleIcon color="primary" />
                    ) : (
                        <ErrorOutlineIcon color="error" />
                    )
                }
                onClick={() => dispatch(handleStepper(index))}
            >
                <StepButton icon={<></>}>
                    <Typography
                        sx={{
                            fontWeight: "bold",
                            color:
                                selectedOrder?.status === "done" ||
                                validateData()
                                    ? "primary.main"
                                    : "error.main",
                        }}
                    >
                        {index + 1}. {step.label}
                    </Typography>
                </StepButton>
            </StepLabel>
            <StepContent sx={{ pt: 3 }}>
                <Grid container rowSpacing={3} columnSpacing={5}>
                    {filteredData.map((field, index) => {
                        const newField = {
                            ...field,
                            name: field.name
                                ? `${step.sectionName}.${field.name}`
                                : step.sectionName,
                        };
                        return <FormItem field={newField} key={index} />;
                    })}
                </Grid>
            </StepContent>
        </Step>
    );
};
