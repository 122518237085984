/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    Button,
    CircularProgress,
    DialogContent,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    type SelectChangeEvent,
    TextField,
    useSnackbar,
} from "@enerbit/base";
import { type ChangeEvent, useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";
import { StyledDialog, StyledDialogTitle } from "../../../common";
import { EEntity } from "../../../helpers";
import { getCustomers, getLeads } from "../../../store";
import {
    addLead,
    addLeads,
    selectEntity,
} from "../../../store/slices/initial-form/initialForm";
import {
    closeModal,
    resetTableAddress,
    setName,
    setPage,
} from "../../../store/slices/table-address/tableAddress";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { AddressTableClient, AddressTableLead } from "../tables";

export const AddressModal = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { selectedLeads, selectedCustomers, open, loading, name } =
        useAppSelector((state) => state.tableAddress);
    const { entity, errorOrder, message, filter } = useAppSelector(
        (state) => state.initialForm,
    );
    const dispatch = useAppDispatch();

    const handleClose = () => {
        dispatch(closeModal());
    };

    const handleAction = () => {
        if (entity === EEntity.Client) {
            dispatch(
                addLead(
                    selectedCustomers.map((customer) => {
                        const { measurement_point_id, pii, location } =
                            customer;
                        return {
                            id: measurement_point_id,
                            full_name: `${pii.names} ${pii.last_names}`,
                            address: location.address,
                            department: location.state,
                            city: location.city,
                        };
                    })[0],
                ),
            );
        } else {
            if (entity === EEntity.Lead) {
                dispatch(
                    addLead(
                        selectedLeads.map((leadItem) => ({
                            id: leadItem.id,
                            secondary_id: leadItem.measurement_point_id, // O cualquier otro campo apropiado
                            full_name: `${leadItem.name} ${leadItem.last_name}`,
                            address: leadItem.address,
                            city: leadItem.city,
                            department: leadItem.department,
                            email: leadItem.email_from, // Asumiendo que email_from es el correo electrónico que quieres usar
                        }))[0],
                    ),
                );
            } else {
                dispatch(
                    addLeads(
                        selectedLeads.map((leadItem) => ({
                            id: leadItem.id,
                            secondary_id: leadItem.measurement_point_id, // O cualquier otro campo apropiado
                            full_name: `${leadItem.name} ${leadItem.last_name}`,
                            address: leadItem.address,
                            city: leadItem.city,
                            department: leadItem.department,
                            email: leadItem.email_from, // Asumiendo que email_from es el correo electrónico que quieres usar
                        })),
                    ),
                );
            }
        }
        dispatch(resetTableAddress());
        handleClose();
    };

    const debounced = useDebouncedCallback((value) => {
        if (entity === EEntity.Client) {
            dispatch(setPage(0));
            dispatch(getCustomers({ email: filter, name: value }));
        } else {
            dispatch(setPage(1));
            dispatch(getLeads({ email: filter, name: value }));
        }
    }, 500);

    const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        const nextValue = e.target.value.trim();
        dispatch(setName(nextValue));
        debounced(nextValue);
    };

    const handleChangeEntity = (event: SelectChangeEvent<EEntity | null>) => {
        const newEntity = event.target.value as EEntity;
        if (newEntity) {
            dispatch(selectEntity(newEntity));
        }
    };

    useEffect(() => {
        if (errorOrder) {
            enqueueSnackbar(message, { variant: "error" });
        }
    }, [errorOrder]);

    useEffect(() => {
        dispatch(setName(""));
    }, []);

    return (
        <StyledDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            maxWidth={"lg"}
            fullWidth
        >
            <StyledDialogTitle
                id="customized-dialog-title"
                onClose={handleClose}
            >
                Predios
            </StyledDialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <InputLabel className="title-input-edition" required>
                            Lead o cliente
                        </InputLabel>
                        <FormControl fullWidth>
                            <Select
                                value={entity}
                                onChange={handleChangeEntity}
                                id="activity"
                                disabled={loading}
                                sx={{ borderRadius: "14px" }}
                                variant="outlined"
                                fullWidth
                            >
                                <MenuItem value={EEntity.Lead}>Lead</MenuItem>
                                <MenuItem value={EEntity.Client}>
                                    Cliente
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    {entity && EEntity.Smartbit !== entity && (
                        <Grid item xs={12}>
                            <InputLabel className="title-input-edition">
                                Buscar por nombre o dirección
                            </InputLabel>
                            <FormControl fullWidth>
                                <TextField
                                    fullWidth
                                    type="email"
                                    sx={{
                                        "& fieldset": { borderRadius: "14px" },
                                    }}
                                    value={name}
                                    onChange={handleSearchChange}
                                    variant="outlined"
                                    placeholder="Buscar"
                                    InputProps={{
                                        endAdornment: loading && (
                                            <InputAdornment position="end">
                                                <CircularProgress
                                                    size={20}
                                                    thickness={6}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    )}
                    {entity && EEntity.Smartbit !== entity && (
                        <Grid item xs={12}>
                            <InputLabel
                                sx={{ color: "#344054", fontSize: "18px" }}
                                required
                            >
                                Seleccione el servicio o sede que desea
                                consultar
                            </InputLabel>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        {entity === EEntity.Lead && <AddressTableLead />}
                        {entity === EEntity.Client && <AddressTableClient />}
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            id={"table-button"}
                            fullWidth
                            disabled={
                                !(
                                    selectedLeads.length > 0 ||
                                    selectedCustomers.length > 0
                                )
                            }
                            variant="contained"
                            color="secondary"
                            onClick={handleAction}
                        >
                            Ver
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </StyledDialog>
    );
};
