/* eslint-disable @typescript-eslint/no-explicit-any */
import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import { getActivities } from ".";
import { EEntity } from "../../../helpers/common";
import type {
    IAction,
    IInitialFormState,
    ISelectedEntity,
} from "../../../models";
import type {
    ItemGetOrders,
    OrderEntityGetOrders,
} from "../../../models/IGetOrders";
import type { INotifyTo } from "../../../models/INotifications";
import {
    addOrderEntities,
    cancelOrder,
    createOrder,
    deleteOrderEntities,
    editOrder,
    getCancelReasons,
    getCertificates,
    getCurrentEntity,
    getEntities,
    getFrontier,
    getInitialEntities,
    getLeadById,
    getMeasurementType,
    getOrder,
    getSmartBit,
    updateOrderEntities,
} from "./thunk";

const initialState: IInitialFormState = {
    filter: "",
    action: null,
    loadingActivities: false,
    loadingEntities: false,
    listActivities: [],
    listEntities: [],
    entity: null,
    notFoundLead: false,
    addedEntities: [],
    loadingOrder: false,
    errorOrder: false,
    addedNotifyToEmails: [],
    initialized: false,
    loadingLeads: false,
    errorLeads: false,
    message: "",
    isDone: false,
    loadingUpdateLead: false,
    loadingSmartbit: false,
    loadingCancellationReasons: false,
    cancellationReasons: [],
    loadingCancel: false,
    selectedOptions: [],
    loadingFrontier: false,
    loadingCertificates: false,
    lastCertificate: null,
    loadingCurrentEntity: false,
    loadingInitialEntities: false,
    cancellationResponsibles: [],
};

const initialForm = createSlice({
    name: "initialForm",
    initialState,
    reducers: {
        setFilter: (state, { payload }: PayloadAction<string>) => {
            state.filter = payload;
        },
        setSelectedOptions: (state, { payload }: PayloadAction<string[]>) => {
            state.selectedOptions = payload;
        },
        setAction: (state, { payload }: PayloadAction<IAction | null>) => {
            state.action = payload;
        },
        addLead: (state, { payload }: PayloadAction<ISelectedEntity>) => {
            state.addedEntities = [payload];
        },
        addLeads: (state, { payload }: PayloadAction<ISelectedEntity[]>) => {
            // Filtrar por ciudad
            const city =
                state.addedEntities.length > 0
                    ? state.addedEntities[0].city
                    : payload[0].city;
            const filteredByCity = payload.filter((item) => item.city === city);

            // Crear un conjunto para almacenar los IDs únicos
            const uniqueIds = new Set(
                state.addedEntities.map((item) => item.id),
            );

            // Filtrar los nuevos leads que no tienen un ID ya presente
            const newLeads = filteredByCity.filter(
                (item) => !uniqueIds.has(item.id),
            );

            // Agregar los nuevos leads al estado
            state.addedEntities = [...state.addedEntities, ...newLeads];
        },
        resetLeads: (state) => {
            state.errorOrder = false;
            state.message = "";
            state.addedEntities = [];
            state.selectedOrder = undefined;
        },
        selectOrder: (state, { payload }: PayloadAction<ItemGetOrders>) => {
            state.selectedOrder = payload;
        },
        resetSelectOrder: (state) => {
            state.selectedOrder = undefined;
        },
        updateEntitiesSelectedOrder: (
            state,
            { payload }: PayloadAction<OrderEntityGetOrders[] | undefined>,
        ) => {
            if (state.selectedOrder && payload) {
                state.selectedOrder.order_entities = payload;
            }
        },
        resetPartialForm: (state) => {
            return {
                ...initialState,
                message: state.message,
                activity: state.activity,
                entity: state.entity,
                listActivities: state.listActivities,
                listEntities: state.listEntities,
                selectedOptions: state.selectedOptions,
                addedEntities: state.addedEntities,
                filter: state.filter,
                nodes: state.nodes,
                edges: state.edges,
            };
        },

        addNotificationEmail: (
            state,
            { payload }: PayloadAction<INotifyTo>,
        ) => {
            const exists = state.addedNotifyToEmails.find(
                (item) => item.email === payload.email,
            );

            if (!exists) {
                state.addedNotifyToEmails = [
                    ...state.addedNotifyToEmails,
                    payload,
                ];
            }
        },
        removeNotificationEmail: (
            state,
            { payload }: PayloadAction<string>,
        ) => {
            state.addedNotifyToEmails = state.addedNotifyToEmails.filter(
                (item) => item.email !== payload,
            );
        },
        loadEmails: (state, { payload }: PayloadAction<INotifyTo[]>) => {
            if (!payload) {
                state.addedNotifyToEmails = [];
            } else {
                state.addedNotifyToEmails = payload;
            }
        },

        resetInitialForm: (state) => {
            return {
                ...initialState,
                listActivities: state.listActivities,
                listEntities: state.listEntities,
            };
        },
        selectActivity: (state, { payload }: PayloadAction<string>) => {
            state.activity = state.listActivities.find(
                ({ name }) => name === payload,
            );
        },
        resetActivity: (state) => {
            state.activity = undefined;
        },
        selectEntity: (state, { payload }: PayloadAction<EEntity | null>) => {
            state.entity = payload;
        },
        handleDeleteLead: (state, { payload }: PayloadAction<string>) => {
            state.addedEntities = state.addedEntities.filter(
                ({ id }) => id !== payload,
            );
        },
        setNodes: (state, { payload }) => {
            state.nodes = payload;
        },
        setEdges: (state, { payload }) => {
            state.edges = payload;
        },
        setEntity: (state, { payload }: PayloadAction<EEntity>) => {
            state.entity = payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getActivities.pending, (state) => {
            state.loadingActivities = true;
        });
        builder.addCase(getActivities.fulfilled, (state, action) => {
            state.loadingActivities = false;
            state.listActivities = action.payload;
        });
        builder.addCase(getActivities.rejected, (state) => {
            state.loadingActivities = false;
        });
        builder.addCase(getMeasurementType.pending, (state) => {
            state.measurementType = undefined;
        });
        builder.addCase(getMeasurementType.fulfilled, (state, action) => {
            state.measurementType = action.payload;
        });
        builder.addCase(getMeasurementType.rejected, (state) => {
            state.measurementType = undefined;
        });
        builder.addCase(getEntities.pending, (state) => {
            state.loadingEntities = true;
        });
        builder.addCase(getEntities.fulfilled, (state, action) => {
            state.loadingEntities = false;
            state.listEntities = action.payload;
        });
        builder.addCase(getEntities.rejected, (state) => {
            state.loadingEntities = false;
        });
        builder.addCase(getOrder.pending, (state) => {
            state.loadingOrder = true;
            state.errorOrder = false;
        });
        builder.addCase(getOrder.fulfilled, (state, { payload }) => {
            state.loadingOrder = false;
            if (state.selectedOrder) {
                state.selectedOrder = {
                    ...state.selectedOrder,
                    order_cancel: payload.order_cancel,
                    order_done: payload.order_done,
                    form_data: payload.form_data,
                    status: payload.status,
                    planned_date_begin: payload.planned_date_begin,
                    planned_date_end: payload.planned_date_end,
                };
            } else {
                state.selectedOrder = payload;
            }
        });
        builder.addCase(getOrder.rejected, (state) => {
            state.loadingOrder = false;
            state.errorOrder = true;
            state.message =
                "Ha ocurrido un error inesperado, por favor inténtelo de nuevo.";
        });
        builder.addCase(addOrderEntities.pending, (state) => {
            state.loadingLeads = true;
            state.errorLeads = false;
        });
        builder.addCase(addOrderEntities.fulfilled, (state, { payload }) => {
            state.loadingLeads = false;
            const city =
                state.addedEntities.length > 0
                    ? state.addedEntities[0].city
                    : payload[0].city;

            const aux = payload
                .filter((item) => item.city === city)
                .map((item) => ({ ...item }));

            const UniqIds = new Set();
            state.addedEntities = [...state.addedEntities, ...aux].filter(
                ({ id }) => {
                    const isDuplicated = UniqIds.has(id);
                    UniqIds.add(id);
                    return !isDuplicated;
                },
            );
        });
        builder.addCase(addOrderEntities.rejected, (state) => {
            state.loadingLeads = false;
            state.errorLeads = true;
        });
        builder.addCase(updateOrderEntities.pending, (state) => {
            state.loadingUpdateLead = true;
        });
        builder.addCase(updateOrderEntities.fulfilled, (state) => {
            state.loadingUpdateLead = false;
        });
        builder.addCase(updateOrderEntities.rejected, (state) => {
            state.loadingUpdateLead = false;
            state.errorLeads = true;
        });
        builder.addCase(deleteOrderEntities.pending, (state) => {
            state.loadingLeads = true;
            state.errorLeads = false;
        });
        builder.addCase(deleteOrderEntities.fulfilled, (state, { payload }) => {
            state.loadingLeads = false;
            state.addedEntities = state.addedEntities.filter(
                ({ id }) => id !== payload,
            );
        });
        builder.addCase(deleteOrderEntities.rejected, (state) => {
            state.loadingLeads = false;
            state.errorLeads = true;
        });
        builder.addCase(createOrder.pending, (state) => {
            state.loadingOrder = true;
            state.errorOrder = false;
            state.isDone = false;
            state.errorContent = undefined;
        });
        builder.addCase(createOrder.fulfilled, (state) => {
            state.loadingOrder = false;
            state.isDone = true;
        });
        builder.addCase(
            createOrder.rejected,
            (state, { payload }: PayloadAction<any>) => {
                const { status, content } = payload;
                state.loadingOrder = false;
                state.isDone = false;
                state.errorOrder = true;
                if (status === 404) {
                    state.message = "No cuenta con una instalación";
                    if (content) {
                        state.errorContent = state.addedEntities.filter(
                            ({ id }) => content.includes(id),
                        );
                    }
                } else if (status === 409) {
                    state.message = "Ya existe una instalación asociada";
                    if (content) {
                        state.errorContent = state.addedEntities.filter(
                            ({ id }) => content.includes(id),
                        );
                    }
                } else {
                    state.message =
                        "No se pudo crear la orden, por favor intente nuevamente.";
                }
            },
        );
        builder.addCase(editOrder.pending, (state) => {
            state.loadingOrder = true;
            state.errorOrder = false;
            state.isDone = false;
        });
        builder.addCase(editOrder.fulfilled, (state) => {
            state.loadingOrder = false;
            state.isDone = true;
        });
        builder.addCase(editOrder.rejected, (state) => {
            state.loadingOrder = false;
            state.errorOrder = true;
            state.isDone = false;
        });
        builder.addCase(cancelOrder.pending, (state) => {
            state.loadingCancel = true;
            state.errorOrder = false;
            state.isDone = false;
            state.errorContent = undefined;
        });
        builder.addCase(cancelOrder.fulfilled, (state) => {
            state.loadingCancel = false;
            state.isDone = true;
        });
        builder.addCase(cancelOrder.rejected, (state) => {
            state.loadingCancel = false;
            state.isDone = false;
            state.errorOrder = true;
            state.message = "No se pudo cancelar la orden, inténtelo de nuevo.";
        });
        builder.addCase(getSmartBit.pending, (state) => {
            state.entity = EEntity.Smartbit;
            state.loadingSmartbit = true;
            state.errorOrder = false;
            state.nodes = undefined;
            state.edges = undefined;
        });
        builder.addCase(getSmartBit.fulfilled, (state, { payload }) => {
            const { nodes, edges } = payload;
            state.loadingSmartbit = false;
            state.nodes = nodes;
            state.edges = edges;
        });
        builder.addCase(
            getSmartBit.rejected,
            (state, { payload }: PayloadAction<any>) => {
                const { status, content } = payload;
                state.entity = null;
                state.loadingSmartbit = false;
                state.errorOrder = true;
                if (status === 404) {
                    if (content.includes("measurement point")) {
                        state.message =
                            "El medidor no cuenta con punto de medida.";
                    } else if (content.includes("meters")) {
                        state.message =
                            "El smartBit no cuenta con medidores asociados.";
                    } else if (content.includes("ess")) {
                        state.message = "smartBit sin ess.";
                    } else {
                        state.message = "No existe este smartBit.";
                    }
                } else {
                    state.message =
                        "Ha ocurrido un error inesperado, por favor inténtelo de nuevo.";
                }
                state.nodes = undefined;
                state.edges = undefined;
            },
        );
        builder.addCase(getCancelReasons.pending, (state) => {
            state.loadingCancellationReasons = true;
            state.cancellationReasons = [];
            state.cancellationResponsibles = [];
        });
        builder.addCase(getCancelReasons.fulfilled, (state, { payload }) => {
            state.loadingCancellationReasons = false;
            state.cancellationReasons = payload;
            payload.forEach((item) => {
                if (
                    !state.cancellationResponsibles.includes(item.responsible)
                ) {
                    state.cancellationResponsibles.push(item.responsible);
                }
            });
        });
        builder.addCase(getCancelReasons.rejected, (state) => {
            state.loadingCancellationReasons = false;
            state.errorOrder = true;
            state.message = "No se pudo cargar las razones de cancelación";
        });
        builder.addCase(getFrontier.pending, (state) => {
            state.loadingFrontier = true;
            state.errorOrder = false;
            state.addedEntities = [];
        });
        builder.addCase(
            getFrontier.fulfilled,
            (state, { payload }: PayloadAction<EEntity>) => {
                state.entity = payload;
                state.loadingFrontier = false;
            },
        );
        builder.addCase(getFrontier.rejected, (state) => {
            state.entity = null;
            state.loadingFrontier = false;
            state.errorOrder = true;
            state.message = "No existe esta frontera.";
        });
        builder.addCase(getLeadById.pending, (state) => {
            state.loadingLeads = true;
            state.errorOrder = false;
            state.addedEntities = [];
        });
        builder.addCase(getLeadById.fulfilled, (state) => {
            state.entity = EEntity.Lead;
            state.loadingLeads = false;
        });
        builder.addCase(getLeadById.rejected, (state) => {
            state.entity = null;
            state.loadingLeads = false;
            state.errorOrder = true;
            state.message = "No existe esta lead.";
        });
        builder.addCase(getCertificates.pending, (state) => {
            state.loadingCertificates = true;
            state.lastCertificate = [];
        });
        builder.addCase(getCertificates.fulfilled, (state, { payload }) => {
            state.loadingCertificates = false;
            state.lastCertificate = payload;
        });
        builder.addCase(getCertificates.rejected, (state) => {
            state.loadingCertificates = false;
            state.errorOrder = true;
            state.lastCertificate = [];
        });

        // Get initial entities for the navigation
        builder.addCase(getInitialEntities.pending, (state) => {
            state.loadingInitialEntities = true;
            state.loadingSmartbit = true;
        });
        builder.addCase(getInitialEntities.fulfilled, (state, { payload }) => {
            state.loadingInitialEntities = false;
            state.loadingSmartbit = false;
            if (payload) {
                state.addedEntities = payload;
            }
        });
        builder.addCase(getInitialEntities.rejected, (state) => {
            state.loadingInitialEntities = false;
            state.loadingSmartbit = false;
        });

        // Get current entity
        builder.addCase(getCurrentEntity.pending, (state) => {
            state.loadingCurrentEntity = true;
            state.loadingSmartbit = true;
        });
        builder.addCase(getCurrentEntity.fulfilled, (state, { payload }) => {
            state.loadingCurrentEntity = false;
            state.loadingSmartbit = false;

            if (payload) state.filter = payload;
        });
        builder.addCase(getCurrentEntity.rejected, (state) => {
            state.loadingCurrentEntity = false;
            state.loadingSmartbit = false;
        });
    },
});

export const {
    setFilter,
    resetLeads,
    resetInitialForm,
    selectEntity,
    selectActivity,
    addLead,
    addLeads,
    selectOrder,
    resetPartialForm,
    handleDeleteLead,
    setAction,
    setSelectedOptions,
    resetActivity,
    updateEntitiesSelectedOrder,
    addNotificationEmail,
    removeNotificationEmail,
    loadEmails,
    setNodes,
    setEdges,
    setEntity,
    resetSelectOrder,
} = initialForm.actions;

export default initialForm.reducer;
