import { Box, Grid, Typography } from "@enerbit/base";
import type { FC } from "react";
import { AssociatedItem } from ".";
import type { ItemGetOrders, OrderEntityGetOrders } from "../../../models";

interface Props {
    associatedLeads: OrderEntityGetOrders[];
    order: ItemGetOrders;
}

export const CertificationsAssociated: FC<Props> = ({
    associatedLeads,
    order,
}) => {
    return (
        <Grid item mb={2} xs={12}>
            <Box className="box-certification-edit" p={4}>
                <Grid container>
                    <Grid item>
                        <Typography
                            color="primary"
                            sx={{
                                fontSize: "21px",
                                fontWeight: 700,
                                mb: "12px",
                                display: "flex",
                                flex: 1,
                            }}
                        >
                            Actas asociadas
                        </Typography>
                    </Grid>
                    <Grid container item spacing={3}>
                        {associatedLeads.map((item, key) => {
                            return (
                                <AssociatedItem
                                    associatedLead={item}
                                    order={order}
                                    key={key}
                                />
                            );
                        })}
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    );
};
