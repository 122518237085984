import {
    Box,
    Grid,
    IconButton,
    Typography,
    enerbitColors,
} from "@enerbit/base";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useAppDispatch, useAppSelector } from "../../../store";
import { removeNotificationEmail } from "../../../store/slices/initial-form/initialForm";

interface Props {
    name: string;
    email: string;
}

const EmailItem = ({ name, email }: Props) => {
    const dispatch = useAppDispatch();
    const { action } = useAppSelector((state) => state.initialForm);

    const handleEmailRemove = () => {
        dispatch(removeNotificationEmail(email));
    };

    return (
        <Grid item xs={4}>
            <Box
                sx={{
                    padding: "14px 16px 14px 16px",
                    border: `1px solid ${enerbitColors.neutral[300]}`,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    //   height: "61px",
                    borderRadius: "16px",
                }}
            >
                <Box>
                    <Typography
                        sx={{
                            fontSize: "16px",
                            color: enerbitColors.neutral[700],
                            fontWeight: 700,
                        }}
                    >
                        {name}
                    </Typography>
                    <Typography sx={{ color: enerbitColors.neutral.main }}>
                        {email}
                    </Typography>
                </Box>
                {action?.type === "Create" && (
                    <IconButton
                        onClick={handleEmailRemove}
                        id="remove_notification_email_btn"
                    >
                        <DeleteOutlineIcon color="error" />
                    </IconButton>
                )}
            </Box>
        </Grid>
    );
};

export default EmailItem;
