import {
    FormControlLabel,
    Grid,
    InputLabel,
    LoadingButton,
    Switch,
    TextField,
    Typography,
} from "@enerbit/base";
import { type FC, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import {
    updateOrderEntities,
    useAppDispatch,
    useAppSelector,
} from "../../../store";

interface Props {
    index: number;
}

export const ContactWitness: FC<Props> = ({ index }) => {
    const {
        register,
        formState: { errors },
        watch,
        setValue,
    } = useFormContext();
    const dispatch = useAppDispatch();
    const { selectedOrder, loadingUpdateLead, action } = useAppSelector(
        (state) => state.initialForm,
    );
    const [isChecked, setIsChecked] = useState(
        !!watch(`order_entities.${index}.form_data.name_witness`) ||
            !!watch(`order_entities.${index}.form_data.phone_contact_witness`),
    );

    useEffect(() => {
        if (!isChecked) {
            setValue(`order_entities.${index}.form_data.name_witness`, null);
            setValue(
                `order_entities.${index}.form_data.phone_contact_witness`,
                null,
            );
            setValue(
                `order_entities.${index}.form_data.name_third_contact`,
                null,
            );
            setValue(
                `order_entities.${index}.form_data.phone_third_contact`,
                null,
            );
            setValue(
                `order_entities.${index}.form_data.email_third_contact`,
                null,
            );
            setValue(`order_entities.${index}.form_data.observations`, null);
        }
    }, [isChecked]);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <FormControlLabel
                    sx={{ width: "100%" }}
                    control={
                        <Switch
                            id="contact"
                            disabled={
                                action?.type === "Cancel" ||
                                action?.type === "Consult"
                            }
                            checked={isChecked}
                            onChange={(e) => setIsChecked(e.target.checked)}
                            color="secondary"
                        />
                    }
                    label={
                        <Typography color={"#53358E"}>
                            ¿Contacto testigo?
                        </Typography>
                    }
                />
            </Grid>
            {isChecked && (
                <Grid item xs={12}>
                    <Grid container rowSpacing={3} columnSpacing={5}>
                        <Grid item md={6} xs={12}>
                            <InputLabel
                                className="title-input-edition"
                                required
                            >
                                Nombre del contacto
                            </InputLabel>
                            <TextField
                                fullWidth
                                id="name_witness"
                                disabled={action?.type === "Consult"}
                                sx={{ "& fieldset": { borderRadius: "14px" } }}
                                {...register(
                                    `order_entities.${index}.form_data.name_witness`,
                                )}
                                error={!!errors.name_witness}
                                helperText={
                                    errors.name_witness &&
                                    errors.name_witness.message
                                }
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <InputLabel
                                className="title-input-edition"
                                required
                            >
                                Número de teléfono
                            </InputLabel>
                            <TextField
                                fullWidth
                                id="phone_contact_witness"
                                type="number"
                                disabled={action?.type === "Consult"}
                                sx={{ "& fieldset": { borderRadius: "14px" } }}
                                {...register(
                                    `order_entities.${index}.form_data.phone_contact_witness`,
                                )}
                                error={!!errors.phone_contact_witness}
                                helperText={
                                    errors.phone_contact_witness &&
                                    errors.phone_contact_witness.message
                                }
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}
            {action?.type === "Edit" && (
                <Grid item>
                    <LoadingButton
                        loading={loadingUpdateLead}
                        color="primary"
                        variant="contained"
                        onClick={() => {
                            dispatch(
                                updateOrderEntities({
                                    form_data: watch(
                                        `order_entities.${index}.form_data`,
                                    ),
                                    assignee_id:
                                        selectedOrder?.order_entities[index]
                                            .assignee_id ?? "",
                                    entity_id:
                                        selectedOrder?.order_entities[0].entity
                                            .id ?? "",
                                    id:
                                        selectedOrder?.order_entities[index]
                                            .id ?? "",
                                }),
                            );
                        }}
                    >
                        Guardar cambios
                    </LoadingButton>
                </Grid>
            )}
        </Grid>
    );
};
