import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ITeamOperatorsState, OrderOperatorGetOrders } from "../../../models";
import { addOrderOperators, deleteOrderOperators, getOperators } from "./thunk";

const initialState: ITeamOperatorsState = {
    search: ["operador"],
    loadingOperator: false,
    listOperators: [],
    addedOperators: [],
    notFoundOperator: false,
    errorOperator: false,
};

const teamOperators = createSlice({
    name: "teamOperators",
    initialState,
    reducers: {
        resetListOperators: (state) => {
            state.listOperators = [];
        },
        loadOperators: (
            state,
            { payload }: PayloadAction<OrderOperatorGetOrders[]>,
        ) => {
            state.addedOperators = payload;
            state.listOperators = [];
        },
        addOperator: (
            state,
            { payload }: PayloadAction<OrderOperatorGetOrders>,
        ) => {
            const isInvalid = !!state.addedOperators.find(
                ({ operator_id }) => operator_id === payload.operator_id,
            );

            if (!isInvalid) {
                state.addedOperators = [...state.addedOperators, payload];
                state.listOperators = [];
            }
        },
        resetAddedNotificationEmail: (state) => {
            state.addedOperators = [];
        },
        handleDeleteOperator: (state, { payload }: PayloadAction<string>) => {
            state.addedOperators = state.addedOperators.filter(
                ({ operator_id }) => operator_id !== payload,
            );
        },
        resetAddedOperators: (state) => {
            state.addedOperators = [];
            1;
        },
        setSearch: (state, { payload }: PayloadAction<string[]>) => {
            state.search = payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getOperators.pending, (state) => {
            state.loadingOperator = true;
        });
        builder.addCase(getOperators.fulfilled, (state, { payload }) => {
            state.loadingOperator = false;

            if (payload.length > 0) {
                state.listOperators = payload.filter((elementList) => {
                    return !state.addedOperators.some((elementAdded) => {
                        return (
                            elementAdded.operator_id === elementList.operator_id
                        );
                    });
                });
            } else {
                state.notFoundOperator = true;
            }
        });
        builder.addCase(getOperators.rejected, (state) => {
            state.loadingOperator = false;
        });
        builder.addCase(addOrderOperators.pending, (state) => {
            state.loadingOperator = true;
            state.errorOperator = false;
        });
        builder.addCase(addOrderOperators.fulfilled, (state, { payload }) => {
            state.loadingOperator = false;
            const isInvalid = state.addedOperators.find(
                ({ id }) => id === payload.id,
            );

            if (!isInvalid) {
                state.addedOperators = [...state.addedOperators, payload];
                state.listOperators = [];
            }
        });
        builder.addCase(addOrderOperators.rejected, (state) => {
            state.loadingOperator = false;
            state.errorOperator = true;
        });
        builder.addCase(deleteOrderOperators.pending, (state) => {
            state.loadingOperator = true;
            state.errorOperator = false;
        });
        builder.addCase(
            deleteOrderOperators.fulfilled,
            (state, { payload }) => {
                state.loadingOperator = false;
                state.addedOperators = state.addedOperators.filter(
                    ({ id }) => id !== payload,
                );
            },
        );
        builder.addCase(deleteOrderOperators.rejected, (state) => {
            state.loadingOperator = false;
            state.errorOperator = true;
        });
    },
});

export const {
    resetListOperators,
    loadOperators,
    addOperator,
    resetAddedNotificationEmail,
    handleDeleteOperator,
    resetAddedOperators,
    setSearch,
} = teamOperators.actions;

export default teamOperators.reducer;
