import {
    AnimatePresence,
    Container,
    Grid,
    Typography,
    motion,
} from "@enerbit/base";
import { EEntity } from "../helpers/common";
import { useAppSelector } from "../store/store";
import {
    FilterProvider,
    ResultsFilter,
    ResultsFilterSkeleton,
} from "./components/filter";
import { OrderContainer, OrderContainerSkeleton } from "./components/order";

const Search = () => {
    const {
        addedEntities,
        entity,
        action,
        loadingFrontier,
        loadingSmartbit,
        loadingLeads,
    } = useAppSelector((state) => state.initialForm);

    return (
        <Container sx={{ py: 4 }} maxWidth={"xl"}>
            <AnimatePresence mode="wait">
                <motion.div
                    key={action ? "withAction" : "withoutAction"}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    <Typography
                        color="primary"
                        sx={{ fontSize: "28px", fontWeight: 700, mb: "12px" }}
                    >
                        Consulta
                    </Typography>
                    <Grid container spacing={3}>
                        <FilterProvider />
                        {!(
                            loadingFrontier ||
                            loadingSmartbit ||
                            loadingLeads
                        ) &&
                            addedEntities.length > 0 && (
                                <>
                                    <ResultsFilter />
                                    {(entity === EEntity.Lead ||
                                        entity === EEntity.Client) && (
                                        <>
                                            <OrderContainer
                                                entity={EEntity.Client}
                                            />
                                            <OrderContainer
                                                entity={EEntity.Lead}
                                            />
                                        </>
                                    )}
                                    {entity === EEntity.Smartbit && (
                                        <OrderContainer
                                            entity={EEntity.Smartbit}
                                        />
                                    )}
                                </>
                            )}
                        {(loadingFrontier ||
                            loadingSmartbit ||
                            loadingLeads) && (
                            <>
                                <ResultsFilterSkeleton />
                                <OrderContainerSkeleton />
                            </>
                        )}
                    </Grid>
                </motion.div>
            </AnimatePresence>
        </Container>
    );
};

export default Search;

// {
//   action.type === "Create" && <CreateOrder />;
// }
// {
//   action.type === "Consult" && <ConsultOrder />;
// }
// {
//   action.type === "Edit" && <EditOrder />;
// }
// {
//   action.type === "Cancel" && <CancelOrder />;
// }
// {
//   action.type === "Certifications" && <Certifications />;
// }
