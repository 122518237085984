/* eslint-disable @typescript-eslint/no-explicit-any */
import { api } from "@enerbit/base";
import { createAsyncThunk } from "@reduxjs/toolkit";
import type { Edge } from "reactflow";
import { EEntity } from "../../../helpers";
import { getLastCertificate } from "../../../helpers/certificates";
import type {
    ILeadItem,
    IPostOrders,
    IRespActivities,
    IRespMeasurementPoint,
    IRespOutputData,
    IResponseFrontier,
    IResponseLeads,
} from "../../../models";
import type { ICancelOrders } from "../../../models/ICancelOrders";
import type { ICancellationReasons } from "../../../models/ICancellationReasons";
import type { ISelectedEntity } from "../../../models/ICustomSelectedEntity";
import type {
    IOrderEntityFormDataGetOrders,
    ItemGetOrders,
    OrderEntityGetOrders,
} from "../../../models/IGetOrders";
import type { IRespEntities } from "../../../models/IRespEntities";
import type { Certificate } from "../../../models/certificates";
import { generateEdges } from "../../../services";
import { generateNodes, generateSmartBit } from "../../../services/flow";
import { getDetailsOrder } from "../detail-progress";
import { addLead } from "./initialForm";

export const getActivities = createAsyncThunk(
    "initialForm/getActivities",
    async () => {
        const { data } = await api.get<IRespActivities>(
            "/service-orders/order-types",
        );
        return data.items
            .sort((a, b) => a.name.localeCompare(b.name))
            .filter((activity) => activity.is_active);
    },
);

export const getMeasurementType = createAsyncThunk(
    "initialForm/getMeasurementType",
    async ({ id, is_lead }: { id: string; is_lead: boolean }) => {
        try {
            let dataMeasurements: IRespOutputData | undefined;

            if (is_lead) {
                const { data } = await api.get<IRespOutputData>(
                    `/frontier-initial-diagnostic/output-data?lead_id=${id}&is_field=true`,
                );
                dataMeasurements = data;
            } else {
                const responseLeads = await api.get<IResponseLeads>(
                    `/assignees/leads?measurement_point_id=${id}`,
                );
                const leadId = responseLeads.data.items[0]?.id;

                if (!leadId) {
                    throw new Error("No lead found");
                }

                const { data } = await api.get<IRespOutputData>(
                    `/frontier-initial-diagnostic/output-data?lead_id=${leadId}&is_field=true`,
                );
                dataMeasurements = data;
            }

            return dataMeasurements?.previous_diagnostic?.form_data.diagnostic
                .measurement_type as string;
        } catch (error) {
            console.error("Error fetching measurement type:", error);
            throw new Error("Unable to fetch measurement type");
        }
    },
);

export const getEntities = createAsyncThunk(
    "initialForm/getEntities",
    async () => {
        const { data } = await api.get<IRespEntities>(
            "/service-orders/entities",
        );
        return data.items;
    },
);

export const getOrder = createAsyncThunk(
    "initialForm/getOrder",
    async (id: string, { rejectWithValue, dispatch }) => {
        try {
            const { data } = await api.get<ItemGetOrders>(
                `/service-orders/orders/${id}`,
            );
            dispatch(getDetailsOrder(data));
            return data;
        } catch (error: any) {
            return rejectWithValue(error.response?.status);
        }
    },
);

export const addOrderEntities = createAsyncThunk(
    "initialForm/addOrderEntities",
    async (
        {
            entities,
            entity_id,
            order_id,
        }: { entities: ISelectedEntity[]; entity_id: string; order_id: string },
        { rejectWithValue },
    ) => {
        try {
            const responses: ISelectedEntity[] = await Promise.all(
                entities.map((entity) =>
                    api
                        .post<{ id: string }>(
                            "/service-orders/order-entities",
                            {
                                entity_id,
                                order_id,
                                assignee_id: entity.id,
                                form_data: {
                                    name_witness: null,
                                    phone_contact_witness: null,
                                    name_third_contact: null,
                                    phone_third_contact: null,
                                    email_third_contact: null,
                                    observations: null,
                                },
                            },
                        )
                        .then(({ data }) => {
                            return {
                                ...entity,
                                id: entity.id,
                                secondary_id: data.id,
                            };
                        }),
                ),
            );
            return responses;
        } catch (error) {
            return rejectWithValue("Ha ocurrido un error.");
        }
    },
);

export const updateOrderEntities = createAsyncThunk(
    "initialForm/updateOrderEntities",
    async (
        {
            form_data,
            assignee_id,
            entity_id,
            id,
        }: {
            form_data: IOrderEntityFormDataGetOrders;
            assignee_id: string;
            entity_id: string;
            id: string;
        },
        { rejectWithValue },
    ) => {
        try {
            const { data } = await api.patch(
                `/service-orders/orders-entities/${id}`,
                {
                    entity_id,
                    assignee_id,
                    form_data,
                },
            );
            return data;
        } catch (error) {
            return rejectWithValue("Ha ocurrido un error.");
        }
    },
);

export const deleteOrderEntities = createAsyncThunk(
    "initialForm/deleteOrderEntities",
    async (
        {
            id,
            entity_id,
            assignee_id,
        }: { id: string; entity_id: string; assignee_id: string },
        { rejectWithValue },
    ) => {
        try {
            await api.delete(
                `/service-orders/order-entities/${id}?entity_id=${entity_id}&assignee_id=${assignee_id}`,
            );
            return id;
        } catch (error) {
            return rejectWithValue("Ha ocurrido un error.");
        }
    },
);

export const createOrder = createAsyncThunk(
    "initialForm/createOrder",
    async (order: IPostOrders, { rejectWithValue }) => {
        try {
            const { data } = await api.post("/service-orders/orders", order);

            return data;
        } catch (error: any) {
            return rejectWithValue({
                status: error.response?.status,
                content: error.response?.data.content,
            });
        }
    },
);

export const editOrder = createAsyncThunk(
    "initialForm/editOrder",
    async (
        { id, order }: { id: string; order: Partial<IPostOrders> },
        { rejectWithValue },
    ) => {
        try {
            const { data } = await api.patch(
                `/service-orders/orders/${id}`,
                order,
            );

            return data;
        } catch (error: any) {
            return rejectWithValue(error.response?.status);
        }
    },
);

export const cancelOrder = createAsyncThunk(
    "initialForm/cancelOrder",
    async (order: ICancelOrders, { rejectWithValue }) => {
        try {
            const body: Omit<ICancelOrders, "cancellation_responsible"> = {
                cancel_by: order.cancel_by,
                cancellation_reason_id: order.cancellation_reason_id,
                form_data: order.form_data,
                observations: order.observations,
                order_id: order.order_id,
                picture_url: order.picture_url,
            };

            const { data } = await api.post(
                `/service-orders/order-cancel`,
                body,
            );

            return data;
        } catch (error: any) {
            return rejectWithValue({
                status: error.response?.status,
                content: error.response?.data.content,
            });
        }
    },
);

export const getSmartBit = createAsyncThunk(
    "initialForm/getSmartBit",
    async (
        { id, isCreate }: { id: string; isCreate?: boolean },
        { rejectWithValue, dispatch },
    ) => {
        try {
            const { data, status } = await api.get<any>(
                `assignees/smartbits?device_id=${id}`,
                {
                    validateStatus: (status) =>
                        (status >= 200 && status < 300) || status === 404,
                },
            );

            const deviceNode = generateSmartBit(id);
            let nodes = [deviceNode];
            let edges: Edge<any>[] = [];

            if (status === 404) {
                if (!data.detail.includes("meters")) {
                    throw {
                        response: {
                            status,
                            data: {
                                detail: data.detail || "No detail provided",
                            },
                        },
                    };
                }
                if (isCreate) {
                    dispatch(addLead({ id, city: null, department: null }));
                }
            } else {
                const { items } = data;
                const tempNodes = generateNodes(items);
                edges = generateEdges(deviceNode.id, tempNodes);
                nodes = [...tempNodes, deviceNode];

                if (isCreate) {
                    const city = items[0]?.location?.city || null;
                    const department = items[0]?.location?.state || null;
                    dispatch(addLead({ id, city, department }));
                }
            }

            return { nodes, edges };
        } catch (error: any) {
            return rejectWithValue({
                status: error.response?.status,
                content: error.response?.data.detail,
            });
        }
    },
);

export const getCancelReasons = createAsyncThunk(
    "initialForm/getCancelReasons",
    async (_, { rejectWithValue }) => {
        try {
            const { data } = await api.get<ICancellationReasons>(
                "/service-orders/cancellation-reasons?page=0&size=50",
            );
            return data.items.filter(
                (reason) =>
                    reason.is_active &&
                    reason.available_in.includes("estadium"),
            );
        } catch (error: any) {
            return rejectWithValue(error.response?.status);
        }
    },
);

export const getFrontier = createAsyncThunk(
    "initialForm/getFrontier",
    async (frt: string, { rejectWithValue, dispatch }) => {
        try {
            const respFrontier = await api.get<IResponseFrontier>(
                `assignees/frontiers?frontier_xm_code=${frt}&page=0&size=50`,
            );

            const items = respFrontier.data.items;
            if (items.length === 0) {
                throw new Error("Items not found");
            }

            const { lead_id: LD, measurement_point_id: MP } = items[0];

            const handleMP = async () => {
                const respClient = await api.get<IRespMeasurementPoint>(
                    `assignees/measurement-point/${MP}`,
                );

                dispatch(
                    addLead({
                        id: respClient.data.id,
                        full_name: "Cliente",
                        address: respClient.data.location.address,
                        city: respClient.data.location.city,
                        department: respClient.data.location.state,
                    }),
                );
                return EEntity.Client;
            };

            const handleLD = async () => {
                const respLead = await api.get<ILeadItem>(
                    `assignees/leads/${LD}`,
                );

                dispatch(
                    addLead({
                        id: respLead.data.id,
                        full_name: respLead.data.display_name,
                        address: respLead.data.address,
                        email: respLead.data.email_from,
                        city: respLead.data.city,
                        department: respLead.data.department,
                    }),
                );
                return EEntity.Lead;
            };

            if (MP) return handleMP();
            if (LD) return handleLD();

            throw new Error("No MP or LD found");
        } catch (error: any) {
            console.error(error);
            const errorMessage =
                error instanceof Error ? error.message : "Unknown error";
            return rejectWithValue({
                message: errorMessage,
                status: error?.response?.status || 500,
                frontierCode: frt,
            });
        }
    },
);

export const getLeadById = createAsyncThunk(
    "initialForm/getLeadById",
    async (frt: string, { rejectWithValue, dispatch }) => {
        try {
            const respLead = await api.get<IResponseLeads>(
                `assignees/leads?lead_integrator_id=${frt}&page=1&size=50`,
            );

            const leads = respLead.data.items;

            if (leads.length === 0) {
                throw new Error("Items not found");
            }

            const lead = leads[0];

            dispatch(
                addLead({
                    id: lead.id,
                    secondary_id: lead.measurement_point_id,
                    full_name: lead.display_name,
                    address: lead.address,
                    email: lead.email_from,
                    city: lead.city,
                    department: lead.department,
                }),
            );
        } catch (error: any) {
            const errorMessage =
                error instanceof Error ? error.message : "Unknown error";
            return rejectWithValue({
                message: errorMessage,
                status: error?.response?.status || 500,
                frontierCode: frt,
            });
        }
    },
);

export const getCertificates = createAsyncThunk(
    "initialForm/getCertificates",
    async (clientId: string, { rejectWithValue }) => {
        try {
            const responseLeads = await api.get<IResponseLeads>(
                `/assignees/leads?measurement_point_id=${clientId}`,
            );

            const leadId = responseLeads.data.items[0].id;

            const clientCertificates = await api.get<{ items: Certificate[] }>(
                `/service-orders-certificates/certificates?assignee_id=${clientId}`,
            );

            const leadCertficates = await api.get<{ items: Certificate[] }>(
                `/service-orders-certificates/certificates?assignee_id=${leadId}`,
            );

            const totalCertificates = [
                ...clientCertificates.data.items,
                ...leadCertficates.data.items,
            ];

            const lastCertificate = getLastCertificate(
                totalCertificates.filter(
                    (item) =>
                        item.certificate_type.code !== "HAZRW" &&
                        item.certificate_type.code !== "VEHRW",
                ),
            );
            return lastCertificate ? [lastCertificate] : null;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const getInitialEntities = createAsyncThunk(
    "initial-form/getInitialEntities",
    async (entities: OrderEntityGetOrders[]) => {
        const entityCode = entities[0].entity.code as EEntity;

        let promises: Promise<ISelectedEntity>[] = [];

        if (entityCode === EEntity.Lead) {
            promises = entities.map(async (entity) => {
                const res = await api.get<ILeadItem>(
                    `/assignees/leads/${entity.assignee_id}`,
                );

                const { data } = res;

                const formattedData: ISelectedEntity = {
                    id: data.id,
                    full_name: data.display_name,
                    secondary_id: data.measurement_point_id,
                    address: data.address,
                    email: data.email_from,
                    city: data.city,
                    department: data.department,
                };

                return formattedData;
            });
        } else if (entityCode === EEntity.Client) {
            promises = entities.map(async (entity) => {
                const res = await api.get<IRespMeasurementPoint>(
                    `/assignees/measurement-point/${entity.assignee_id}`,
                );

                const { data } = res;

                const formattedData: ISelectedEntity = {
                    id: data.id,
                    full_name: entity.pii.full_name,
                    address: data.location.address,
                    city: data.location.city,
                    department: data.location.state,
                };

                return formattedData;
            });
        }

        const response = await Promise.all(promises);

        return response;
    },
);

export const getCurrentEntity = createAsyncThunk(
    "initial-form/getCurrentEntity",
    async (
        { entityCode, assignee }: { entityCode: EEntity; assignee: string },
        { rejectWithValue },
    ) => {
        try {
            if (entityCode === EEntity.Lead) {
                const res = await api.get<ILeadItem>(
                    `/assignees/leads/${assignee}`,
                );

                return res.data.email_from;
            }
            if (entityCode === EEntity.Client) {
                const res = await api.get<IResponseLeads>(
                    `/assignees/leads?measurement_point_id=${assignee}`,
                );

                if (res.data.items.length === 0) {
                    throw new Error("404");
                }

                return res.data.items[0].email_from;
            }
            if (entityCode === EEntity.Smartbit) {
                return assignee;
            }
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);
