import { Box, Grid, IconButton, Typography, UpdateIcon } from "@enerbit/base";
import { getOrder } from "../store";
import { handleDetails } from "../store/slices/detail-progress/detailProgress";
import { useAppDispatch, useAppSelector } from "../store/store";
import Backbutton from "./components/back-button/BackButton";
import { EmptyData } from "./components/empty-data";
import { StepperContent } from "./components/stepper/StepperContent";

export const Details = () => {
    const { detailsOrder, loadingDetails } = useAppSelector(
        (state) => state.detailProgress,
    );
    const { selectedOrder } = useAppSelector((state) => state.initialForm);
    const dispatch = useAppDispatch();
    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={11}>
                    <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                        <Backbutton onClick={() => dispatch(handleDetails())} />
                        <Typography
                            color="primary"
                            sx={{
                                fontSize: "28px",
                                fontWeight: 700,
                                mb: "0px",
                            }}
                        >
                            Detalle del progreso de la orden
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={1}>
                    <IconButton
                        onClick={() =>
                            selectedOrder
                                ? dispatch(getOrder(selectedOrder.id))
                                : null
                        }
                        disabled={loadingDetails}
                        color="primary"
                    >
                        <UpdateIcon />
                    </IconButton>
                </Grid>
                <Grid item xs={12}>
                    {detailsOrder ? (
                        <StepperContent />
                    ) : (
                        <EmptyData message="No se encuentra el detalle de esta orden." />
                    )}
                </Grid>
            </Grid>
        </>
    );
};
