import type { IJsonForm } from "../../models/IDetailsCertificates";

export const electricalBoxesRetired: IJsonForm[] = [
    {
        label: "Panel",
        isSection: true,
        type: "array",
        json: [
            {
                label: "Elemento al que corresponde",
                type: "select",
                name: "usage",
                sx: {
                    xs: 12,
                    md: 6,
                },
            },
            {
                label: "Fabricante",
                name: "manufacturer.value",
                type: "text",
                sx: {
                    xs: 12,
                    md: 6,
                },
            },
            {
                label: "Foto de la caja",
                name: "manufacturer.picture_url",
                type: "image",
            },
            {
                label: "Estado visual de la caja",
                type: "select",
                name: "state",
                sx: {
                    xs: 12,
                    md: 6,
                },
            },
        ],
    },
];
