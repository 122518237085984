import type { IJsonForm } from "../../models/IDetailsCertificates";
import { seals } from "./seals";

export const optionalMeterInstalled: IJsonForm[] = [
    {
        label: "El medidor es:",
        type: "select",
        name: "is_installed",
        items: [
            {
                text: "Encontrado",
                value: "false",
            },
            {
                text: "Instalado",
                value: "true",
            },
        ],
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "meter_serial.value",
        label: "Serie medidor",
        type: "number",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "meter_serial.picture_url",
        label: "Foto de la serie del medidor",
        type: "image",
    },
    {
        name: "usage",
        label: "Función del medidor",
        type: "select",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "meter_location",
        label: "Ubicación del medidor",
        type: "select",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "ownership",
        label: "Propiedad del medidor",
        type: "select",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "meter_brand",
        label: "Marca medidor",
        type: "text",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "meter_model",
        label: "Modelo (Referencia)",
        type: "text",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "manufacture_year",
        label: "Año de fabricación",
        type: "number",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "measurement_type",
        label: "Tipo de medición",
        type: "select",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "number_meter_elements",
        label: "Número de elementos del medidor",
        type: "select",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "number_connection_elements",
        label: "Número de elementos de la conexión",
        type: "select",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "connection_correspondence",
        label: "Correspondencia de la conexión",
        type: "select",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "used_elements",
        label: "Elementos utilizados",
        type: "select",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "socket",
        label: "Tomacorriente",
        type: "select",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "wires_count",
        label: "Número de hilos",
        type: "select",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "connection_phases_count",
        label: "Número de fases de la conexión del medidor",
        type: "select",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "active_class_index_percentage",
        label: "Índice de clase activa (%)",
        type: "select",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "reactive_class_index_percentage",
        label: "Índice de clase reactiva (%)",
        type: "select",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "active_energy_imported",
        label: "Lectura importación activa",
        type: "number",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "active_energy_exported",
        label: "Lectura exportación activa",
        type: "number",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "reactive_energy_imported",
        label: "Lectura importación reactiva",
        type: "number",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "reactive_energy_exported",
        label: "Lectura exportación reactiva",
        type: "number",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        label: "Fecha de calibración",
        name: "calibration_date.value",
        type: "date",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        label: "Foto donde se vea el adhesivo de calibración del laboratorio",
        name: "calibration_date.picture_url",
        type: "image",
    },
    {
        label: "Sello",
        name: "seals",
        type: "seals",
        json: seals,
    },
];
