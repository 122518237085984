import { Box, Typography, enerbitColors } from "@enerbit/base";
import type { FC } from "react";
import { orderDescriptions } from "../../../const/descriptions/order_descriptions";

interface Props {
    activityCode: string;
}

const OrderDescription: FC<Props> = ({ activityCode }) => {
    return (
        <Box
            sx={{
                padding: "12px 16px 12px 16px",
                backgroundColor: "rgba(238, 235, 244, 1)",
                border: `1px solid ${enerbitColors.primary.main}`,
                borderRadius: "16px",
                display: "flex",
                flexDirection: "column",
                marginTop: "-12px",
                marginBottom: "24px",
            }}
        >
            <Typography
                sx={{
                    color: enerbitColors.primary.main,
                    fontWeight: 700,
                    fontSize: "16px",
                }}
            >
                ¿Cuándo usar esta orden?
            </Typography>
            <Typography sx={{ color: enerbitColors.primary.main }}>
                {orderDescriptions[activityCode].description}
            </Typography>
        </Box>
    );
};

export default OrderDescription;
