import { Typography } from "@enerbit/base";
import type { FC } from "react";
import { formatDateTime, isValidDate } from "../../../helpers";

interface Props {
    description?: string;
    planned_date_begin?: string;
    planned_date_end?: string;
}

export const HistoryObservations: FC<Props> = ({
    description,
    planned_date_begin,
    planned_date_end,
}) => {
    if (!description) return null;

    return (
        <div>
            <Typography
                color="primary"
                sx={{ fontSize: "18px", fontWeight: 700, mb: "12px" }}
            >
                Información de la orden
            </Typography>
            {planned_date_begin && (
                <p>
                    <b>Fecha y hora de inicio de la orden:</b>{" "}
                    {isValidDate(planned_date_begin)
                        ? formatDateTime(planned_date_begin)
                        : planned_date_begin}
                </p>
            )}
            {planned_date_end && (
                <p>
                    <b>Fecha y hora de finalización de la orden:</b>{" "}
                    {isValidDate(planned_date_end)
                        ? formatDateTime(planned_date_end)
                        : planned_date_end}
                </p>
            )}
            <Typography
                color="primary"
                sx={{ fontSize: "18px", fontWeight: 700, mb: "12px" }}
            >
                Observación de la orden
            </Typography>
            {description && (
                <p>
                    <b>Observación:</b> {description}
                </p>
            )}
        </div>
    );
};
