import dayjs from "dayjs";
import moment from "moment";
import type * as yup from "yup";

export const getInitialMinDate = () => {
    return dayjs();
};

export const getFinalMinDate = (initialDate: string) => {
    return dayjs(initialDate).add(1, "minute"); // Return Dayjs object
};

export const getFinalMaxDate = (initialDate: string) => {
    return dayjs(initialDate); // Return Dayjs object
};

export const getFinalMaxTime = (initialDate: string, finalDate: string) => {
    return dayjs(initialDate).isSame(dayjs(finalDate), "day")
        ? undefined
        : dayjs(initialDate).add(24, "hour"); // Return Dayjs object or undefined
};

export const getInitialMinTime = (initialDate: string) => {
    return dayjs(initialDate).isSame(dayjs(), "day") ? dayjs() : undefined; // Return Dayjs object or undefined
};

export const getFinalMinTime = (initialDate: string, finalDate: string) => {
    return dayjs(initialDate).isSame(dayjs(finalDate), "day")
        ? dayjs(initialDate).add(1, "minute") // Return Dayjs object
        : undefined;
};

export const initialDateValidation = (
    value: string | null | undefined,
    ctx: yup.TestContext,
) => {
    if (!value) {
        return ctx.createError({
            message: "La fecha y hora inicial son obligatorias",
        });
    }

    const minDate = getInitialMinDate();
    const date = dayjs(value);

    if (date.isBefore(minDate)) {
        return ctx.createError({
            message: `La fecha y hora inicial deben ser posteriores a ${minDate.format(
                "YYYY-MM-DD HH:mm:ss",
            )}`,
        });
    }

    // Adjusting for Day.js usage
    const minTime = getInitialMinTime(ctx.parent.planned_date_begin);

    if (minTime) {
        // Use .hour() for Day.js to get the hour part of the Dayjs object
        if (date.hour() < minTime.hour()) {
            return ctx.createError({
                message: `La hora inicial debe estar a partir de ${minTime.hour()}:00`,
            });
        }
    }

    return true;
};

export const finalDateValidation = (
    value: string | null | undefined,
    ctx: yup.TestContext,
) => {
    if (!value) {
        return ctx.createError({
            message: "La fecha y hora final son obligatorias",
        });
    }

    // Ensure getFinalMinDate returns a Dayjs object and adjust accordingly
    const minDate = getFinalMinDate(ctx.parent.planned_date_begin);
    const date = dayjs(value);

    if (date.isBefore(minDate)) {
        return ctx.createError({
            message: `La fecha y hora final deben ser posteriores a ${minDate.format(
                "YYYY-MM-DD HH:mm:ss",
            )}`,
        });
    }

    // Ensure getFinalMinTime returns a Dayjs object or undefined, and adjust the comparison
    const minTime = getFinalMinTime(
        ctx.parent.planned_date_begin,
        ctx.parent.planned_date_end,
    );

    if (minTime && date.isBefore(minTime)) {
        return ctx.createError({
            message: `La hora final debe ser posterior a ${minTime.hour()}:00`,
        });
    }

    return true;
};

export const formatCustomDate = (date: Date | string): string => {
    moment.locale("es");

    const currentDate = moment(date, "YYYY-MM-DD");
    const currentYear = moment().year();

    if (currentDate.year() === currentYear) {
        return currentDate.format("D [de] MMMM");
    } else {
        return currentDate.format("D [de] MMMM [de] YYYY");
    }
};

export const formatCustomTime = (dateString: Date | string): string => {
    moment.locale("es");

    const date = moment(dateString);
    const now = moment();

    if (date.isSame(now, "day")) {
        const diffSeconds = now.diff(date, "seconds");
        const diffMinutes = now.diff(date, "minutes");

        if (diffSeconds < 60) {
            return `hace ${diffSeconds} segundos`;
        } else if (diffMinutes < 60) {
            return `hace ${diffMinutes} minutos`;
        } else {
            return date.format("h:mm A");
        }
    } else {
        return date.format("h:mm A");
    }
};

export const formatDateTime = (dateStr: string | Date) => {
    return moment(dateStr).format("YYYY/MM/DD h:mm A");
};

export const formatDate = (dateStr: string | Date) => {
    return moment(dateStr).format("YYYY/MM/DD");
};

export const isValidDate = (str: string) => {
    return moment(str, moment.ISO_8601, true).isValid();
};

interface IsoDates {
    start: string;
    end: string;
}

export const getRangeISO = (dates: {
    startDate: Date | string;
    endDate: Date | string;
}): IsoDates => {
    // Parsea y ajusta la fecha inicial a las 00:00:00
    const startDate = moment(dates.startDate).startOf("day").toISOString();

    // Parsea y ajusta la fecha final a las 23:59:59
    const endDate = moment(dates.endDate).endOf("day").toISOString();

    return { start: startDate, end: endDate };
};
