/* eslint-disable @typescript-eslint/no-explicit-any */

import type { FC } from "react";
import type { IBody } from "../../../models";
import { HistoryObservations } from "./HistoryObservations";
import { HistoryOrderInfo } from "./HistoryOrderInfo";
import { HistoryServiceInfo } from "./HistoryServiceInfo";

interface Props {
    data: IBody;
    event_on: "orders" | "order_operators" | "order_entities";
}

export const HistoryDetails: FC<Props> = ({ data }) => {
    if (!data || typeof data !== "object") {
        return null;
    }

    const orderInfoData = {
        planned_date_begin: data.planned_date_begin,
        planned_date_end: data.planned_date_end,
        email: data.email,
        order_operators: data.order_operators,
        operator_id: data.operator_id,
        work_group_id: data.work_group_id,
    };

    const serviceInfoData = {
        assignee_id: data.assignee_id,
        order_entities: data.order_entities,
        name_witness: data.form_data?.name_witness,
        phone_contact_witness: data.form_data?.phone_contact_witness,
    };

    const observationsData = {
        description: data.form_data?.description,
        planned_date_begin: data.planned_date_begin,
        planned_date_end: data.planned_date_end,
    };

    return (
        <div>
            <HistoryServiceInfo {...serviceInfoData} />
            <HistoryOrderInfo {...orderInfoData} />
            <HistoryObservations {...observationsData} />
        </div>
    );
};
