import {
    AdapterDayjs,
    DatePicker,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    LocalizationProvider,
    MenuItem,
    Select,
    Typography,
    dayjs,
} from "@enerbit/base";
import { useEffect } from "react";
import { Controller, get, useFormContext } from "react-hook-form";
import { useAppSelector } from "../../../store";

export const OrderCancellationReason = () => {
    const { action, activity } = useAppSelector((state) => state.initialForm);

    const {
        control,
        formState: { errors },
        setValue,
    } = useFormContext();

    useEffect(() => {
        setValue("order_reason", "");
        setValue("form_data.criteria.cancellation_date_xm", "");

        return () => {
            setValue("order_reason", null);
            setValue("form_data.criteria.cancellation_date_xm", null);
        };
    }, []);

    return (
        <Grid item xs={12} container spacing={3}>
            <Grid item xs={12}>
                <Typography
                    color="primary"
                    sx={{ fontSize: "28px", fontWeight: 400, mb: "12px" }}
                >
                    Cancelación de cuenta
                </Typography>
            </Grid>
            <Grid item container spacing={3}>
                <Grid item md={6} xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <InputLabel className="title-input-edition" required>
                            Fecha de cancelación de XM
                        </InputLabel>
                        <Controller
                            name={"form_data.criteria.cancellation_date_xm"}
                            control={control}
                            render={({ field: { value } }) => (
                                <DatePicker
                                    disablePast
                                    disabled={action?.type === "Consult"}
                                    value={value ? dayjs(value) : null}
                                    format="YYYY/MM/DD"
                                    onChange={(date) => {
                                        const dateStr =
                                            date && date.isValid()
                                                ? date.toISOString()
                                                : null;
                                        setValue(
                                            "form_data.criteria.cancellation_date_xm",
                                            dateStr,
                                        );
                                    }}
                                    slotProps={{
                                        textField: {
                                            id: "form_data.criteria.cancellation_date_xm",
                                            color: "primary",
                                            helperText: get(
                                                errors,
                                                "form_data.criteria.cancellation_date_xm",
                                            )
                                                ? get(
                                                      errors,
                                                      "form_data.criteria.cancellation_date_xm",
                                                  ).message
                                                : "",
                                            error: !!get(
                                                errors,
                                                "form_data.criteria.cancellation_date_xm",
                                            ),
                                            sx: {
                                                "& fieldset": {
                                                    borderRadius: "14px",
                                                },
                                                "& p": { color: "red" },
                                                width: "100%",
                                            },
                                        },
                                    }}
                                />
                            )}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={6}>
                    <InputLabel className="title-input-edition" required>
                        Motivo de cancelación de cuenta
                    </InputLabel>
                    <FormControl fullWidth>
                        <Controller
                            name="order_reason"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    disabled={action?.type === "Consult"}
                                    id="activity"
                                    error={!!errors.order_reason}
                                    sx={{ borderRadius: "14px" }}
                                    variant="outlined"
                                    fullWidth
                                >
                                    <MenuItem value={"Baja por falta de pago"}>
                                        Baja por falta de pago
                                    </MenuItem>
                                    <MenuItem value={"Cambio de agente"}>
                                        Cambio de agente
                                    </MenuItem>
                                    <MenuItem
                                        value={"Cambio de nivel de tensión"}
                                    >
                                        Cambio de nivel de tensión
                                    </MenuItem>
                                    <MenuItem value={"Voluntad del cliente"}>
                                        Voluntad del cliente
                                    </MenuItem>
                                    <MenuItem value={"Unificación de cuentas"}>
                                        Unificación de cuentas
                                    </MenuItem>
                                </Select>
                            )}
                            defaultValue=""
                        />
                        {!!errors.order_reason && (
                            <FormHelperText error>
                                {errors.order_reason.message}
                            </FormHelperText>
                        )}
                    </FormControl>
                </Grid>
            </Grid>
        </Grid>
    );
};
