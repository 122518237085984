import {
    Dialog,
    DialogTitle,
    HighlightOffIcon,
    IconButton,
    Typography,
    styled,
} from "@enerbit/base";
import type { FC } from "react";

export const StyledDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiPaper-root.MuiDialog-paper": {
        borderRadius: "30px",
    },
    "& .MuiDialogContent-root": {
        padding: theme.spacing(4),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(2),
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

export const StyledDialogTitle: FC<DialogTitleProps> = ({
    children,
    onClose,
    ...other
}) => {
    return (
        <DialogTitle sx={{ m: 0, pl: 4, pr: 8 }} {...other}>
            <Typography
                sx={{
                    color: "#53358E",
                    fontSize: "25px",
                    lineHeight: "47px",
                    letterSpacing: "-0.02em",
                }}
            >
                <div>{children}</div>
            </Typography>
            {onClose ? (
                <IconButton
                    size="small"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 20,
                        top: 10,
                    }}
                >
                    <HighlightOffIcon color="primary" fontSize="large" />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};
