/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Box, Grid, LoadingButton } from "@enerbit/base";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { EEntity, schemaOrder, validateMultiple } from "../../../helpers";
import { useResetStore } from "../../../hooks/useResetStore";
import type { IPostOrders } from "../../../models/IPostOrders";
import { createOrder, useAppSelector } from "../../../store";
import { useAppDispatch } from "../../../store/store";
import MechanismSuspensionModal from "../activity/MechanismSuspensionModal";
import { OrderCancellationReason } from "../cancellation-reason";
import { InfoOrder } from "../info-order";
import { LeadPartnerContent } from "../lead-partner";
import Notifications from "../nofitifications/Notifications";
import { Observations } from "../observations";
import OtherPeople from "../other-people/OtherPeople";

export const CreateProvider = () => {
    const { enqueueSnackbar } = useSnackbar();
    const {
        activity,
        listEntities,
        addedEntities,
        errorOrder,
        errorContent,
        isDone,
        loadingOrder,
        message,
        action,
        lastCertificate,
    } = useAppSelector((state) => state.initialForm);
    const { listOrders } = useAppSelector(
        (state) => state.listOrders.orders[action?.entity ?? EEntity.Lead],
    );

    const { listOrders: listOrdersClient } = useAppSelector(
        (state) => state.listOrders.orders[EEntity.Client],
    );

    const validActivityCodes = new Set([
        "CCE",
        "CCEG",
        "CS",
        "CSG",
        "NS",
        "NSDG",
    ]);

    const existsOrderClient = useMemo(() => {
        const isLead = action?.entity === EEntity.Lead;
        const currentOrdersClient = listOrdersClient.filter(
            ({ status }) => status !== "cancel",
        );
        const clientOrdersCount = currentOrdersClient.length;
        const isActivityCodeValid = validActivityCodes.has(
            activity?.code ?? "",
        );
        const isTransferClient =
            activity?.code === "CS" || activity?.code === "CSG";

        return (
            isLead &&
            clientOrdersCount > 0 &&
            isActivityCodeValid &&
            !isTransferClient
        );
    }, [action?.entity, listOrdersClient, activity?.code]);

    const existPendingOrder = useMemo(() => {
        return listOrders.find(
            ({ order_type, status }) =>
                status === "pending" && order_type.code === activity?.code,
        );
    }, [listOrders, activity]);

    const { resetFormContent } = useResetStore();
    const dispatch = useAppDispatch();
    const [openModal, setOpenModal] = useState<boolean>(false);

    const methods = useForm<IPostOrders>({
        defaultValues: {
            planned_date_begin: null,
            planned_date_end: null,
            priority: "medium",
            order_reason: null,
            status: "pending",
            form_data: {
                notification_data: [],
                criteria: {
                    cancellation_date_xm: null,
                    coordinations: [],
                },
                other_documents: null,
            },
        },
        resolver: yupResolver(schemaOrder),
        mode: "onChange",
    });

    const {
        handleSubmit,
        setValue,
        reset,
        getValues,
        watch,
        unregister,
        formState: { errors },
    } = methods;
    methods;

    const onSubmit = (formData: IPostOrders) => {
        dispatch(createOrder(formData));
    };

    console.log(errors);

    useEffect(() => {
        if (errorOrder && !errorContent) {
            enqueueSnackbar(message, { variant: "error" });
        }
    }, [errorOrder]);

    useEffect(() => {
        if (errorContent) {
            enqueueSnackbar(`${message}:`, { variant: "error" });
            errorContent.forEach(({ full_name, address }) => {
                enqueueSnackbar(
                    `Nombre: ${full_name}, Dirección: ${address}.\n`,
                    {
                        variant: "error",
                    },
                );
            });
        }
    }, [errorContent]);

    useEffect(() => {
        if (isDone) {
            enqueueSnackbar("La orden fue creada con éxito.", {
                variant: "success",
            });
            resetFormContent();
            const fieldValue = getValues("order_type_id");
            reset();
            setValue("order_type_id", fieldValue);
        }
    }, [isDone]);

    const currentOrderEntitiesLength = watch("order_entities")?.length || 0;

    useEffect(() => {
        const currentOrderEntitiesLength = watch("order_entities")?.length || 0;

        for (
            let i = addedEntities.length;
            i < currentOrderEntitiesLength;
            i++
        ) {
            unregister(`order_entities.${i}`);
        }

        addedEntities.forEach((entity, index) => {
            setValue(`order_entities.${index}.assignee_id`, entity.id);
            const entityData = listEntities.find(
                ({ code }) => action?.entity === code,
            );
            setValue(`order_entities.${index}.entity_id`, entityData?.id ?? "");
            setValue(`order_entities.${index}.form_data.name_witness`, null);
            setValue(
                `order_entities.${index}.form_data.phone_contact_witness`,
                null,
            );
            setValue(
                `order_entities.${index}.form_data.name_third_contact`,
                null,
            );
            setValue(
                `order_entities.${index}.form_data.phone_third_contact`,
                null,
            );
            setValue(
                `order_entities.${index}.form_data.email_third_contact`,
                null,
            );
            setValue(`order_entities.${index}.form_data.observations`, null);
        });
        if (
            addedEntities.length > 0 &&
            addedEntities[0].department &&
            addedEntities[0].city
        ) {
            setValue("state", addedEntities[0].department);
            setValue("city", addedEntities[0].city);
        }
    }, [addedEntities, listEntities, action]);

    useEffect(() => {
        if (activity) {
            setValue("order_type_id", activity.id);
        }
    }, [activity]);

    useEffect(() => {
        if (existPendingOrder) {
            enqueueSnackbar(
                `Hay una orden de ${existPendingOrder.order_type.name} pendiente `,
                {
                    variant: "warning",
                },
            );
        }
    }, [listOrders, activity?.code]);

    useEffect(() => {
        const certificate = lastCertificate ? lastCertificate[0] : null;
        if (
            (activity?.code === "RSE" || activity?.code === "SSE") &&
            lastCertificate &&
            lastCertificate.length > 0 &&
            typeof certificate?.form_data.observations === "object" &&
            typeof certificate?.form_data.observations.for_us === "object" &&
            certificate?.form_data.observations.for_us.suspension_mechanism &&
            certificate?.form_data.observations.for_us.suspension_mechanism ===
                "Suspensión remota"
        ) {
            setOpenModal(true);
        }
    }, [activity]);

    useEffect(() => {
        for (
            let i = addedEntities.length;
            i < currentOrderEntitiesLength;
            i++
        ) {
            unregister(`order_entities.${i}`);
        }
    }, [addedEntities, currentOrderEntitiesLength]);

    useEffect(() => {
        if (existPendingOrder) {
            enqueueSnackbar(`Ya existe una orden pendiente.`, {
                variant: "warning",
            });
        }

        if (
            activity?.name &&
            addedEntities.length <= 1 &&
            validateMultiple(activity?.name)
        ) {
            enqueueSnackbar(
                `Esta orden de gabinete debe tener al menos 2 predios.`,
                {
                    variant: "warning",
                },
            );
        }

        if (existsOrderClient) {
            enqueueSnackbar(`Ya existe una orden de cliente.`, {
                variant: "warning",
            });
        }
    }, [existPendingOrder, addedEntities, existsOrderClient, activity?.name]);

    return (
        <FormProvider {...methods}>
            <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
                <Grid container rowSpacing={3}>
                    <LeadPartnerContent />
                    {(activity?.code === "CC" ||
                        activity?.code === "REMOV_CE_NO_INST") && (
                        <OrderCancellationReason />
                    )}
                    <InfoOrder />
                    <OtherPeople />
                    <Observations />
                    <Notifications />
                    <Grid item display="grid" xs={12}>
                        <LoadingButton
                            id="create-order"
                            disabled={
                                loadingOrder ||
                                !!existPendingOrder ||
                                (!!activity?.name &&
                                    activity?.code !== "ISBG" &&
                                    !!validateMultiple(activity?.name) &&
                                    addedEntities.length <= 1) ||
                                existsOrderClient
                            }
                            loading={loadingOrder}
                            type="submit"
                            variant="contained"
                            color="secondary"
                        >
                            Generar
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Box>
            <MechanismSuspensionModal
                open={openModal}
                handleClose={() => setOpenModal(false)}
            />
        </FormProvider>
    );
};
