import * as yup from "yup";

export const schemaActivityOrder = yup.object().shape({
    activity: yup.string().required("Este campo es requerido."),
    email: yup.string().nullable().required("Este campo es requerido."),
    smartbit: yup
        .string()
        .nullable()
        .matches(
            /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/,
            "Ingrese un id valido.",
        )
        .required("Este campo es requerido."),
    order: yup.string().nullable().required("Este campo es requerido."),
});
