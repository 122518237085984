import { Grid, InputLabel, TextField } from "@enerbit/base";
import type { FC } from "react";
import { useFormContext } from "react-hook-form";
import type { IJsonForm } from "../../../../models/IDetailsCertificates";

export const TextForm: FC<IJsonForm> = ({ name, label, type, sx }) => {
    const { register } = useFormContext();

    return (
        <Grid item xs={sx?.xs} md={sx?.md}>
            <InputLabel className="title-input-edition">{label}</InputLabel>
            <TextField
                inputProps={{ readOnly: true }}
                fullWidth
                {...register(name ?? "")}
                sx={{ "& fieldset": { borderRadius: "14px" } }}
                variant="outlined"
                type={type === "text-area" ? "text" : type}
                multiline={type === "text-area"}
                rows={type === "text-area" ? "3" : undefined}
            />
        </Grid>
    );
};
