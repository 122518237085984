import { api } from "@enerbit/base";
import type { ILeadItem, IOperatorItem, IWorkGroupsItem } from "../models";

export const getTeamHistory = async (id: string) => {
    try {
        const { data } = await api.get<IWorkGroupsItem>(
            `/work-groups/work-groups/${id}`,
        );

        return data.operators.map(({ id, pii, username }) => {
            return {
                id,
                name: pii.full_name,
                email: username,
            };
        });
    } catch (error) {
        return null;
    }
};

export const getOperatorHistory = async (id: string) => {
    try {
        const { data } = await api.get<IOperatorItem>(
            `/ops-users/operators/${id}/`,
        );
        return {
            id,
            name: data.pii.full_name,
            email: data.username,
        };
    } catch (error) {
        return null;
    }
};

export const getOperatorsHistory = async (ids: string[]) => {
    const operators = await Promise.all(
        ids.map(async (id) => {
            try {
                const { data } = await api.get<IOperatorItem>(
                    `/ops-users/operators/${id}/`,
                );
                return {
                    id,
                    name: data.pii.full_name,
                    email: data.username,
                };
            } catch (error) {
                return null;
            }
        }),
    );

    return operators.filter((operator) => operator !== null) as {
        id: string;
        name: string;
        email: string;
    }[];
};

export const getLeadsHistory = async (ids: string[]) => {
    const leads = await Promise.all(
        ids.map(async (id) => {
            try {
                const { data } = await api.get<ILeadItem>(
                    `assignees/leads/${id}`,
                );
                return {
                    id,
                    name: data.display_name,
                    email: data.email_from,
                };
            } catch (error) {
                return null;
            }
        }),
    );

    return leads.filter((lead) => lead !== null) as {
        id: string;
        name: string;
        email: string;
    }[];
};

export const getLeadHistory = async (id: string) => {
    try {
        const { data } = await api.get<ILeadItem>(`assignees/leads/${id}`);
        return {
            id,
            name: data.display_name,
            email: data.email_from,
        };
    } catch (error) {
        return null;
    }
};
