import { api } from "@enerbit/base";

export const getOpsUserById = async (id: string) => {
    const { data } = await api.get(`/ops-users/operators/${id}/`);

    return data.company.id;
};

export const getUserPiiById = async (id: string) => {
    const { data } = await api.get(`/ops-users/operators/${id}/`);
    return {
        ...data.pii,
        company: {
            ...data.company,
        },
    };
};
