import { createOrderAllowedRoles, mapRolesToEntities } from "../const/roles";
import { RoleCode } from "../models/IRoles";
import { EEntity } from "./common";

export const definePermissions = (role: RoleCode, entity: EEntity | null) => {
	if (!entity) return false;
	return (
		mapRolesToEntities[role]?.includes(entity) &&
		createOrderAllowedRoles?.includes(role)
	);
};
