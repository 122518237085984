/* eslint-disable @typescript-eslint/no-explicit-any */
import { api } from "@enerbit/base";
import { createAsyncThunk } from "@reduxjs/toolkit";
import type { IResponseCustomers, IResponseLeads } from "../../../models";

export const getLeads = createAsyncThunk(
    "tableAddress/getLeads",
    async (
        {
            email,
            page = 1,
            size = 5,
            name = "",
        }: {
            email: string;
            page?: number;
            size?: number;
            name?: string;
        },
        { rejectWithValue },
    ) => {
        try {
            const parsedEmail = encodeURIComponent(email);
            const isEmail = email.includes("@");
            const url = isEmail
                ? `/assignees/leads?email=${parsedEmail}&page=${page}&size=${size}${
                      name ? `&contact_name=${name}` : ""
                  }`
                : `/assignees/leads?lead_integrator_id=${parsedEmail}&page=${page}&size=${size}${
                      name ? `&contact_name=${name}` : ""
                  }`;
            const { data } = await api.get<IResponseLeads>(url);
            return data;
        } catch (error: any) {
            return rejectWithValue(error.response?.status);
        }
    },
);

export const getCustomers = createAsyncThunk(
    "tableAddress/getCustomers",
    async (
        {
            email,
            page = 0,
            size = 5,
            name = "",
        }: {
            email: string;
            page?: number;
            size?: number;
            name?: string;
        },
        { rejectWithValue },
    ) => {
        try {
            const parsedEmail = encodeURIComponent(email);
            const { data } = await api.get<IResponseCustomers>(
                `/assignees/customers?email=${parsedEmail}&page=${page}&size=${size}${
                    name ? `&names=${name}` : ""
                }`,
            );
            return data;
        } catch (error: any) {
            return rejectWithValue(error.response?.status);
        }
    },
);
