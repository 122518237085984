import {
    Box,
    CheckIcon,
    Chip,
    Grid,
    LoadingButton,
    Typography,
    decodedJwt,
} from "@enerbit/base";
import { yupResolver } from "@hookform/resolvers/yup";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { schemaCancelOrder } from "../../../helpers";
import { useResetStore } from "../../../hooks/useResetStore";
import type { ICancelOrders } from "../../../models/ICancelOrders";
import { getUserPiiById } from "../../../services/opsUser";
import { cancelOrder, getCancelReasons } from "../../../store";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { InfoCancellation } from "../cancellation-reason";
import { LeadPartnerContent } from "../lead-partner";
import { DeleteItemModalId } from "../modals";

export const CancelProvider = () => {
    const { selectedOrder, isDone, errorOrder, message, loadingCancel } =
        useAppSelector((state) => state.initialForm);
    const [operators, setOperatos] = useState([]);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { resetFormContent } = useResetStore();

    const methods = useForm<ICancelOrders>({
        defaultValues: {
            order_id: selectedOrder?.id,
            cancel_by: decodedJwt()?.sub,
            cancellation_reason_id: "",
            observations: "",
            picture_url: null,
            form_data: {
                geolocalization: null,
            },
        },
        resolver: yupResolver(schemaCancelOrder),
        mode: "onChange",
    });

    const { trigger, getValues } = methods;

    useEffect(() => {
        dispatch(getCancelReasons());
    }, []);

    useEffect(() => {
        if (isDone) {
            enqueueSnackbar("Orden cancelada con éxito.", {
                variant: "success",
            });
            resetFormContent();

            navigate("/");
        }
    }, [isDone]);

    const onSubmit = (formData: ICancelOrders) => {
        dispatch(cancelOrder(formData));
    };

    useEffect(() => {
        if (errorOrder) {
            enqueueSnackbar(message, { variant: "error" });
        }
    }, [errorOrder]);

    const [openCancel, setOpenCancel] = useState(false);
    const handleOpenCancel = async () => {
        const isValid = await trigger();
        if (!isValid) return;
        setOpenCancel(true);
    };
    const handleCloseCancel = () => setOpenCancel(false);
    const handleCancelOrder = () => {
        onSubmit(getValues());
    };

    const getOperator = async () => {
        try {
            const promises = selectedOrder?.order_operators.map(
                async ({ operator_id }) => {
                    const pii = await getUserPiiById(operator_id);
                    return { pii };
                },
            );

            const resolvedOperators = await Promise.all(promises);

            setOperatos([...operators, ...resolvedOperators]);
        } catch (error) {
            enqueueSnackbar("Error consultando operadores", {
                variant: "error",
            });
            return error;
        }
    };

    useEffect(() => {
        getOperator();
    }, []);
    return (
        <FormProvider {...methods}>
            <Box component="form" noValidate>
                <Grid container rowSpacing={3}>
                    <LeadPartnerContent />
                    {operators.length > 0 && (
                        <Grid item xs={12} container spacing={1}>
                            <Grid
                                item
                                sx={{ display: "flex", alignItems: "center" }}
                            >
                                <Typography
                                    variant="body1"
                                    component="span"
                                    sx={{ color: "#344054" }}
                                >
                                    <b>Responsables:</b>
                                </Typography>
                            </Grid>
                            {operators?.map((operator, index: number) => {
                                return (
                                    <Grid item key={index}>
                                        <Chip
                                            icon={
                                                <CheckIcon
                                                    sx={{
                                                        "&&": {
                                                            color: "#53358E",
                                                        },
                                                    }}
                                                />
                                            }
                                            label={
                                                operator?.pii?.full_name || ""
                                            }
                                            sx={{
                                                borderRadius: 0,
                                                color: "#53358E",
                                                backgroundColor: "white",
                                            }}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    )}
                    <InfoCancellation />
                    <Grid item display="grid" xs={12}>
                        <LoadingButton
                            loading={loadingCancel}
                            id="cancel-order"
                            onClick={handleOpenCancel}
                            variant="contained"
                            color="secondary"
                        >
                            Cancelar
                        </LoadingButton>
                    </Grid>
                </Grid>
                <DeleteItemModalId
                    open={openCancel}
                    handleClose={handleCloseCancel}
                    deleteItemId={handleCancelOrder}
                    message="¿Está seguro que desea cancelar esta orden?"
                />
            </Box>
        </FormProvider>
    );
};
