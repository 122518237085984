import { Box, Grid, Typography } from "@enerbit/base";
import { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { EEntity } from "../../../helpers";
import {
    getCurrentEntity,
    getInitialEntities,
    getOrder,
    getSmartBit,
    useAppDispatch,
    useAppSelector,
} from "../../../store";
import {
    resetLeads,
    setAction,
    setEntity,
} from "../../../store/slices/initial-form/initialForm";
import Backbutton from "../back-button/BackButton";
import { CancelProvider } from "../form-provider";
import { LoadingOrder } from "../loading";

export const CancelOrder = () => {
    const { selectedOrder, loadingOrder, action } = useAppSelector(
        (state) => state.initialForm,
    );

    const dispatch = useAppDispatch();

    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (!id) return;
        dispatch(getOrder(id));
    }, []);

    useEffect(() => {
        if (
            selectedOrder &&
            selectedOrder.status !== "pending" &&
            selectedOrder.status !== "pending_assignment"
        ) {
            navigate("/");
        }
    }, [selectedOrder]);

    useEffect(() => {
        const entity = searchParams.get("entity");
        const assigneeId = searchParams.get("assignee");

        if (!selectedOrder || !entity || !assigneeId) return;

        dispatch(setEntity(entity as EEntity));

        if (!action?.isFromSearch) {
            dispatch(
                getCurrentEntity({
                    entityCode: entity as EEntity,
                    assignee: assigneeId,
                }),
            );
        }

        if (entity !== EEntity.Smartbit) {
            dispatch(getInitialEntities(selectedOrder.order_entities));
        } else {
            dispatch(
                getSmartBit({
                    id: selectedOrder.order_entities[0].assignee_id,
                    isCreate: true,
                }),
            );
        }

        dispatch(
            setAction({
                type: "Cancel",
                entity: entity as EEntity,
                isFromSearch: action?.isFromSearch,
            }),
        );
    }, [selectedOrder]);

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                        <Backbutton
                            onClick={() => {
                                if (!action?.isFromSearch) {
                                    dispatch(resetLeads());
                                }
                                navigate("/");
                            }}
                        />{" "}
                        <Typography
                            color="primary"
                            sx={{
                                fontSize: "28px",
                                fontWeight: 700,
                                display: "flex",
                                flex: 1,
                            }}
                        >
                            Cancelar orden de servicio
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
            {selectedOrder && !loadingOrder && <CancelProvider />}
            {loadingOrder && <LoadingOrder />}
        </>
    );
};
