import type { IJsonForm } from "../../models/IDetailsCertificates";

export const measurementSystemData: IJsonForm[] = [
    {
        label: "Torre",
        name: "towers",
        type: "array",
        json: [
            {
                label: "Nombre",
                type: "text",
                name: "name",
                sx: {
                    xs: 12,
                    md: 6,
                },
            },
            {
                label: "Gabinete",
                name: "list_cabinet",
                type: "array",
                json: [
                    {
                        label: "Serie gabinete",
                        type: "text",
                        name: "name",
                        sx: {
                            xs: 12,
                            md: 6,
                        },
                    },
                    {
                        label: "Cantidad de medidores",
                        type: "number",
                        name: "meters_quantity",
                        sx: {
                            xs: 12,
                            md: 6,
                        },
                    },

                    {
                        label: "Piso",
                        type: "number",
                        name: "flat",
                        sx: {
                            xs: 12,
                            md: 6,
                        },
                    },
                    {
                        label: "Observaciones",
                        type: "text-area",
                        name: "observation",
                        sx: {
                            xs: 12,
                        },
                    },
                    {
                        label: "Foto gabinete",
                        type: "image",
                        name: "cabinet_picture",
                        sx: {
                            xs: 12,
                        },
                    },
                ],
            },
        ],
    },
];
