/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
    Box,
    Grid,
    IconButton,
    RemoveRedEyeOutlinedIcon,
    Typography,
    enerbitColors,
    useSnackbar,
} from "@enerbit/base";
import { type FC, useState } from "react";
import { useFormContext } from "react-hook-form";
import { StyledTooltip } from "../../../../common";
import type { IJsonForm } from "../../../../models/IDetailsCertificates";
import { loadImage } from "../../../../services";
import { PreviewImageModal } from "../../modals";

export const ImageSealForm: FC<IJsonForm> = ({ name, label }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { getValues } = useFormContext();
    const [previewImage, setPreviewImage] = useState("");
    const [openPreviewImage, setOpenPreviewImage] = useState(false);
    const handleOpenPreviewImage = async () => {
        await loadImage(getValues(name ?? ""))
            .then((image) => {
                setPreviewImage(image);
                setOpenPreviewImage(true);
            })
            .catch(() =>
                enqueueSnackbar("No se pudo cargar la imagen.", {
                    variant: "error",
                }),
            );
    };
    const handleClosePreviewImage = () => setOpenPreviewImage(false);

    return (
        <Grid item xs={12}>
            {getValues(name ?? "") ? (
                <Grid
                    container
                    p={1}
                    columnSpacing={2}
                    className="container-img-seals"
                >
                    <Grid
                        container
                        item
                        columnSpacing={2}
                        xs={6}
                        display="flex"
                        alignItems="center"
                    >
                        <Box className="img-seals">IMG</Box>
                        <Typography
                            className="item-box-certification-edit"
                            sx={{ fontSize: "16px", px: 1 }}
                        >
                            {label}
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        item
                        columnSpacing={1}
                        xs={6}
                        display="flex"
                        justifyContent="end"
                    >
                        <Grid item>
                            <StyledTooltip title="Haz click para visualizar la imagen">
                                <IconButton onClick={handleOpenPreviewImage}>
                                    <RemoveRedEyeOutlinedIcon />
                                </IconButton>
                            </StyledTooltip>
                            <PreviewImageModal
                                open={openPreviewImage}
                                handleClose={handleClosePreviewImage}
                                previewImage={previewImage}
                                label={label!}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            ) : (
                <Grid container spacing={3}>
                    <Grid item>
                        <Typography
                            sx={{
                                fontWeight: "bold",
                                color: enerbitColors.primary.main,
                            }}
                        >
                            {label}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Box className="border-file" width={"100%"}>
                            <Grid
                                p={4}
                                container
                                columnSpacing={2}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Grid item xs={12}>
                                    <Typography textAlign={"center"}>
                                        Aún no hay foto.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};
