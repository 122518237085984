import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import { getLeads } from ".";
import type { ICustomerItem, ILeadItem, ITableAddress } from "../../../models";
import { getCustomers } from "./thunk";

const initialState: ITableAddress = {
    listLeads: [],
    listCustomers: [],
    loading: false,
    page: 0,
    size: 5,
    rowCount: 1,
    selectedLeads: [],
    selectedCustomers: [],
    error: false,
    open: false,
    openAdd: false,
    name: "",
    messageTable: "",
};

const tableAddress = createSlice({
    name: "tableAddress",
    initialState,
    reducers: {
        resetList: (state) => {
            state.listLeads = [];
            state.listCustomers = [];
        },
        setName: (state, { payload }: PayloadAction<string>) => {
            state.name = payload;
        },
        setPage(state, action: PayloadAction<number>) {
            state.page = action.payload;
        },
        setPageSize(state, action: PayloadAction<number>) {
            state.size = action.payload;
        },
        handleLead(state, { payload }: PayloadAction<ILeadItem>) {
            state.selectedLeads = [payload];
        },
        handleLeads(state, { payload }: PayloadAction<ILeadItem>) {
            const exists = !!state.selectedLeads.find(
                ({ id }) => id === payload.id,
            );

            state.selectedLeads = !exists
                ? [...state.selectedLeads, payload]
                : state.selectedLeads.filter(({ id }) => id !== payload.id);
        },
        handleCustomer(state, { payload }: PayloadAction<ICustomerItem>) {
            state.selectedCustomers = [payload];
        },
        handleCustomers(state, { payload }: PayloadAction<ICustomerItem>) {
            const exists = !!state.selectedCustomers.find(
                ({ measurement_point_id }) =>
                    measurement_point_id === payload.measurement_point_id,
            );

            state.selectedCustomers = !exists
                ? [...state.selectedCustomers, payload]
                : state.selectedCustomers.filter(
                      ({ measurement_point_id }) =>
                          measurement_point_id !== payload.measurement_point_id,
                  );
        },
        openModal: (state) => {
            state.open = true;
        },
        closeModal: (state) => {
            state.open = false;
            resetTableAddress();
        },
        openAddModal: (state) => {
            state.openAdd = true;
        },
        closeAddModal: (state) => {
            state.openAdd = false;
        },
        resetTableAddress: () => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getLeads.pending, (state) => {
            state.loading = true;
            state.error = false;
        });
        builder.addCase(getLeads.fulfilled, (state, { payload }) => {
            state.loading = false;
            if (payload) {
                if (!state.openAdd) {
                    state.open = true;
                }
                state.listLeads = payload.items;
                state.rowCount = payload.total;
            }
        });
        builder.addCase(getLeads.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = true;
            state.listLeads = [];
            if (payload === 404) {
                state.messageTable =
                    "Este lead no existe o no cuenta con predios asociados.";
            } else {
                state.open = false;
                state.messageTable =
                    "Ha ocurrido un error inesperado, por favor inténtelo de nuevo.";
            }
        });
        builder.addCase(getCustomers.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getCustomers.fulfilled, (state, { payload }) => {
            state.loading = false;
            if (payload) {
                if (!state.openAdd) {
                    state.open = true;
                }
                state.listCustomers = payload.items;
                state.rowCount = Math.ceil(payload.total / state.size);
            }
        });
        builder.addCase(getCustomers.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = true;
            state.listCustomers = [];
            if (payload === 404) {
                state.messageTable =
                    "Este cliente no existe o no cuenta con predios asociados.";
            } else {
                state.open = false;
                state.messageTable =
                    "Ha ocurrido un error inesperado, por favor inténtelo de nuevo.";
            }
        });
    },
});

export const {
    setPage,
    setPageSize,
    handleLead,
    handleLeads,
    handleCustomer,
    handleCustomers,
    resetTableAddress,
    openModal,
    closeModal,
    openAddModal,
    closeAddModal,
    setName,
    resetList,
} = tableAddress.actions;

export default tableAddress.reducer;
