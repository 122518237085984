import type { IJsonForm } from "../../models/IDetailsCertificates";

export const irregularitiesData: IJsonForm[] = [
    {
        name: "use_other_than_contracted",
        label: "¿Uso distinto al contratado?",
        type: "switch",
        isIcon: true,
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "use_other_than_contracted.picture_url",
        label: "Foto del exterior del predio donde se evidencie que corresponde a un negocio",
        type: "image",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "use_other_than_contracted.local_name",
        label: "Nombre del local",
        type: "text",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "use_other_than_contracted.local_activity_economy",
        label: "Actividad  económica del local",
        type: "select",
        sx: {
            xs: 12,
            md: 6,
        },
    },

    {
        name: "use_other_than_contracted",
        label: "Extensión de área para cada uso",
        type: "title",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "use_other_than_contracted.area_extension",
        label: "Extensión de área para uso comercial (m2)",
        type: "number",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "use_other_than_contracted",
        label: "Censo de carga",
        type: "title",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        label: "Electrodoméstico",
        name: "use_other_than_contracted.census",
        type: "array",
        json: [
            {
                label: "Artículo o electrodoméstico",
                name: "appliance",
                type: "text",
                sx: {
                    xs: 12,
                    md: 6,
                },
            },
            {
                label: "Cantidad",
                name: "quantity",
                type: "number",
                sx: {
                    xs: 12,
                    md: 6,
                },
            },
            {
                label: "Potencia nominal unitaria [kW]",
                name: "nominal_power",
                type: "number",
                sx: {
                    xs: 12,
                    md: 6,
                },
            },
            {
                label: "Voltaje nominal [V]",
                name: "nominal_voltage",
                type: "number",
                sx: {
                    xs: 12,
                    md: 6,
                },
            },
            {
                label: "Corriente nominal [A]",
                name: "nominal_current",
                type: "number",
                sx: {
                    xs: 12,
                    md: 6,
                },
            },
            {
                label: "Número de fases",
                name: "phases_number",
                type: "number",
                sx: {
                    xs: 12,
                    md: 6,
                },
            },
            {
                label: "Potencia total [kW]",
                name: "total_power",
                type: "number",
                sx: {
                    xs: 12,
                    md: 6,
                },
            },
            {
                label: "Días de uso al mes",
                name: "days_use_month",
                type: "number",
                sx: {
                    xs: 12,
                    md: 6,
                },
            },
            {
                label: "Horas de uso al día",
                name: "hours_use_day",
                type: "number",
                sx: {
                    xs: 12,
                    md: 6,
                },
            },
            {
                label: "Consumo estimado [kWh/mes]",
                name: "estimated_consumption",
                type: "number",
                sx: {
                    xs: 12,
                    md: 6,
                },
            },
            {
                label: "Marca",
                name: "brand",
                type: "text",
                sx: {
                    xs: 12,
                    md: 6,
                },
            },
            {
                label: "Modelo",
                name: "model",
                type: "text",
                sx: {
                    xs: 12,
                    md: 6,
                },
            },
        ],
    },
    {
        name: "use_other_than_contracted.observations",
        label: "Observaciones",
        type: "text-area",
        sx: {
            xs: 12,
        },
    },

    {
        name: "load_increase",
        label: "¿Aumento de carga o capacidad instalada sin autorización?",
        type: "switch",
        isIcon: true,
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "load_increase.picture_url",
        label: "Foto donde se evidencia la capacidad del transformador de potencia o la medición de la corriente según aplique",
        type: "image",
    },
    {
        name: "load_increase.observations",
        label: "Observaciones",
        type: "text-area",
        sx: {
            xs: 12,
        },
    },

    //-----------------------------//--------------------------
    {
        name: "electric_connection",
        label: "¿Acometida derivada?",
        type: "switch",
        isIcon: true,
        sx: {
            xs: 12,
            md: 6,
        },
    },

    {
        name: "electric_connection.current_electric_connection",
        label: "Corriente acometida derivada",
        type: "text",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "electric_connection.picture_url",
        label: "Foto de la derivación antes de retirar el cable o normalizar y foto de la medición de la corriente si la derivación tiene carga en uso",
        type: "image",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "electric_connection",
        label: "Censo de carga",
        type: "title",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        label: "Electrodoméstico",
        name: "electric_connection.census",
        type: "array",
        json: [
            {
                label: "Artículo o electrodoméstico",
                name: "appliance",
                type: "text",
                sx: {
                    xs: 12,
                    md: 6,
                },
            },
            {
                label: "Cantidad",
                name: "quantity",
                type: "number",
                sx: {
                    xs: 12,
                    md: 6,
                },
            },
            {
                label: "Potencia nominal unitaria [kW]",
                name: "nominal_power",
                type: "number",
                sx: {
                    xs: 12,
                    md: 6,
                },
            },
            {
                label: "Voltaje nominal [V]",
                name: "nominal_voltage",
                type: "number",
                sx: {
                    xs: 12,
                    md: 6,
                },
            },
            {
                label: "Corriente nominal [A]",
                name: "nominal_current",
                type: "number",
                sx: {
                    xs: 12,
                    md: 6,
                },
            },
            {
                label: "Número de fases",
                name: "phases_number",
                type: "number",
                sx: {
                    xs: 12,
                    md: 6,
                },
            },
            {
                label: "Potencia total [kW]",
                name: "total_power",
                type: "number",
                sx: {
                    xs: 12,
                    md: 6,
                },
            },
            {
                label: "Días de uso al mes",
                name: "days_use_month",
                type: "number",
                sx: {
                    xs: 12,
                    md: 6,
                },
            },
            {
                label: "Horas de uso al día",
                name: "hours_use_day",
                type: "number",
                sx: {
                    xs: 12,
                    md: 6,
                },
            },
            {
                label: "Consumo estimado [kWh/mes]",
                name: "estimated_consumption",
                type: "number",
                sx: {
                    xs: 12,
                    md: 6,
                },
            },
            {
                label: "Marca",
                name: "brand",
                type: "text",
                sx: {
                    xs: 12,
                    md: 6,
                },
            },
            {
                label: "Modelo",
                name: "model",
                type: "text",
                sx: {
                    xs: 12,
                    md: 6,
                },
            },
        ],
    },
    {
        name: "electric_connection.observations",
        label: "Observaciones",
        type: "text-area",
        sx: {
            xs: 12,
        },
    },

    //-----------------------------//--------------------------
    {
        name: "unauthorized_electric_connection",
        label: "¿Acometida no autorizada o servicio directo?",
        type: "switch",
        isIcon: true,
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "unauthorized_electric_connection.direct_current",
        label: "Corriente acometida directa",
        type: "text",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "unauthorized_electric_connection.picture_url",
        label: "Foto que evidencie la acometida no autorizada y la medición de la corriente si hay carga en uso",
        type: "image",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "unauthorized_electric_connection",
        label: "Censo de carga",
        type: "title",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        label: "Electrodoméstico",
        name: "unauthorized_electric_connection.census",
        type: "array",
        json: [
            {
                label: "Artículo o electrodoméstico",
                name: "appliance",
                type: "text",
                sx: {
                    xs: 12,
                    md: 6,
                },
            },
            {
                label: "Cantidad",
                name: "quantity",
                type: "number",
                sx: {
                    xs: 12,
                    md: 6,
                },
            },
            {
                label: "Potencia nominal unitaria [kW]",
                name: "nominal_power",
                type: "number",
                sx: {
                    xs: 12,
                    md: 6,
                },
            },
            {
                label: "Voltaje nominal [V]",
                name: "nominal_voltage",
                type: "number",
                sx: {
                    xs: 12,
                    md: 6,
                },
            },
            {
                label: "Corriente nominal [A]",
                name: "nominal_current",
                type: "number",
                sx: {
                    xs: 12,
                    md: 6,
                },
            },
            {
                label: "Número de fases",
                name: "phases_number",
                type: "number",
                sx: {
                    xs: 12,
                    md: 6,
                },
            },
            {
                label: "Potencia total [kW]",
                name: "total_power",
                type: "number",
                sx: {
                    xs: 12,
                    md: 6,
                },
            },
            {
                label: "Días de uso al mes",
                name: "days_use_month",
                type: "number",
                sx: {
                    xs: 12,
                    md: 6,
                },
            },
            {
                label: "Horas de uso al día",
                name: "hours_use_day",
                type: "number",
                sx: {
                    xs: 12,
                    md: 6,
                },
            },
            {
                label: "Consumo estimado [kWh/mes]",
                name: "estimated_consumption",
                type: "number",
                sx: {
                    xs: 12,
                    md: 6,
                },
            },
            {
                label: "Marca",
                name: "brand",
                type: "text",
                sx: {
                    xs: 12,
                    md: 6,
                },
            },
            {
                label: "Modelo",
                name: "model",
                type: "text",
                sx: {
                    xs: 12,
                    md: 6,
                },
            },
        ],
    },
    {
        name: "unauthorized_electric_connection.observations",
        label: "Observaciones",
        type: "text-area",
        sx: {
            xs: 12,
        },
    },

    //-----------------------------//--------------------------
    {
        name: "multiplier_factor_error",
        label: "¿Error en el factor de liquidación externo?",
        type: "switch",
        isIcon: true,
        sx: {
            xs: 12,
            md: 6,
        },
    },

    {
        name: "multiplier_factor_error.picture_url",
        label: "Foto a la placa de características de uno de los transformadores de corriente y uno de los transformadores de tensión (estos últimos si los hay)",
        type: "image",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "multiplier_factor_error.observations",
        label: "Observaciones",
        type: "text-area",
        sx: {
            xs: 12,
        },
    },

    //-----------------------------//--------------------------
    {
        name: "unauthorized_reconnection",
        label: "¿Reconexión no autorizada?",
        type: "switch",
        isIcon: true,
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "unauthorized_reconnection.picture_url",
        label: "Foto que evidencie la irregularidad si la conexión no se hizo a través del medidor",
        type: "image",
        sx: {
            xs: 12,
            md: 6,
        },
    },

    {
        name: "unauthorized_reconnection.observations",
        label: "Observaciones",
        type: "text-area",
        sx: {
            xs: 12,
        },
    },
    //-----------------------------//--------------------------
    {
        name: "meter_broken_seals",
        label: "¿Tapa principal del medidor con sellos de seguridad rotos, adulterados o retirados?",
        type: "switch",
        isIcon: true,
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "meter_broken_seals.picture_url",
        label: "Foto del porta sello de la tapa principal del medidor, donde se observe la serie del medidor",
        type: "image",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "meter_broken_seals.serial",
        label: "Número del serial de la bolsa de seguridad",
        type: "number",
        sx: {
            xs: 12,
            md: 6,
        },
    },

    {
        name: "meter_broken_seals.observations",
        label: "Observaciones",
        type: "text-area",
        sx: {
            xs: 12,
        },
    },

    //-----------------------------//--------------------------
    {
        name: "terminal_broken_seals",
        label: "¿Bornera de conexiones del medidor con sellos de seguridad rotos, adulterados o retirados?",
        type: "switch",
        isIcon: true,
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "terminal_broken_seals.picture_url",
        label: "Foto del porta sello o de los sellos de la tapa de la bornera de conexiones del medidor, antes de ser normalizados",
        type: "image",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "terminal_broken_seals.serial",
        label: "Número del serial de la bolsa de seguridad",
        type: "number",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "terminal_broken_seals.observations",
        label: "Observaciones",
        type: "text-area",
        sx: {
            xs: 12,
        },
    },

    //----------------------//--------------------//

    {
        name: "test_block_broken_seals",
        label: "¿Bloque de pruebas con sellos de seguridad rotos, adulterados o retirados?",
        type: "switch",
        isIcon: true,
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "test_block_broken_seals.picture_url",
        label: "Foto del porta sello o de los sellos de la tapa del bloque de conexiones, antes de ser normalizados",
        type: "image",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "test_block_broken_seals.serial",
        label: "Número del serial de la bolsa de seguridad",
        type: "number",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "test_block_broken_seals.observations",
        label: "Observaciones",
        type: "text-area",
        sx: {
            xs: 12,
        },
    },

    //---------------------//--------------------//
    {
        name: "current_transformer_broken_seals",
        label: "¿Transformadores de corriente con sellos de seguridad rotos, adulterados o retirados?",
        type: "switch",
        isIcon: true,
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "current_transformer_broken_seals.picture_url",
        label: "Foto del porta sello o de los sellos de la tapa de conexiones, antes de ser normalizados",
        type: "image",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "current_transformer_broken_seals.observations",
        label: "Observaciones",
        type: "text-area",
        sx: {
            xs: 12,
        },
    },

    //----------------------------//------------------//
    {
        name: "voltage_transformer_broken_seals",
        label: "¿Transformadores de tensión con sellos de seguridad rotos, adulterados o retirados?",
        type: "switch",
        isIcon: true,
        sx: {
            xs: 12,
            md: 6,
        },
    },

    {
        name: "voltage_transformer_broken_seals.picture_url",
        label: "Foto del porta sello o de los sellos de la tapa de conexiones, antes de ser normalizados",
        type: "image",
        sx: {
            xs: 12,
            md: 6,
        },
    },

    {
        name: "voltage_transformer_broken_seals.observations",
        label: "Observaciones",
        type: "text-area",
        sx: {
            xs: 12,
        },
    },
    //----------------------------//------------------//
    {
        name: "terminal_meter_connection_altered",
        label: "¿Bornera de conexiones del medidor con conexiones alteradas o dañadas?",
        type: "switch",
        isIcon: true,
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "terminal_meter_connection_altered.picture_url",
        label: "Foto que evidencie la irregularidad",
        type: "image",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "terminal_meter_connection_altered.observations",
        label: "Observaciones",
        type: "text-area",
        sx: {
            xs: 12,
        },
    },

    //----------------------------//------------------//
    {
        name: "meter_connection_terminal_bridge",
        label: "¿Bornera de conexiones del medidor con puente?",
        type: "switch",
        isIcon: true,
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "meter_connection_terminal_bridge.picture_url",
        label: "Foto que evidencie la irregularidad sin quitar el puente",
        type: "image",
        sx: {
            xs: 12,
            md: 6,
        },
    },

    {
        name: "meter_connection_terminal_bridge.serial",
        label: "Número del serial de la bolsa de seguridad",
        type: "number",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "meter_connection_terminal_bridge.observations",
        label: "Observaciones",
        type: "text-area",
        sx: {
            xs: 12,
        },
    },

    //----------------------------//------------------//

    {
        name: "block_test_connection_altered",
        label: "¿Bloque de pruebas con conexiones alteradas o dañadas?",
        type: "switch",
        isIcon: true,
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "block_test_connection_altered.picture_url",
        label: "Foto que evidencie la irregularidad",
        type: "image",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "block_test_connection_altered.observations",
        label: "Observaciones",
        type: "text-area",
        sx: {
            xs: 12,
        },
    },

    //----------------------------//------------------//

    {
        name: "transformers_connection_altered",
        label: "¿Transformador de medida con conexiones alteradas o dañadas?",
        type: "switch",
        isIcon: true,
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "transformers_connection_altered.picture_url",
        label: "Foto que evidencie la irregularidad",
        type: "image",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "transformers_connection_altered.observations",
        label: "Observaciones",
        type: "text-area",
        sx: {
            xs: 12,
        },
    },

    //----------------------------//------------------//

    {
        name: "bad_test_block",
        label: "¿Bloque de pruebas en mal estado, sulfatado, partido o fogoneado?",
        type: "switch",
        isIcon: true,
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "bad_test_block.picture_url",
        label: "Foto que evidencie la irregularidad",
        type: "image",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "bad_test_block.observations",
        label: "Observaciones",
        type: "text-area",
        sx: {
            xs: 12,
        },
    },

    //----------------------------//------------------//

    {
        name: "damaged_main_cover",
        label: "¿Tapa principal del medidor dañada o perforada?",
        type: "switch",
        isIcon: true,
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "damaged_main_cover.picture_url",
        label: "Foto que evidencie la irregularidad",
        type: "image",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "damaged_main_cover.serial",
        label: "Número del serial de la bolsa de seguridad",
        type: "number",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "damaged_main_cover.observations",
        label: "Observaciones",
        type: "text-area",
        sx: {
            xs: 12,
        },
    },

    //----------------------------//------------------//

    {
        name: "meter_connection_terminal_block_without_cover",
        label: "¿Bornera de conexiones del medidor sin tapa?",
        type: "switch",
        isIcon: true,
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "meter_connection_terminal_block_without_cover.picture_url",
        label: "Foto que evidencie la irregularidad",
        type: "image",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "meter_connection_terminal_block_without_cover.serial",
        label: "Número del serial de la bolsa de seguridad",
        type: "number",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "meter_connection_terminal_block_without_cover.observations",
        label: "Observaciones",
        type: "text-area",
        sx: {
            xs: 12,
        },
    },

    //----------------------------//------------------//

    {
        name: "burnt_meter",
        label: "¿Medidor quemado o con bornera de conexiones sulfatada o fogoneada?",
        type: "switch",
        isIcon: true,
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "burnt_meter.picture_url",
        label: "Foto que evidencie la irregularidad",
        type: "image",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "burnt_meter.observations",
        label: "Observaciones",
        type: "text-area",
        sx: {
            xs: 12,
        },
    },

    //----------------------------//------------------//

    {
        name: "meter_reading_not_readable",
        label: "¿Lectura del medidor no legible por display del medidor apagado, con segmentos quemados o registro en mal estado?",
        type: "switch",
        isIcon: true,
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "meter_reading_not_readable.picture_url",
        label: "Foto que evidencie la irregularidad",
        type: "image",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "meter_reading_not_readable.serial",
        label: "Número del serial de la bolsa de seguridad",
        type: "number",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "meter_reading_not_readable.observations",
        label: "Observaciones",
        type: "text-area",
        sx: {
            xs: 12,
        },
    },

    //----------------------------//------------------//

    {
        name: "meter_with_moisture",
        label: "¿Medidor con humedad o partículas internas?",
        type: "switch",
        isIcon: true,
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "meter_with_moisture.picture_url",
        label: "Foto que evidencie la irregularidad",
        type: "image",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "meter_with_moisture.serial",
        label: "Número del serial de la bolsa de seguridad",
        type: "number",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "meter_with_moisture.observations",
        label: "Observaciones",
        type: "text-area",
        sx: {
            xs: 12,
        },
    },

    //----------------------------//------------------//

    {
        name: "braking_meter",
        label: "¿Medidor frenado o con pruebas técnicas no conformes?",
        type: "switch",
        isIcon: true,
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "braking_meter.picture_url",
        label: "Pantallazo de las pruebas técnicas no conformes",
        type: "image",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "braking_meter.serial",
        label: "Número del serial de la bolsa de seguridad",
        type: "number",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "braking_meter.observations",
        label: "Observaciones",
        type: "text-area",
        sx: {
            xs: 12,
        },
    },
];
