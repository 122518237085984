import {
	AddCircleOutlineIcon,
	Box,
	Button,
	FormControlLabel,
	Grid,
	Switch,
	TextField,
	Typography,
	enerbitColors,
} from "@enerbit/base";

import ClearIcon from "@mui/icons-material/Clear";
import { useEffect, useState } from "react";
import {
	Controller,
	get,
	useFieldArray,
	useFormContext,
} from "react-hook-form";
import DropZone from "../drop-zone/DropZone";

const OtherPeople = () => {
	const {
			control,
			watch,
			setValue,
			formState: { errors },
	} = useFormContext();

	const [isOtherPeople, setIsOtherPeople] = useState(
			!!watch("form_data.other_documents"),
	);

	const { fields, append, remove } = useFieldArray({
			control,
			name: "form_data.other_documents",
	});

	const emptyDocument = {
			full_name: "",
			company_name: "",
			role: "",
			path_file: [""],
	};

	useEffect(() => {
			setIsOtherPeople(!!watch("form_data.other_documents"));
	}, [watch("form_data.other_documents")]);

	useEffect(() => {
			if (!isOtherPeople) {
					setValue("form_data.other_documents", null);
			} else if (isOtherPeople && !watch("form_data.other_documents")) {
					setValue("form_data.other_documents", [emptyDocument]);
			}
	}, [isOtherPeople]);

	return (
			<Grid my={1} xs={12}>
					<Typography
							color="primary"
							sx={{ fontSize: "28px", fontWeight: 400, mb: "12px" }}
					>
							Acompañantes
					</Typography>

					<FormControlLabel
							sx={{ width: "100%" }}
							control={
									<Switch
											id="other-people-switch"
											checked={isOtherPeople}
											onChange={(e) => setIsOtherPeople(e.target.checked)}
											color="secondary"
									/>
							}
							label={
									<Typography color={"#53358E"}>
											¿Esta orden tendrá acompañantes?
									</Typography>
							}
					/>

					{isOtherPeople && watch("form_data.other_documents") && (
							<>
									{fields.map((field, index: number) => (
											<Box
													key={field.id}
													sx={{
															border: `1px solid ${enerbitColors.primary.main}`,
															borderRadius: "12px",
															p: 3,
															my: 2,
															position: "relative",
													}}
											>
													{watch("form_data.other_documents").length > 1 && (
															<ClearIcon
																	color="error"
																	sx={{
																			position: "absolute",
																			right: "10px",
																			top: "10px",
																			cursor: "pointer",
																	}}
																	onClick={() => remove(index)}
															/>
													)}
													<Grid container spacing={2}>
															<Grid item xs={4}>
																	<Controller
																			name={`form_data.other_documents[${index}].full_name`}
																			control={control}
																			render={({ field }) => (
																					<TextField
																							{...field}
																							label="Nombre completo"
																							variant="outlined"
																							fullWidth
																							margin="normal"
																							error={
																									!!get(
																											errors,
																											`form_data.other_documents[${index}].full_name`,
																									)
																							}
																							helperText={
																									!!get(
																											errors,
																											`form_data.other_documents[${index}].full_name`,
																									) &&
																									get(
																											errors,
																											`form_data.other_documents[${index}].full_name`,
																									).message
																							}
																					/>
																			)}
																	/>
															</Grid>

															<Grid item xs={4}>
																	<Controller
																			name={`form_data.other_documents[${index}].company_name`}
																			control={control}
																			render={({ field }) => (
																					<TextField
																							{...field}
																							label="Nombre de la empresa"
																							variant="outlined"
																							fullWidth
																							margin="normal"
																							error={
																									!!get(
																											errors,
																											`form_data.other_documents[${index}].company_name`,
																									)
																							}
																							helperText={
																									!!get(
																											errors,
																											`form_data.other_documents[${index}].company_name`,
																									) &&
																									get(
																											errors,
																											`form_data.other_documents[${index}].company_name`,
																									).message
																							}
																					/>
																			)}
																	/>
															</Grid>

															<Grid item xs={4}>
																	<Controller
																			name={`form_data.other_documents[${index}].role`}
																			control={control}
																			render={({ field }) => (
																					<TextField
																							{...field}
																							label="Rol"
																							variant="outlined"
																							fullWidth
																							margin="normal"
																							error={
																									!!get(
																											errors,
																											`form_data.other_documents[${index}].role`,
																									)
																							}
																							helperText={
																									!!get(
																											errors,
																											`form_data.other_documents[${index}].role`,
																									) &&
																									get(
																											errors,
																											`form_data.other_documents[${index}].role`,
																									).message
																							}
																					/>
																			)}
																	/>
															</Grid>

															<Grid item xs={12}>
																	<DropZone index={index} />
															</Grid>
													</Grid>
											</Box>
									))}

									<Button
											fullWidth
											id="add_other_people"
											color="primary"
											variant="outlined"
											sx={{ height: "52px", my: 2 }}
											onClick={() => append(emptyDocument)}
									>
											<AddCircleOutlineIcon />
											Agregar acompañante
									</Button>
							</>
					)}
			</Grid>
	);
};

export default OtherPeople;
