import { createSlice } from "@reduxjs/toolkit";
import { getHistory } from ".";
import type { IHistoryState } from "../../../models/IHistoryState";

const initialState: IHistoryState = {
    totalHistory: 1,
    history: [],
    loadingHistory: false,
    errorHistory: false,
};

const history = createSlice({
    name: "history",
    initialState,
    reducers: {
        resetHistory: (state) => {
            state.history = [];
            state.totalHistory = 1;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getHistory.pending, (state) => {
            state.loadingHistory = true;
            state.errorHistory = false;
        });
        builder.addCase(getHistory.fulfilled, (state, { payload }) => {
            state.loadingHistory = false;
            state.history = [...state.history, ...payload.items];
            state.totalHistory = payload.total;
        });
        builder.addCase(getHistory.rejected, (state) => {
            state.loadingHistory = false;
            state.errorHistory = true;
            state.message = "No se pudo cargar el historial.";
        });
    },
});

export const { resetHistory } = history.actions;

export default history.reducer;
