export const enabledCertificates = (code: string) => {
	const activities = [
		"TECH_RECEIPT",
		"CCE",
		"CCEG",
		"CS",
		"CSG",
		"DSM",
		"NSCN-RPE",
		"NCMP",
		"NS",
		"NSG",
		"NSDG",
		"NSDI",
		"RDCE",
		"RP",
		"ADCSM",
		"AIV",
	];

	return activities.includes(code);
};
