import type { IJsonForm } from "../../models/IDetailsCertificates";
import { phase } from "./phase";

export const currentTransformerInstalled: IJsonForm[] = [
    {
        label: "El transformador es:",
        type: "select",
        name: "is_installed",
        items: [
            {
                text: "Encontrado",
                value: "false",
            },
            {
                text: "Instalado",
                value: "true",
            },
        ],
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        label: "Factor de liquidación externo",
        name: "current_external_liquidation_factor",
        type: "number",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        label: "Ubicación",
        name: "location.value",
        type: "select",
        sx: {
            xs: 12,
        },
    },
    {
        label: "Ubicación",
        name: "location.picture_url",
        type: "image",
    },
    // {
    //   label: "Tipo de instalación",
    //   name: "installation_type",
    //   type: "select",
    //   items: ["Barra pasante", "Núcleo abierto", "Ventana"],
    // },
    // {
    //   label: "Ventana",
    //   name: "window",
    //   type: "select",
    //   items: ["Ventana con bornera de conexiones", "Ventana con cable embebido"],
    // },
    // {
    //   label: "Diámetro de la ventana",
    //   name: "diameter",
    //   type: "select",
    //   items: [
    //     "Diámetro de la ventana fase R",
    //     "Diámetro de la ventana fase S",
    //     "Diámetro de la ventana fase T",
    //   ],
    // },
    {
        label: "Fase R",
        name: "r_phase",
        type: "phase",
        json: phase,
    },
    {
        label: "Fase S",
        name: "s_phase",
        type: "phase",
        json: phase,
    },
    {
        label: "Fase T",
        name: "t_phase",
        type: "phase",
        json: phase,
    },
];
