import type { IStepperList } from "../models/IDetailsCertificates";
import {
    activities,
    activitiesToExecute,
    cabinet,
    cabinetMeters,
    comercialData,
    currentTransformerInstalled,
    currentTransformerRetired,
    diagrams,
    electricalBoxes,
    electricalBoxesInstalled,
    electricalBoxesRetired,
    electricalConductorsInstalled,
    electricalConductorsRetired,
    generalData,
    irregularitiesData,
    mainMeter,
    mainMeterComunicationsInstalled,
    mainMeterComunicationsRetired,
    mainMeterInstalled,
    mainMeterRetired,
    materials,
    materialsToExecute,
    measurementSystemData,
    metersTechnicalTest,
    observations,
    optionalMeterComunicationsInstalled,
    optionalMeterComunicationsRetired,
    optionalMeterInstalled,
    optionalMeterRetired,
    photographicAnnexes,
    requestTechnicalSupport,
    signatures,
    technicalValidationTesting,
    terminalsInstalled,
    terminalsRetired,
    transformersTechnicalTest,
    voltageTransformerInstalled,
    voltageTransformerRetired,
} from "./json";

export const stepperList: IStepperList[] = [
    {
        label: "Información comercial",
        data: comercialData,
        sectionName: "comercial_data",
    },
    {
        label: "Información del sistema de medida",
        data: measurementSystemData,
        sectionName: "measurement_system_data",
    },
    {
        label: "Validación técnica y pruebas",
        data: technicalValidationTesting,
        sectionName: "technical_validation_testing",
    },
    {
        label: "Gabinete",
        data: cabinet,
        sectionName: "cabinet",
    },
    {
        label: "Derecho a solicitar la asesoría o participación de un técnico particular",
        data: requestTechnicalSupport,
        sectionName: "request_technical_support",
        code: ["RSE", "SSE"],
    },
    {
        label: "Información general",
        data: generalData,
        sectionName: "general_data",
        code: ["SSE"],
    },
    {
        label: "Medidor de energía activa/reactiva principal encontrado/instalado",
        data: mainMeterInstalled,
        sectionName: "main_meter_installed",
        code: ["SSE"],
    },
    {
        label: "Medidor de energía activa/reactiva principal encontrado/instalado",
        data: mainMeter,
        sectionName: "main_meter",
    },
    {
        label: "Medidor de energía activa/reactiva principal retirado",
        data: mainMeterRetired,
        sectionName: "main_meter_retired",
    },
    {
        label: "Medidor de energía activa/reactiva respaldo/otro medidor encontrado/instalado",
        data: optionalMeterInstalled,
        sectionName: "optional_meter_installed",
        code: ["SSE"],
    },
    {
        label: "Medidor de energía activa/reactiva respaldo/otro medidor retirado",
        data: optionalMeterRetired,
        sectionName: "optional_meter_retired",
    },
    {
        label: "Transformadores de corriente(TC) encontrados/instalados",
        data: currentTransformerInstalled,
        sectionName: "current_transformer_installed",
    },
    {
        label: "Transformadores de corriente(TC) encontrados/instalados",
        data: currentTransformerInstalled,
        sectionName: "current_transformer",
    },
    {
        label: "Transformadores de corriente(TC) retirados",
        data: currentTransformerRetired,
        sectionName: "current_transformer_retired",
    },
    {
        label: "Transformadores de tensión(TT) instalados/encontrados",
        data: voltageTransformerInstalled,
        sectionName: "voltage_transformer_installed",
    },
    {
        label: "Transformadores de tensión(TT) retirados",
        data: voltageTransformerRetired,
        sectionName: "voltage_transformer_retired",
    },
    {
        label: "Conductores encontrados/instalado",
        data: electricalConductorsInstalled,
        sectionName: "electrical_conductors_installed",
    },
    {
        label: "Conductores retirados",
        data: electricalConductorsRetired,
        sectionName: "electrical_conductors_retired",
    },
    {
        label: "Paneles o cajas de seguridad encontradas/instaladas",
        data: electricalBoxesInstalled,
        sectionName: "electrical_boxes_installed",
    },
    {
        label: "Paneles o cajas de seguridad encontradas/instaladas",
        data: electricalBoxes,
        sectionName: "electrical_boxes",
        code: ["SSE"],
    },
    {
        label: "Panel o caja de seguridad retiradas",
        data: electricalBoxesRetired,
        sectionName: "electrical_boxes_retired",
    },
    {
        label: "Comunicaciones medidor principal encontrada/instalada",
        data: mainMeterComunicationsInstalled,
        sectionName: "main_meter_comunications_installed",
        code: ["SSE"],
    },
    {
        label: "Comunicaciones medidor principal encontrada/instalada",
        data: mainMeterComunicationsInstalled,
        sectionName: "main_meter_comunications",
        code: ["SSE"],
    },
    {
        label: "Comunicaciones medidor principal retirada",
        data: mainMeterComunicationsRetired,
        sectionName: "main_meter_comunications_retired",
    },
    {
        label: "Bornera de prueba o elemento similar principal/otra encontrada/instalada",
        data: terminalsInstalled,
        sectionName: "terminals_installed",
    },
    {
        label: "Bornera de prueba o elemento similar principal/otra retirada",
        data: terminalsRetired,
        sectionName: "terminals_retired",
    },
    {
        label: "Comunicaciones medidor de respaldo/otro medidor encontrada/instalada",
        data: optionalMeterComunicationsInstalled,
        sectionName: "optional_meter_comunications_installed",
    },
    {
        label: "Comunicaciones medidor de respaldo/otro medidor retirada",
        data: optionalMeterComunicationsRetired,
        sectionName: "optional_meter_comunications_retired",
    },
    {
        label: "Pruebas técnicas transformadores de medida",
        data: transformersTechnicalTest,
        sectionName: "transformers_technical_test",
    },
    {
        label: "Pruebas técnicas medidores",
        data: metersTechnicalTest,
        sectionName: "meters_technical_test",
        code: ["RSE", "SSE"],
    },
    {
        label: "Actividades",
        data: activities,
        sectionName: "activities",
        code: ["SSE"],
    },
    {
        label: "Actividades",
        data: activitiesToExecute,
        sectionName: "activities_to_execute",
    },
    {
        label: "Materiales",
        data: materials,
        sectionName: "materials",
        code: ["SSE"],
    },
    {
        label: "Materiales retirados",
        data: materials,
        sectionName: "retired_materials",
    },
    {
        label: "Materiales",
        data: materialsToExecute,
        sectionName: "materials_to_execute",
    },
    {
        label: "Observaciones",
        data: observations,
        sectionName: "observations",
        code: ["SSE"],
    },
    {
        label: "Anexos fotográficos",
        data: photographicAnnexes,
        sectionName: "photographic_annexes",
        code: ["SSE"],
    },
    {
        label: "Diagramas",
        data: diagrams,
        sectionName: "diagrams",
        code: ["RSE", "SSE"],
    },
    {
        label: "Irregularidades",
        data: irregularitiesData,
        sectionName: "irregularities",
    },
    {
        label: "Firmas en constancia de aceptación",
        data: signatures,
        sectionName: "signatures",
        code: ["SSE"],
    },
    {
        label: "Validación de conexión de cargas",
        data: cabinetMeters,
        sectionName: "cabinet.meters",
    },
];
