import type { FC } from "react";
import { useFormContext } from "react-hook-form";
import type { IJsonForm } from "../../../models/IDetailsCertificates";
import { ComponentMap } from "./ComponentMap";

type Props = {
    field: IJsonForm;
};

export const FormItem: FC<Props> = ({ field }) => {
    const { watch } = useFormContext();
    const { type, name } = field;

    if (
        !type ||
        watch(name ?? "") === null ||
        watch(name ?? "") === undefined ||
        (typeof watch(name ?? "") === "object" && field.type === "text") ||
        (typeof watch(name ?? "") === "string" && field.type === "checkbox")
    ) {
        return <></>;
    }
    const Component = ComponentMap[type] as React.FC<IJsonForm>;

    return <Component {...field} />;
};
