import {
    AddCircleOutlineIcon,
    Box,
    Button,
    Divider,
    Grid,
    TextField,
    Typography,
    enerbitColors,
} from "@enerbit/base";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import type { ValidationError } from "yup";
import { schemaNotifications } from "../../../helpers";
import type { INotifyTo } from "../../../models/INotifications";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
    addNotificationEmail,
    loadEmails,
} from "../../../store/slices/initial-form/initialForm";
import { EmptyData } from "../empty-data";
import EmailItem from "./EmailItem";

const Notifications = () => {
    const [newEmail, setNewEmail] = useState<INotifyTo>({
        full_name: "",
        category: "",
        email: "",
    });
    const [errors, setErrors] = useState<INotifyTo | null>(null);
    const { addedNotifyToEmails, action } = useAppSelector(
        (state) => state.initialForm,
    );
    const dispatch = useAppDispatch();

    const { setValue, watch } = useFormContext();

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setNewEmail((prev) => {
            const nD = { ...prev };
            nD[e.target.name as keyof INotifyTo] = e.target.value;
            return nD;
        });
    };

    const addEmail = () => {
        schemaNotifications
            .validate(newEmail, { abortEarly: false })
            .then((data: INotifyTo) => {
                dispatch(addNotificationEmail(data));
                setErrors(null);
            })
            .catch((error: ValidationError) => {
                setErrors(
                    error.inner.reduce((acc, curr) => {
                        acc[curr.path as keyof INotifyTo] = curr.message;
                        return acc;
                    }, {} as INotifyTo),
                );
            });
    };

    useEffect(() => {
        if (action?.type === "Create") {
            setValue("form_data.notification_data", addedNotifyToEmails);
        }
    }, [addedNotifyToEmails]);

    useEffect(() => {
        if (action?.type !== "Create") {
            dispatch(loadEmails(watch("form_data.notification_data")));
        }
    }, [watch("form_data.notification_data")]);

    return (
        <Grid item xs={12}>
            <Typography
                color="primary"
                sx={{ fontSize: "28px", fontWeight: 400 }}
            >
                Notificar a
            </Typography>
            <Typography sx={{ color: enerbitColors.neutral.main, mb: 2 }}>
                Se enviará una copia de la orden de servicio vía correo
                electrónico
            </Typography>
            {action?.type === "Create" ? (
                <Grid container rowSpacing={3} columnSpacing={1}>
                    <Grid item xs={2}>
                        <TextField
                            fullWidth
                            id="notification_category"
                            name="category"
                            placeholder="Rol"
                            sx={{ "& fieldset": { borderRadius: "14px" } }}
                            variant="outlined"
                            error={!!errors?.category}
                            helperText={!!errors?.category && errors.category}
                            onChange={(e) => handleChange(e)}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            id="notification_full_name"
                            name="full_name"
                            placeholder="Escribir nombre completo"
                            sx={{ "& fieldset": { borderRadius: "14px" } }}
                            variant="outlined"
                            error={!!errors?.full_name}
                            helperText={!!errors?.full_name && errors.full_name}
                            onChange={(e) => handleChange(e)}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            name="email"
                            id="notification_email"
                            placeholder="Escribir correo electrónico"
                            sx={{ "& fieldset": { borderRadius: "14px" } }}
                            variant="outlined"
                            error={!!errors?.email}
                            helperText={!!errors?.email && errors.email}
                            onChange={(e) => handleChange(e)}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Button
                            fullWidth
                            id="add_notification_email_btn"
                            color="primary"
                            variant="outlined"
                            sx={{ height: "52px" }}
                            onClick={addEmail}
                        >
                            <AddCircleOutlineIcon />
                            Agregar correo
                        </Button>
                    </Grid>
                </Grid>
            ) : null}
            {addedNotifyToEmails.length > 0 ? (
                <>
                    <Divider sx={{ my: 2 }} />
                    <Grid container rowSpacing={3} columnSpacing={1}>
                        {addedNotifyToEmails.map((item) => (
                            <EmailItem
                                key={item.email}
                                email={item.email}
                                name={item.full_name}
                            />
                        ))}
                    </Grid>
                </>
            ) : (
                <Box mt={2}>
                    <EmptyData
                        message={
                            action?.type === "Create"
                                ? "Aún no has agregado un correo para notificar sobre esta orden."
                                : "No se agregó ningún correo para notificar sobre esta orden."
                        }
                    />
                </Box>
            )}
        </Grid>
    );
};

export default Notifications;
