import { CircularProgress, Grid } from "@enerbit/base";

export const LoadingOrder = () => {
    return (
        <Grid container>
            <Grid item xs={12} display="flex" justifyContent={"center"}>
                <CircularProgress />
            </Grid>
        </Grid>
    );
};
