import type { IJsonForm } from "../../models/IDetailsCertificates";

export const cabinetMeters: IJsonForm[] = [
    {
        label: "Carga",
        isSection: true,
        type: "array",
        json: [
            {
                name: "main_meter_installed.name",
                label: "Número del apartamento, local o casa",
                type: "text",
                sx: {
                    xs: 12,
                },
            },
            {
                label: "Observaciones",
                name: "main_meter_installed.signature_customer_witness.observations",
                type: "text-area",
                sx: {
                    xs: 12,
                },
            },
            {
                label: "Nombre completo",
                name: "main_meter_installed.signature_customer_witness.signature.fullname",
                type: "text",
                sx: {
                    xs: 12,
                    md: 6,
                },
            },
            {
                label: "Tipo de documento de identidad",
                name: "main_meter_installed.signature_customer_witness.signature.legal_id_type",
                type: "select",
                sx: {
                    xs: 12,
                    md: 6,
                },
            },
            {
                label: "Correo electrónico",
                name: "main_meter_installed.signature_customer_witness.signature.email",
                type: "email",
                sx: {
                    xs: 12,
                    md: 6,
                },
            },
            {
                label: null,
                name: "main_meter_installed.signature_customer_witness.signature.signature",
                type: "signature",
            },
        ],
    },
];
