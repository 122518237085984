import {
    AnimatePresence,
    CloseIcon,
    ContentPasteSearchOutlinedIcon,
    EditOutlinedIcon,
    Grid,
    RemoveRedEyeOutlinedIcon,
    Typography,
} from "@enerbit/base";
import { type FC, useEffect, useState } from "react";
import { PaginationOrders } from ".";
import { StyledChip } from "../../../common";
import { EEntity } from "../../../helpers/common";
import { getOrders } from "../../../store";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { LoadingOrderItem } from "./LoadingOrderItem";
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { OrderFilter } from "./OrderFilter";
import { OrderItem } from "./OrderItem";

interface Props {
    entity: EEntity;
}

export const OrderContainer: FC<Props> = ({ entity }) => {
    const { addedEntities, entity: globalEntity } = useAppSelector(
        (state) => state.initialForm,
    );
    const { listOrders, loadingOrder, dateRange, selectedOrderStatus, page } =
        useAppSelector((state) => state.listOrders.orders[entity]);
    const dispatch = useAppDispatch();

    const [size] = useState(9);

    useEffect(() => {
        if (addedEntities.length > 0) {
            if (EEntity.Client === entity && !addedEntities[0].id) {
                return;
            }

            dispatch(
                getOrders({
                    id:
                        EEntity.Client === entity &&
                        addedEntities[0].secondary_id
                            ? addedEntities[0].secondary_id!
                            : addedEntities[0].id,
                    entity,
                    page: page - 1,
                    size,
                    dateRange,
                    status: selectedOrderStatus[0],
                    searchLead:
                        !addedEntities[0].secondary_id &&
                        globalEntity === EEntity.Client &&
                        entity === EEntity.Lead,
                }),
            );
        }
    }, [page, dateRange, selectedOrderStatus[0], addedEntities[0]]);

    return (
        <Grid container item spacing={3}>
            <OrderFilter entity={entity} />
            <Grid item container xs={12} spacing={3}>
                <Grid item display={"flex"} alignItems={"center"}>
                    <Typography component={"span"}>Estado:</Typography>
                </Grid>
                <Grid item>
                    <StyledChip
                        icon={<EditOutlinedIcon fontSize="small" />}
                        label="Editar orden de servicio"
                        sx={{ background: "#FFEDDC", color: "#F79009" }}
                    />
                </Grid>
                <Grid item>
                    <StyledChip
                        icon={<CloseIcon fontSize="small" />}
                        label="Cancelar orden de servicio"
                        sx={{ background: "#FEECEB", color: "#F04438" }}
                    />
                </Grid>
                <Grid item>
                    <StyledChip
                        icon={<RemoveRedEyeOutlinedIcon fontSize="small" />}
                        label="Detalle de la orden"
                        sx={{ background: "#EFE9FB", color: "#53358E" }}
                    />
                </Grid>
                <Grid item>
                    <StyledChip
                        icon={
                            <ContentPasteSearchOutlinedIcon fontSize="small" />
                        }
                        label="Ver acta"
                        sx={{ background: "#EDF4FF", color: "#3B82F6" }}
                    />
                </Grid>
            </Grid>
            <AnimatePresence>
                <Grid
                    container
                    item
                    spacing={3}
                    style={{ display: "flex", alignItems: "stretch" }}
                >
                    {listOrders.map((item, index) => {
                        return (
                            <OrderItem
                                key={index.toString()}
                                order={item}
                                entity={entity}
                                index={index}
                            />
                        );
                    })}
                    {loadingOrder && <LoadingOrderItem />}
                </Grid>
            </AnimatePresence>
            <PaginationOrders entity={entity} size={size} />
        </Grid>
    );
};
