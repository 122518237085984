import type { IJsonForm } from "../../models/IDetailsCertificates";

export const comercialData: IJsonForm[] = [
    {
        label: "Número de factibilidad",
        name: "feasibility_number",
        type: "number",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "project_name",
        type: "text",
        label: "Nombre del proyecto",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "municipality",
        type: "text",
        label: "Municipio",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "network_operator",
        type: "text",
        label: "Operador de red",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "or_reference_number",
        type: "text",
        label: "Número de radicado del OR",
        sx: {
            xs: 12,
            md: 6,
        },
    },
];
