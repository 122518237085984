import {
	Box,
	Checkbox,
	type GridColDef,
	type GridRenderCellParams,
	type GridRowParams,
	Radio,
	Typography,
	esES,
} from "@enerbit/base";
import { type FC, useCallback, useEffect, useState } from "react";
import { CustomPagination } from "../../../common/CustomPagination";
import { StyledDataGrid } from "../../../common/StyledDataGrid";
import type {
	ICustomerItem,
	PiiCustomer,
} from "../../../models/IResponseCustomers";
import { getCustomers } from "../../../store";
import {
	handleCustomer,
	handleCustomers,
	setPage,
	setPageSize,
} from "../../../store/slices/table-address/tableAddress";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAppDispatch, useAppSelector } from "../../../store/store";

interface Props {
	multiple?: boolean;
}

export const AddressTableClient: FC<Props> = ({ multiple }) => {
	const { filter } = useAppSelector((state) => state.initialForm);
	const {
		listCustomers,
		loading,
		size,
		rowCount,
		selectedCustomers,
		page,
		name,
	} = useAppSelector((state) => state.tableAddress);
	const dispatch = useAppDispatch();

	const styledName = { display: "flex", alignItems: "center" };

	const columns: GridColDef[] = [
		{
			field: "pii",
			headerName: "Nombre",
			flex: 1,
			align: "left",
			headerAlign: "center",
			renderCell: ({
				value,
				row,
			}: GridRenderCellParams<PiiCustomer, ICustomerItem>) => {
				const index = listCustomers.findIndex(
					({ measurement_point_id }) =>
						row.measurement_point_id == measurement_point_id,
				);
				const { location } = row;
				return (
					<Box>
						{multiple ? (
							<Box sx={styledName}>
								<Checkbox
									id={`address-${page}-${index}`}
									checked={
										!!selectedCustomers.find(
											({ measurement_point_id }) =>
												measurement_point_id === row.measurement_point_id,
										)
									}
									size="small"
									color="secondary"
									sx={{ mb: 0.5 }}
								/>
								<Typography
									sx={{
										fontWeight: "bold",
										fontSize: "17px",
									}}
								>
									{`${value?.names} ${value?.last_names}`}
								</Typography>
							</Box>
						) : (
							<Box sx={styledName}>
								<Radio
									id={`address-${page}-${index}`}
									size="small"
									sx={{ mb: 0.5 }}
									checked={
										!!selectedCustomers.find(
											({ measurement_point_id }) =>
												measurement_point_id === row.measurement_point_id,
										)
									}
									color="secondary"
								/>
								<Typography
									sx={{
										fontWeight: "bold",
										fontSize: "17px",
									}}
								>
									{`${value?.names} ${value?.last_names}`}
								</Typography>
							</Box>
						)}
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								ml: 1.5,
								mb: 1,
							}}
						>
							<Typography color="neutral.main">
								{location?.city || "No registra"}
							</Typography>
							<Typography color="neutral.main">
								{location?.address || "No registra"}
							</Typography>
						</Box>
					</Box>
				);
			},
			sortable: false,
		},
	];

	const getRowSpacing = useCallback((params: any) => {
		return {
			top: params.isFirstVisible ? 0 : 5,
			bottom: params.isLastVisible ? 0 : 5,
		};
	}, []);

	const getRowClassName = (params: GridRowParams): string => {
		return selectedCustomers?.some(
			(customer) =>
				customer.measurement_point_id === params.row.measurement_point_id,
		)
			? "selected-row"
			: "";
	};

	const [isFirstRender, setIsFirstRender] = useState(true);

	useEffect(() => {
		if (!isFirstRender) {
			if (filter) {
				dispatch(getCustomers({ email: filter, page, size, name }));
			}
		} else {
			dispatch(setPage(0));
			setIsFirstRender(false);
		}
	}, [page, size, isFirstRender]);

	return (
		<Box sx={{ width: "100%" }}>
			<StyledDataGrid
				disableVirtualization
				paginationMode="server"
				disableColumnMenu
				rowSpacingType="border"
				getRowSpacing={getRowSpacing}
				getRowClassName={getRowClassName}
				rows={listCustomers}
				selectionModel={selectedCustomers.map(
					({ measurement_point_id }) => measurement_point_id,
				)}
				getRowId={(row) => `${row.measurement_point_id}`}
				onSelectionModelChange={(e) => {
					if (e.length > 0) {
						const selectedIDs = new Set(e);
						const selectedRowData = listCustomers.filter((row) =>
							selectedIDs.has(row.measurement_point_id),
						)[0];
						if (multiple) {
							dispatch(handleCustomers(selectedRowData));
						} else {
							dispatch(handleCustomer(selectedRowData));
						}
					}
				}}
				columns={columns}
				pageSize={size}
				rowHeight={90}
				headerHeight={0}
				rowsPerPageOptions={[5, 10, 20, 50]}
				rowCount={rowCount}
				onPageChange={(page) => dispatch(setPage(page))}
				onPageSizeChange={(pageSize) => dispatch(setPageSize(pageSize))}
				loading={loading}
				autoHeight
				localeText={esES.components.MuiDataGrid.defaultProps.localeText}
				pagination
				components={{
					Pagination: CustomPagination,
				}}
			/>
		</Box>
	);
};
