import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import { getDetailsOrder } from ".";
import { formList } from "../../../const/form.config";
import type { IDetailProgressState } from "../../../models/IDetailProgressState";

const initialState: IDetailProgressState = {
    activeStep: -1,
    message: "",
    loadingDetails: false,
    errorDetails: false,
    showDetails: false,
    isNotSection: false,
};

const detailProgress = createSlice({
    name: "detailProgress",
    initialState,
    reducers: {
        handleDetails: (state) => {
            state.showDetails = !state.showDetails;
        },
        handleStepper: (state, action: PayloadAction<number>) => {
            state.activeStep =
                action.payload === state.activeStep ? -1 : action.payload;
        },
        resetDetailProgress: () => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getDetailsOrder.pending, (state) => {
            state.loadingDetails = true;
            state.errorDetails = false;
        });
        builder.addCase(getDetailsOrder.fulfilled, (state, { payload }) => {
            const { detailsOrder, isNotSection } = payload;
            state.isNotSection = isNotSection;
            state.loadingDetails = false;
            state.detailsOrder = detailsOrder;
            if (isNotSection) {
                state.filteredKeysJSON = formList;
            }
        });
        builder.addCase(getDetailsOrder.rejected, (state, { payload }) => {
            state.loadingDetails = false;
            state.errorDetails = true;
            state.detailsOrder = undefined;

            if (payload === 404) {
                state.message =
                    "Este lead no existe o no cuenta con predios asociados.";
            } else {
                state.message =
                    "Ha ocurrido un error inesperado, por favor inténtelo de nuevo.";
            }
        });
    },
});

export const { handleStepper, handleDetails, resetDetailProgress } =
    detailProgress.actions;

export default detailProgress.reducer;
