import type { IJsonForm } from "../../models/IDetailsCertificates";

export const activitiesToExecute: IJsonForm[] = [
    {
        label: "Actividad",
        isSection: true,
        type: "array",
        json: [
            {
                label: "Actividad a ejecutar",
                name: "name",
                type: "select",
                sx: {
                    xs: 12,
                    md: 6,
                },
            },
        ],
    },
];
