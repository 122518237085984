import { AddCircleOutlineIcon, Button } from "@enerbit/base";

import { useFieldArray, useFormContext } from "react-hook-form";

import { useEffect } from "react";
import DropItem from "./DropItem";

interface Props {
    index: number;
}

const DropZone = ({ index }: Props) => {
    const { control, watch } = useFormContext();
    const { fields, append, remove } = useFieldArray({
        control,
        name: `form_data.other_documents.${index}.path_file`,
    });

    useEffect(() => {
        if (watch(`form_data.other_documents.${index}.path_file`).length > 0) {
            return;
        }
        append("");
    }, []);
    return (
        <>
            {fields.map((field, i) => (
                <DropItem
                    key={field.id}
                    name={`form_data.other_documents.${index}`}
                    index={i}
                    remove={remove}
                />
            ))}

            <Button
                fullWidth
                id="add_other_document"
                color="secondary"
                variant="outlined"
                sx={{ height: "52px", my: 2 }}
                onClick={() => append("")}
            >
                <AddCircleOutlineIcon />
                Agregar Documento
            </Button>
        </>
    );
};

export default DropZone;
