import type { IJsonForm } from "../models/IDetailsCertificates";

export const formList: IJsonForm[] = [
    {
        label: "Mapa",
        type: "geolocation",
        name: "geolocalization",
    },
    {
        label: "Foto",
        type: "image",
        name: "picture_url",
    },
    {
        name: "main_meter.meter_serial",
        label: "Serie del medidor",
        type: "text",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "main_meter.active_energy_imported.value",
        type: "number",
        label: "Lectura importación activa",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "main_meter.active_energy_imported.picture_url",
        type: "image",
        label: "Lectura importación activa",
    },
    {
        name: "main_meter.active_energy_exported.value",
        type: "number",
        label: "Lectura exportación activa",
        sx: {
            xs: 12,
        },
    },
    {
        name: "main_meter.active_energy_exported.picture_url",
        type: "image",
        label: "Lectura importación activa",
    },
    {
        name: "main_meter.reactive_energy_imported.value",
        type: "number",
        label: "Lectura importación reactiva",
        sx: {
            xs: 12,
        },
    },
    {
        name: "main_meter.reactive_energy_imported.picture_url",
        type: "image",
        label: "Lectura importación activa",
    },
    {
        name: "main_meter.reactive_energy_exported.value",
        type: "number",
        label: "Lectura exportación reactiva",
        sx: {
            xs: 12,
        },
    },
    {
        name: "main_meter.reactive_energy_exported.picture_url",
        type: "image",
        label: "Lectura importación activa",
    },
    {
        label: "Observaciones",
        name: "observations",
        type: "text-area",
        sx: {
            xs: 12,
        },
    },
];
