import type { IJsonForm } from "../../models/IDetailsCertificates";

export const mainMeterComunicationsInstalled: IJsonForm[] = [
    {
        label: "El modem es:",
        type: "select",
        name: "is_installed",
        items: [
            {
                text: "Encontrado",
                value: "false",
            },
            {
                text: "Instalado",
                value: "true",
            },
        ],
        sx: {
            xs: 12,
            md: 6,
        },
    },

    {
        label: "Serie del módem",
        type: "text",
        name: "serial_number.value",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        label: "Fotografía serie del módem",
        type: "image",
        name: "serial_number.picture_url",
    },
    {
        label: "Marca",
        name: "brand",
        type: "text",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        label: "IMEI",
        name: "imei",
        type: "text",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        label: "Operador",
        name: "operator",
        type: "select",
        sx: {
            xs: 12,
            md: 6,
        },
    },
    {
        name: "network",
        type: "text",
        label: "Medio de comunicación",
        sx: {
            xs: 12,
            md: 6,
        },
    },
];
