import type { IJsonForm } from "../../models/IDetailsCertificates";

export const technicalValidationTesting: IJsonForm[] = [
    {
        label: "¿Aprueba la verificación técnica de la acometida?",
        type: "title",
        name: "technical_verification_connection",
        sx: {
            xs: 12,
        },
    },
    {
        label: "Conforme",
        name: "technical_verification_connection.is_approved",
        type: "switch",
        sx: {
            xs: 12,
        },
    },
    {
        label: "Observaciones",
        name: "technical_verification_connection.observation",
        type: "text-area",
        sx: {
            xs: 12,
        },
    },
    {
        label: "¿Aprueba la verificación técnica de los equipos de conexión?",
        type: "title",
        name: "connection_equipment",
        sx: {
            xs: 12,
        },
    },
    {
        label: "Conforme",
        name: "connection_equipment.is_approved",
        type: "switch",
        sx: {
            xs: 12,
        },
    },
    {
        label: "Observaciones",
        name: "connection_equipment.observation",
        type: "text-area",
        sx: {
            xs: 12,
        },
    },
    {
        label: "¿Aprueba la verificación de la calidad de potencia?",
        type: "title",
        name: "power_quality",
        sx: {
            xs: 12,
        },
    },
    {
        label: "Conforme",
        name: "power_quality.is_approved",
        type: "switch",
        sx: {
            xs: 12,
        },
    },
    {
        label: "Observaciones",
        name: "power_quality.observation",
        type: "text-area",
        sx: {
            xs: 12,
        },
    },
    {
        label: "¿Aprueba las pruebas y maniobras requeridas?",
        type: "title",
        name: "required_maneuvers",
        sx: {
            xs: 12,
        },
    },
    {
        label: "Conforme",
        name: "required_maneuvers.is_approved",
        type: "switch",
        sx: {
            xs: 12,
        },
    },
    {
        label: "Observaciones",
        name: "required_maneuvers.observation",
        type: "text-area",
        sx: {
            xs: 12,
        },
    },
    {
        label: "¿Cumple homologación y/o protocolos de pruebas?",
        type: "title",
        name: "homologation_test_protocols",
        sx: {
            xs: 12,
        },
    },
    {
        label: "Conforme",
        name: "homologation_test_protocols.is_approved",
        type: "switch",
        sx: {
            xs: 12,
        },
    },
    {
        label: "Observaciones",
        name: "homologation_test_protocols.observation",
        type: "text-area",
        sx: {
            xs: 12,
        },
    },
    {
        label: "¿La conexión fue aprobada?",
        type: "title",
        name: "connection",
        sx: {
            xs: 12,
        },
    },
    {
        label: "Conforme",
        name: "connection.is_approved",
        type: "switch",
        sx: {
            xs: 12,
        },
    },
    {
        label: "Observaciones",
        name: "connection.observation",
        type: "text-area",
        sx: {
            xs: 12,
        },
    },
];
