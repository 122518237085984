import { AddCircleOutlineIcon, Button, Grid, Typography } from "@enerbit/base";
import { useFormContext } from "react-hook-form";
import { CabinetItem, LeadOrClientInfo } from ".";
import { EEntity, validateMultiple } from "../../../helpers";
import type { ISelectedEntity } from "../../../models";
import { useAppDispatch, useAppSelector } from "../../../store";
import { openAddModal } from "../../../store/slices/table-address/tableAddress";
import { ContactWitness } from "../contact-witness";
import { EmptyData } from "../empty-data";
import { AddAddressModal } from "../modals";
import { LocationSmartBit, SmartbitFlow } from "../smarbit";

export const EntityContainer = () => {
    const dispatch = useAppDispatch();
    const {
        formState: { errors },
    } = useFormContext();
    const { addedEntities, action, selectedOrder, activity } = useAppSelector(
        (state) => state.initialForm,
    );
    const { openAdd } = useAppSelector((state) => state.tableAddress);

    return (
        <>
            {EEntity.Lead === action?.entity &&
            ((activity?.name && validateMultiple(activity?.name)) ||
                (action?.activity &&
                    validateMultiple(action?.activity.name))) ? (
                <Grid item container xs={12} spacing={3}>
                    {addedEntities.length > 0 ? (
                        <Grid
                            item
                            container
                            xs={12}
                            spacing={3}
                            sx={{ display: "flex", alignItems: "stretch" }}
                        >
                            {action.type === "Create" ? (
                                <>
                                    {addedEntities.map((lead, index) => {
                                        return (
                                            <CabinetItem
                                                lead={lead}
                                                isMain={index === 0}
                                                key={index.toString()}
                                            />
                                        );
                                    })}
                                </>
                            ) : (
                                <>
                                    {selectedOrder?.order_entities.map(
                                        (lead, index) => {
                                            const currentLead: ISelectedEntity =
                                                {
                                                    id: lead.assignee_id ?? "",
                                                    secondary_id: lead.id,
                                                    full_name:
                                                        lead.pii.full_name,
                                                    address: lead.pii.address,
                                                    city: lead.pii.city,
                                                    department: lead.pii.estate,
                                                    email: "",
                                                };

                                            return (
                                                <CabinetItem
                                                    lead={currentLead}
                                                    isMain={
                                                        currentLead.id ===
                                                        addedEntities[0].id
                                                    }
                                                    status={lead.status}
                                                    key={index.toString()}
                                                />
                                            );
                                        },
                                    )}
                                </>
                            )}
                        </Grid>
                    ) : (
                        <Grid item xs={12}>
                            <EmptyData message="Aún no has agregado leads asociados, por favor agrega al menos uno." />
                            {errors.order_entities && (
                                <Typography color="error" variant="body2">
                                    {errors.order_entities.message}
                                </Typography>
                            )}
                        </Grid>
                    )}
                    {action?.type !== "Consult" &&
                        action?.type !== "Cancel" && (
                            <Grid item xs={12}>
                                <Button
                                    id="add-button"
                                    fullWidth
                                    variant="contained"
                                    color="secondary"
                                    endIcon={<AddCircleOutlineIcon />}
                                    onClick={() => dispatch(openAddModal())}
                                >
                                    Agregar
                                </Button>
                                {openAdd && <AddAddressModal />}
                            </Grid>
                        )}
                </Grid>
            ) : EEntity.Lead === action?.entity ||
              EEntity.Client === action?.entity ? (
                <>
                    {addedEntities.length > 0 ? (
                        <LeadOrClientInfo />
                    ) : (
                        <Grid item xs={12}>
                            <EmptyData message="Aún no has agregado un servicio, por favor agrega uno." />
                            {errors.order_entities && (
                                <Typography color="error" variant="body2">
                                    {errors.order_entities.message}
                                </Typography>
                            )}
                        </Grid>
                    )}
                </>
            ) : (
                <>
                    {EEntity.Smartbit === action?.entity &&
                    addedEntities.length > 0 ? (
                        <>
                            <SmartbitFlow />
                            {!addedEntities[0].city &&
                                !addedEntities[0].department && (
                                    <LocationSmartBit />
                                )}
                            <Grid item xs={12}>
                                <ContactWitness index={0} />
                            </Grid>
                        </>
                    ) : (
                        <Grid item xs={12}>
                            <EmptyData message="Aún no has agregado un smartBit, por favor agrega uno." />
                            {errors.order_entities && (
                                <Typography color="error" variant="body2">
                                    {errors.order_entities.message}
                                </Typography>
                            )}
                        </Grid>
                    )}
                </>
            )}
        </>
    );
};
