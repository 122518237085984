import type { IJsonForm } from "../../models/IDetailsCertificates";
import { phase } from "./phase";

export const currentTransformerRetired: IJsonForm[] = [
    {
        label: "Fase R",
        name: "r_phase",
        type: "phase",
        json: phase,
    },
    {
        label: "Fase S",
        name: "s_phase",
        type: "phase",
        json: phase,
    },
    {
        label: "Fase T",
        name: "t_phase",
        type: "phase",
        json: phase,
    },
];
