/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Container, NavSidebar } from "@enerbit/base";
import { useAppDispatch, useAppSelector } from "../store/store";
import "./Orders.css";
import { enqueueSnackbar } from "notistack";
import { useEffect, useRef } from "react";
import Router from "../router/Router";
import { resetActivity } from "../store/slices/initial-form/initialForm";
import { resetPages } from "../store/slices/list-orders/listOrders";
import { getRole } from "../store/slices/roles/roles";
import { resetAddedOperators } from "../store/slices/team-operators/teamOperators";
import { CreateOrder } from "./components/create-order";

export const Orders = () => {
    const { addedEntities, action, errorOrder, message } = useAppSelector(
        (state) => state.initialForm,
    );
    const dispatch = useAppDispatch();

    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        containerRef.current?.scrollTo(0, 0);
    }, [action]);

    useEffect(() => {
        if (errorOrder) {
            enqueueSnackbar(message, { variant: "error" });
        }
    }, [errorOrder]);

    useEffect(() => {
        dispatch(resetAddedOperators());
    }, [action]);

    useEffect(() => {
        if (addedEntities.length > 0) {
            dispatch(resetActivity());
            dispatch(resetPages());
        }
    }, [addedEntities[0]]);

    useEffect(() => {
        dispatch(getRole());
    }, []);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                height: "100vh",
                width: "100vw",
                overflow: "hidden",
            }}
        >
            <NavSidebar />
            <Box
                ref={containerRef}
                sx={{
                    position: "relative",
                    flexGrow: 1,
                    paddingTop: "70px",
                    overflow: "auto",
                }}
            >
                {(!action || (action && action.type !== "Create")) && (
                    <Router />
                )}
                {action && action.type === "Create" && (
                    <Container sx={{ py: 4 }} maxWidth={"xl"}>
                        <CreateOrder />
                    </Container>
                )}
            </Box>
        </div>
    );
};
