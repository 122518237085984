import {
    Box,
    Button,
    ClearIcon,
    Grid,
    LinearProgress,
    RemoveRedEyeOutlinedIcon,
    Typography,
    enerbitColors,
    useDropzone,
} from "@enerbit/base";
import DeleteIcon from "@mui/icons-material/Delete";
import { enqueueSnackbar } from "notistack";
import React, { useCallback, useState } from "react";
import {
    Controller,
    type UseFieldArrayRemove,
    get,
    useFormContext,
} from "react-hook-form";
import { loadFile, uploadFile } from "../../../services";

interface Props {
    name: string;
    index: number;
    remove: UseFieldArrayRemove;
}

const DropItem = ({ name, index, remove }: Props) => {
    const {
        watch,
        setValue,
        formState: { errors },
    } = useFormContext();
    const [isLoading, setIsLoading] = useState(false);
    const [progress, setProgress] = useState<number>(0);
    const [currentFile, setCurrentFile] = useState<File>();

    const currentName = `${name}.path_file.${index}`;

    const handleFileChange = async (file: File) => {
        if (file) {
            try {
                setIsLoading(true);
                setProgress(0);
                const imageUrl = await uploadFile(
                    file,
                    (event: ProgressEvent) => {
                        const percentCompleted = Math.round(
                            (event.loaded * 100) / event.total,
                        );
                        setProgress(percentCompleted);
                    },
                );
                setValue(currentName, imageUrl);
                setCurrentFile(file);
            } catch (error) {
                setProgress(0);
                setValue(currentName, "");
                return error;
            } finally {
                setIsLoading(false);
            }
        }
    };

    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            handleFileChange(acceptedFiles[0]);
        },
        [handleFileChange],
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            "application/pdf": [],
        },
    });

    const isShowCard = !!watch(currentName) || isLoading;

    const handleOpenPreviewFile = async () => {
        await loadFile(watch(currentName!))
            .then((pdf) => {
                window.open(pdf, "_blank");
            })
            .catch(() =>
                enqueueSnackbar("No se pudo cargar el archivo PDF.", {
                    variant: "error",
                }),
            );
    };

    return (
        <Controller
            name={currentName}
            render={({ field }) => (
                <Box
                    {...field}
                    sx={{
                        position: "relative",
                        border: `1px solid ${enerbitColors.primary.main}`,
                        borderRadius: "12px",
                        p: 3,
                        my: 2,
                    }}
                >
                    {watch(`${name}.path_file`)?.length > 1 &&
                        !watch(currentName) && (
                            <ClearIcon
                                color="error"
                                sx={{
                                    position: "absolute",
                                    right: "10px",
                                    top: "10px",
                                    cursor: "pointer",
                                }}
                                onClick={() => remove(index)}
                            />
                        )}

                    <Grid container alignItems={"center"}>
                        <>
                            {isShowCard && (
                                <>
                                    <Grid item xs={1}>
                                        <Box className="pdf">PDF</Box>
                                    </Grid>

                                    <Grid item xs={8}>
                                        <Typography
                                            fontWeight={700}
                                            variant="h6"
                                        >
                                            Documento
                                        </Typography>

                                        <Typography>
                                            {currentFile?.name}
                                        </Typography>
                                        {isLoading && (
                                            <LinearProgress
                                                variant="determinate"
                                                value={progress}
                                            />
                                        )}
                                    </Grid>

                                    <Grid xs={3}>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            {!isLoading && (
                                                <Button
                                                    variant="contained"
                                                    startIcon={
                                                        <RemoveRedEyeOutlinedIcon />
                                                    }
                                                    className="see-button"
                                                    id={`${name}-see-document`}
                                                    onClick={
                                                        handleOpenPreviewFile
                                                    }
                                                >
                                                    Ver documento
                                                </Button>
                                            )}

                                            <DeleteIcon
                                                sx={{
                                                    cursor: "pointer",
                                                    position: "absolute",
                                                    right: "20px",
                                                    top: "35px",
                                                }}
                                                color="error"
                                                onClick={() =>
                                                    setValue(currentName, "")
                                                }
                                            />
                                        </Box>
                                    </Grid>
                                </>
                            )}
                        </>
                        {!isLoading && !watch(currentName) && (
                            <Grid container spacing={3} my={1}>
                                <Grid item xs={12}>
                                    <div
                                        {...(getRootProps() as React.HTMLProps<HTMLInputElement>)}
                                        className="dropzone"
                                        style={{
                                            border: `2px dashed ${
                                                !!get(errors, currentName)
                                                    ? enerbitColors.error.main
                                                    : "#cccccc"
                                            }`,
                                            borderRadius: "12px",
                                            padding: "20px",
                                            textAlign: "center",
                                            cursor: "pointer",
                                            backgroundColor: isDragActive
                                                ? "#eeeeee"
                                                : "",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            gap: "10px",
                                        }}
                                    >
                                        <input
                                            {...(getInputProps() as React.HTMLProps<HTMLInputElement>)}
                                            id={`upload-${name}`}
                                        />
                                        <Typography>
                                            {isDragActive
                                                ? "Suelta el archivo aquí"
                                                : "Arrastra aquí o"}
                                        </Typography>
                                        <Button variant="contained">
                                            Adjunte el archivo
                                        </Button>
                                    </div>
                                    {!!get(errors, currentName) && (
                                        <Typography
                                            color="error"
                                            fontSize={"0.75rem"}
                                        >
                                            {get(errors, currentName).message}
                                        </Typography>
                                    )}
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            )}
        />
    );
};

export default DropItem;
