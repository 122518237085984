import {
    AnimatePresence,
    Box,
    Grid,
    Stack,
    Typography,
    motion,
} from "@enerbit/base";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { StyledTab, StyledTabs, TabPanel } from "../../../common";
import { EEntity } from "../../../helpers";
import {
    getCurrentEntity,
    getInitialEntities,
    getOrder,
    getSmartBit,
    useAppSelector,
} from "../../../store";
import { resetHistory } from "../../../store/slices/history/history";
import {
    setAction,
    setEntity,
} from "../../../store/slices/initial-form/initialForm";
import { useAppDispatch } from "../../../store/store";
import { Details } from "../../Details";
import Backbutton from "../back-button/BackButton";
import { ConsultProvider } from "../form-provider";
import { HistoryOrder } from "../history";
import { LoadingOrder } from "../loading";

const a11yProps = (index: number) => {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
};

export const ConsultOrder = () => {
    const {
        selectedOrder,
        loadingOrder,
        loadingSmartbit,
        action,
        addedEntities,
        filter,
        loadingCurrentEntity,
        loadingInitialEntities,
    } = useAppSelector((state) => state.initialForm);

    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();

    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();

    const [value, setValue] = useState(0);

    const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const { showDetails } = useAppSelector((state) => state.detailProgress);

    useEffect(() => {
        dispatch(resetHistory());
    }, [value]);

    useEffect(() => {
        if (!id) return;
        dispatch(getOrder(id));
    }, []);

    useEffect(() => {
        if (!selectedOrder) return;
        const entity = selectedOrder?.order_entities[0].entity.code as EEntity;
        const assigneeId = selectedOrder?.order_entities[0].assignee_id;

        dispatch(setEntity(entity as EEntity));

        if (!action?.isFromSearch) {
            dispatch(
                getCurrentEntity({
                    entityCode: entity as EEntity,
                    assignee: assigneeId,
                }),
            );
        }

        if (entity !== EEntity.Smartbit) {
            dispatch(getInitialEntities(selectedOrder.order_entities));
        } else {
            dispatch(
                getSmartBit({
                    id: selectedOrder.order_entities[0].assignee_id,
                    isCreate: true,
                }),
            );
        }

        dispatch(
            setAction({
                type: "Consult",
                entity,
                isFromSearch: action?.isFromSearch,
                activity: {
                    code: selectedOrder.order_type.code,
                    name: selectedOrder.order_type.name,
                },
            }),
        );
    }, [selectedOrder]);

    return (
        <AnimatePresence mode="wait">
            <motion.div
                key={showDetails ? "withAction" : "withoutAction"}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
            >
                {!showDetails && (
                    <>
                        <Grid item xs={12}>
                            <Stack
                                spacing={3}
                                direction="row"
                                alignItems={"center"}
                            >
                                <Backbutton
                                    onClick={() => {
                                        // if (!action?.isFromSearch) {
                                        //   //   dispatch(resetLeads());
                                        //   console.log("assignee: ", searchParams.get("assignee"));
                                        // }
                                        navigate(`/`);
                                    }}
                                />
                                <Typography
                                    color="primary"
                                    sx={{
                                        fontSize: "28px",
                                        fontWeight: 700,
                                        mb: "12px",
                                    }}
                                >
                                    Detalle de la orden
                                </Typography>
                            </Stack>
                        </Grid>
                        {!!selectedOrder &&
                            !loadingOrder &&
                            !loadingSmartbit &&
                            !loadingCurrentEntity &&
                            !loadingInitialEntities && (
                                <>
                                    <Box
                                        sx={{
                                            borderBottom: 1,
                                            borderColor: "divider",
                                        }}
                                    >
                                        <StyledTabs
                                            value={value}
                                            onChange={handleChange}
                                        >
                                            <StyledTab
                                                label={
                                                    <Box>
                                                        Consultar orden de
                                                        servicio
                                                    </Box>
                                                }
                                                {...a11yProps(0)}
                                            />
                                            <StyledTab
                                                label={<Box>Historial</Box>}
                                                {...a11yProps(1)}
                                            />
                                        </StyledTabs>
                                    </Box>
                                    <TabPanel value={value} index={0}>
                                        <ConsultProvider />
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        <HistoryOrder />
                                    </TabPanel>
                                </>
                            )}
                        {(loadingOrder ||
                            loadingSmartbit ||
                            loadingCurrentEntity ||
                            loadingInitialEntities) && <LoadingOrder />}
                    </>
                )}
                {showDetails && <Details />}
            </motion.div>
        </AnimatePresence>
    );
};
