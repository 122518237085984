import { Typography } from "@enerbit/base";
import { type FC, useEffect, useState } from "react";
import { EEntity, getLeadHistory, getLeadsHistory } from "../../../helpers";
import { useAppSelector } from "../../../store";

interface Props {
    assignee_id?: string;
    name_witness?: string | null;
    phone_contact_witness?: string | null;
    order_entities?: { assignee_id: string; entity_id: string }[];
}

export const HistoryServiceInfo: FC<Props> = ({
    assignee_id,
    name_witness,
    phone_contact_witness,
    order_entities,
}) => {
    const [user, setUser] = useState<{
        name: string;
        email: string;
    } | null>(null);
    const [leads, setLeads] = useState<
        {
            name: string;
            email: string;
        }[]
    >([]);
    const [isLoading, setIsLoading] = useState(false);

    const { entity, addedEntities } = useAppSelector(
        (state) => state.initialForm,
    );

    const getLead = async (assignee_id: string) => {
        setIsLoading(true);
        const data = await getLeadHistory(assignee_id);
        setIsLoading(false);
        setUser(data);
    };

    useEffect(() => {
        if (entity && assignee_id) {
            if (entity === EEntity.Lead) {
                getLead(assignee_id);
            } else if (entity === EEntity.Client) {
                setIsLoading(true);
                const client = addedEntities.find(
                    ({ id }) => id === assignee_id,
                );
                setIsLoading(false);
                setUser({
                    name: client?.full_name ?? "",
                    email: client?.email ?? "",
                });
            }
        }
    }, [entity, assignee_id]);

    useEffect(() => {
        const fetchLeads = async () => {
            if (order_entities) {
                setIsLoading(true);
                const data = await getLeadsHistory(
                    order_entities.map(({ assignee_id }) => assignee_id),
                );
                setIsLoading(false);
                setLeads(data);
            }
        };

        fetchLeads();
    }, [order_entities]);

    if (!assignee_id && !order_entities) return null;
    return (
        <div>
            {!isLoading ? (
                <>
                    <Typography
                        color="primary"
                        sx={{ fontSize: "18px", fontWeight: 700, mb: "12px" }}
                    >
                        Información del servicio
                    </Typography>
                    {user && (
                        <p>
                            <b>Nombre:</b> {user.name}
                            <br />
                            <b>Correo electrónico:</b> {user.email}
                        </p>
                    )}
                    {entity === EEntity.Smartbit && (
                        <p>
                            <b>id:</b> {assignee_id}
                        </p>
                    )}
                    {leads &&
                        leads.map((entity, index) => (
                            <div key={index}>
                                <p>
                                    <b>Nombre {index + 1}:</b> {entity.name}
                                    <br />
                                    <b>Correo electrónico {index + 1}:</b>{" "}
                                    {entity.email}
                                </p>
                            </div>
                        ))}
                    {name_witness && (
                        <Typography
                            color="primary"
                            sx={{
                                fontSize: "18px",
                                fontWeight: 700,
                                mb: "12px",
                            }}
                        >
                            Contacto testigo
                        </Typography>
                    )}
                    {name_witness && (
                        <p>
                            <b>Nombre testigo:</b> {name_witness}
                        </p>
                    )}
                    {phone_contact_witness && (
                        <p>
                            <b>Teléfono testigo:</b> {phone_contact_witness}
                        </p>
                    )}
                </>
            ) : (
                "Cargando..."
            )}
        </div>
    );
};
