import {
    Box,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    type SelectChangeEvent,
} from "@enerbit/base";
import { filterActivitiesByEntityAndRole } from "../../../helpers";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
    addLead,
    selectActivity,
    setAction,
} from "../../../store/slices/initial-form/initialForm";

export const CreateByActivity = () => {
    const { loading } = useAppSelector((state) => state.tableAddress);
    const {
        listActivities,
        action,
        activity,
        addedEntities,
        loadingActivities,
        loadingEntities,
    } = useAppSelector((state) => state.initialForm);
    const { role } = useAppSelector((state) => state.roles);
    const dispatch = useAppDispatch();

    const handleActivityChange = (event: SelectChangeEvent<string>) => {
        const newActivity = event.target.value;
        dispatch(selectActivity(newActivity));
        if (activity) {
            if (action) {
                dispatch(addLead(addedEntities[0]));
                dispatch(
                    setAction({
                        ...action,
                    }),
                );
            }
        }
    };

    return (
        <Box>
            <Grid
                container
                spacing={3}
                py={3}
                display="flex"
                alignItems={"start"}
            >
                <Grid item lg={6} xs={12}>
                    <InputLabel className="title-input-edition" required>
                        Actividad de la orden a crear
                    </InputLabel>
                    <FormControl fullWidth>
                        <Select
                            disabled={
                                loadingActivities || loadingEntities || loading
                            }
                            value={activity?.name}
                            onChange={handleActivityChange}
                            id="activity"
                            sx={{ borderRadius: "14px" }}
                            variant="outlined"
                            fullWidth
                        >
                            {filterActivitiesByEntityAndRole(
                                listActivities,
                                role,
                                action?.entity,
                            ).map(({ name }, index) => (
                                <MenuItem key={index.toString()} value={name}>
                                    {name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </Box>
    );
};
