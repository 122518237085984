import type { ComponentType } from "react";
import type {
    IControlType,
    IJsonForm,
} from "../../../models/IDetailsCertificates";
import {
    ArrayForm,
    CheckboxForm,
    DateForm,
    ImageForm,
    ImageSealForm,
    MapForm,
    MatrixForm,
    PhaseForm,
    SealsForm,
    SelectForm,
    SignalForm,
    SignatureForm,
    SwitchForm,
    TextForm,
    TitleForm,
} from "./items";

export const ComponentMap: Record<IControlType, ComponentType<IJsonForm>> = {
    text: TextForm,
    email: TextForm,
    number: TextForm,
    "text-area": TextForm,
    select: SelectForm,
    image: ImageForm,
    "image-seal": ImageSealForm,
    date: DateForm,
    switch: SwitchForm,
    array: ArrayForm,
    matrix: MatrixForm,
    geolocation: MapForm,
    seals: SealsForm,
    title: TitleForm,
    signature: SignatureForm,
    phase: PhaseForm,
    signal: SignalForm,
    checkbox: CheckboxForm,
};
