type activityTypes = {
    [key: string]: string;
};

export const activityDictionary: activityTypes = {
    "Cambio de medidor": "Normalización",
    "Cambio de comercializador a enerBit - Individual": "Instalación",
    "Inspección previa - Individual": "Inspección",
    "Actualización de datos del cliente y su sistema de medida":
        "Normalización",
    "Revisión por desviación del consumo de energía": "Normalización",
    "Cancelación de cuenta": "",
    "Daño en sistema de medida": "Normalización",
    "Inspección de interventoria": "",
    "Nuevo servicio - Individual": "Instalación",
    "Normalización del sistema de comunicación": "Normalización",
    "Reconexión del servicio de energía": "Reconexión",
    "Revisión por PQR": "Normalización",
    "Seguimiento de la suspensión del servicio de energía": "Suspensión",
    "Suspensión del servicio de energía": "Suspensión",
    "Pruebas y/o cambio de transformadores de corriente": "",
    "Pruebas y/o cambio de transformadores de tensión": "",
    "Normalización de servicio cliente notificado": "Normalización",
    "Revisión o normalización preventiva del sistema de medida":
        "Normalización",
    "Instalación servicio de energía digital": "",
    "Cambio de comercializador a enerBit - Gabinete": "Instalación",
    "Inspección por gabinete": "",
    "Nuevo servicio - Gabinete": "Instalación",
    "Acompañamiento e inspección visual": "news-report",
    "Atención de emergencias": "news-report",
    "Modo planta": "Modo planta",
    "Cambio de smartBit por gabinete": "Instalación",
    "Cesión de servicio - Individual": "Instalación",
    "Cesión de servicio - Gabinete": "Instalación",
    "Nuevo servicio desenergizado - Gabinete": "Instalación",
    "Nuevo servicio desenergizado - Individual": "Inspección",
    "Normalización por código de medida o preventiva": "Normalización",
    "Verificación y/o actualización de datos del cliente y su sistema de medida":
        "Normalización",
};
