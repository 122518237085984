import { Grid, Skeleton } from "@enerbit/base";
import { OrderFilterSkeleton } from ".";
import { LoadingOrderItem } from "./LoadingOrderItem";

export const OrderContainerSkeleton = () => {
    const fakeArray = new Array(5).fill(0);

    return (
        <Grid container item spacing={3}>
            <Grid item xs={12}>
                <OrderFilterSkeleton />
            </Grid>
            <Grid item container xs={12} spacing={3}>
                {fakeArray.map((_, index) => (
                    <Grid key={index} item>
                        <Skeleton
                            variant="rectangular"
                            width={150}
                            height={20}
                        />
                    </Grid>
                ))}
            </Grid>
            <Grid
                container
                item
                spacing={3}
                style={{ display: "flex", alignItems: "stretch" }}
            >
                <LoadingOrderItem />
            </Grid>
            <Grid item xs={12}>
                <Skeleton variant="rectangular" height={40} />
            </Grid>
        </Grid>
    );
};
