import { Box, Grid, Typography } from "@enerbit/base";
import { useAppDispatch, useAppSelector } from "../../../store";
import { setAction } from "../../../store/slices/initial-form/initialForm";
import { CreateByActivity } from "../activity";
import Backbutton from "../back-button/BackButton";
import { CreateProvider } from "../form-provider";
import OrderDescription from "../order/OrderDescription";

export const CreateOrder = () => {
    const { activity, entity } = useAppSelector((state) => state.initialForm);

    const dispatch = useAppDispatch();

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                        <Backbutton onClick={() => dispatch(setAction(null))} />
                        <Typography
                            color="primary"
                            sx={{ fontSize: "28px", fontWeight: 700 }}
                        >
                            Generar orden de servicio
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
            <CreateByActivity />
            {activity && entity && (
                <OrderDescription activityCode={activity.code} />
            )}
            {activity && entity && <CreateProvider />}
        </>
    );
};
