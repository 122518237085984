import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import { getOrders } from ".";
import type { EEntity } from "../../../helpers";
import type { IDateRange, IListOrdersState } from "../../../models";

const initialState: IListOrdersState = {
    orders: {
        LD: {
            listOrders: [],
            totalOrders: 1,
            loadingOrder: false,
            errorOrder: false,
            message: "",
            dateRange: {
                startDate: "",
                endDate: "",
            },
            selectedOrderStatus: [],
            page: 1,
            size: 9,
        },
        MP: {
            listOrders: [],
            totalOrders: 1,
            loadingOrder: false,
            errorOrder: false,
            message: "",
            dateRange: {
                startDate: "",
                endDate: "",
            },
            selectedOrderStatus: [],
            page: 1,
            size: 9,
        },
        SMB: {
            listOrders: [],
            totalOrders: 1,
            loadingOrder: false,
            errorOrder: false,
            message: "",
            dateRange: {
                startDate: "",
                endDate: "",
            },
            selectedOrderStatus: [],
            page: 1,
            size: 9,
        },
    },
};

const listOrders = createSlice({
    name: "listOrders",
    initialState,
    reducers: {
        resetOrder: (state, { payload }: PayloadAction<EEntity>) => {
            const currentListOrders = state.orders[payload].listOrders;
            state.orders[payload] = {
                ...initialState.orders[payload],
                listOrders: currentListOrders,
            };
        },
        setDateRange: (
            state,
            { payload }: PayloadAction<{ range: IDateRange; entity: EEntity }>,
        ) => {
            state.orders[payload.entity].page = 1;
            state.orders[payload.entity].dateRange = payload.range;
        },
        setPage: (
            state,
            { payload }: PayloadAction<{ page: number; entity: EEntity }>,
        ) => {
            state.orders[payload.entity].page = payload.page;
        },
        resetPages: (state) => {
            state.orders["LD"].page = 1;
            state.orders["MP"].page = 1;
            state.orders["SMB"].page = 1;
        },
        setSelectedOrderStatus: (
            state,
            { payload }: PayloadAction<{ status: string[]; entity: EEntity }>,
        ) => {
            state.orders[payload.entity].page = 1;
            state.orders[payload.entity].selectedOrderStatus = payload.status;
        },
        resetListOrders: () => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getOrders.pending, (state, { meta }) => {
            const entity: EEntity = meta.arg.entity;
            if (entity) {
                state.orders[entity] = {
                    ...state.orders[entity],
                    listOrders: [],
                    loadingOrder: true,
                    errorOrder: false,
                    message: "",
                };
            }
        });

        builder.addCase(getOrders.fulfilled, (state, { payload, meta }) => {
            const entity: EEntity = meta.arg.entity;
            const dateRange: IDateRange = meta.arg.dateRange;
            const size = meta.arg.size;
            const status = meta.arg.status;
            if (entity) {
                state.orders[entity] = {
                    ...state.orders[entity],
                    size: size ?? 9,
                    dateRange,
                    selectedOrderStatus: status ? [status] : [],
                    listOrders: payload.orders,
                    totalOrders: payload.totalOrders,
                    loadingOrder: false,
                    errorOrder: false,
                    message: "",
                };
            }
        });

        builder.addCase(getOrders.rejected, (state, { meta }) => {
            const entity: EEntity = meta.arg.entity;
            if (entity) {
                state.orders[entity].loadingOrder = false;
                state.orders[entity].errorOrder = true;
                state.orders[entity].totalOrders = 1;
            }
        });
    },
});

export const {
    resetOrder,
    resetListOrders,
    setDateRange,
    setSelectedOrderStatus,
    setPage,
    resetPages,
} = listOrders.actions;

export default listOrders.reducer;
