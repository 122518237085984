import type { IJsonForm } from "../../models/IDetailsCertificates";

export const diagrams: IJsonForm[] = [
    {
        label: "Diagrama de conexión",
        name: "connection_diagram.picture_url",
        type: "image",
    },
    {
        label: "Diagrama unifilar",
        name: "line_diagram.picture_url",
        type: "image",
    },
];
