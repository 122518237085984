/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    Box,
    CheckIcon,
    ContentCopyIcon,
    IconButton,
    Tooltip,
    Typography,
    enerbitColors,
} from "@enerbit/base";
import { useState } from "react";
import { Handle, type NodeProps, Position } from "reactflow";
import { useAppSelector } from "../../../store";

export const SmartbitNode = ({ data }: NodeProps<string>) => {
    const { edges } = useAppSelector((state) => state.initialForm);

    const [copied, setCopied] = useState(false); // Nuevo estado para controlar el ícono

    const handleCopy = () => {
        navigator.clipboard.writeText(data);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    };

    return (
        <Box
            sx={{
                width: "100px",
                borderColor: "red",
                backgroundColor: "#fff",
                borderRadius: "3px",
            }}
        >
            <Box
                sx={{
                    width: "100%",
                    padding: "5px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "end",
                }}
            >
                <Typography
                    sx={{
                        fontSize: "7px",
                        color: enerbitColors.primary.main,
                    }}
                >
                    smartBit
                </Typography>
                <Tooltip title={copied ? "Copiado" : ""} arrow placement="top">
                    <IconButton
                        sx={{ fontSize: "7px" }}
                        size="small"
                        onClick={handleCopy}
                    >
                        {copied ? (
                            <CheckIcon
                                sx={{
                                    fontSize: "7px",
                                    color: enerbitColors.primary.main,
                                }}
                            />
                        ) : (
                            <ContentCopyIcon
                                sx={{
                                    fontSize: "7px",
                                    color: enerbitColors.primary.main,
                                }}
                            />
                        )}
                    </IconButton>
                </Tooltip>
            </Box>
            <Box
                sx={{
                    backgroundColor: "#EFE9FB",
                    padding: "5px",
                    height: "35px",
                    borderRadius: "0px 0px 3px 3px",
                    textAlign: "center",
                    overflow: "hidden",
                }}
            >
                <Typography
                    color={"primary"}
                    fontWeight={700}
                    sx={{ fontSize: "8px" }}
                >
                    {data}
                </Typography>
            </Box>
            {edges && edges?.length > 0 && (
                <Handle type="source" position={Position.Top} id="a" />
            )}
        </Box>
    );
};
