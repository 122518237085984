/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
import { Grid, Typography } from "@enerbit/base";
import { type FC, useEffect } from "react";
import {
    MapContainer,
    Marker,
    Popup,
    TileLayer,
    ZoomControl,
    useMap,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
interface Props {
    latitude: number;
    longitude: number;
}

interface ChangeViewProps {
    center: [number, number];
    zoom: number;
}

const ChangeView: FC<ChangeViewProps> = ({ center, zoom }) => {
    const map = useMap();
    useEffect(() => {
        map.flyTo(center, zoom);
    }, [center, zoom, map]);

    return null;
};

export const MapOrder: FC<Props> = ({ latitude, longitude }) => {
    return (
        <Grid container item xs={12} spacing={3}>
            <Grid item>
                <Typography
                    component="span"
                    variant="h6"
                    sx={{ color: "#53358E", fontWeight: "bold" }}
                >
                    Mapa
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <MapContainer
                    style={{ borderRadius: "14px", height: "300px", zIndex: 0 }}
                    center={[latitude, longitude]}
                    zoom={10}
                    zoomControl={false}
                    scrollWheelZoom={true}
                    attributionControl={false}
                >
                    <ChangeView center={[latitude, longitude]} zoom={10} />
                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                    <Marker
                        position={[latitude, longitude]}
                        icon={L.icon({
                            iconUrl:
                                "https://ebdevmicrofront.blob.core.windows.net/resources/bombi-cheerful.webp",
                            iconSize: [40, 60],
                        })}
                    >
                        <Popup>Tú Estás Aquí</Popup>
                    </Marker>
                    <ZoomControl position="bottomright" />
                </MapContainer>
            </Grid>
        </Grid>
    );
};
