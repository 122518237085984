import { Grid, InputLabel, TextField, Typography } from "@enerbit/base";
import { get, useFormContext } from "react-hook-form";
import { useAppSelector } from "../../../store";

export const Observations = () => {
    const {
        register,
        formState: { errors },
    } = useFormContext();
    const { action } = useAppSelector((state) => state.initialForm);

    return (
        <Grid item xs={12}>
            <Typography
                color="primary"
                sx={{ fontSize: "28px", fontWeight: 400, mb: "12px" }}
            >
                Observaciones de la orden
            </Typography>
            <Grid container rowSpacing={3} columnSpacing={5}>
                <Grid item xs={12}>
                    <InputLabel className="title-input-edition">
                        Observación
                    </InputLabel>
                    <TextField
                        fullWidth
                        id="observation"
                        disabled={action?.type === "Consult"}
                        type="description"
                        sx={{ "& fieldset": { borderRadius: "14px" } }}
                        {...register("form_data.description")}
                        error={!!get(errors, "form_data.description")}
                        helperText={
                            !!get(errors, "form_data.description") &&
                            get(errors, "form_data.description").message
                        }
                        variant="outlined"
                        rows={4}
                        multiline
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};
