import {
    Badge,
    Box,
    Button,
    FilterListIcon,
    Menu,
    MenuItem,
    enerbitColors,
} from "@enerbit/base";
import { type FC, useState } from "react";

interface DynamicFilterProps {
    options: { key: string; value: string }[];
    selected: string[];
    onSelect: (values: string[]) => void;
    label: string;
    isMultiple?: boolean;
    fullWidth?: boolean;
    disabled?: boolean;
    isUnSelectable?: boolean;
}

export const DynamicFilter: FC<DynamicFilterProps> = ({
    options,
    selected,
    onSelect,
    label = "Seleccione",
    isMultiple = false,
    fullWidth = false,
    disabled = false,
    isUnSelectable = true,
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleSelection = (value: string) => {
        let newSelected = [...selected];

        if (isMultiple) {
            if (newSelected.includes(value)) {
                newSelected = newSelected.filter((item) => item !== value);
            } else {
                newSelected.push(value);
            }
        } else {
            if (newSelected.includes(value)) {
                if (isUnSelectable) {
                    newSelected = [];
                }
            } else {
                newSelected = [value];
            }
        }
        onSelect(newSelected);
        handleClose();
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    let selectedLabel = label;
    if (!isMultiple && selected.length > 0) {
        selectedLabel =
            options.find((opt) => opt.value === selected[0])?.key || label;
    }

    return (
        <Box sx={{ width: "100%" }}>
            <Badge
                className={`${selected.length > 0 ? "ping" : ""}`}
                color="primary"
                badgeContent={isMultiple ? "" : undefined}
                variant="dot"
                invisible={selected.length === 0}
                sx={{ width: "100%" }}
            >
                <span
                    className={`${selected.length > 0 ? "ping-animation" : ""}`}
                ></span>
                <Button
                    size="large"
                    fullWidth={fullWidth}
                    disabled={disabled}
                    sx={{
                        border: "1px solid #D0D5DD",
                        width: fullWidth ? "100%" : "auto",
                        whiteSpace: "nowrap",
                    }}
                    startIcon={<FilterListIcon sx={{ mt: -0.5 }} />}
                    onClick={handleClick}
                >
                    {selectedLabel}
                </Button>
            </Badge>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                {options.map((option) => (
                    <MenuItem
                        key={option.key}
                        onClick={() => handleSelection(option.value)}
                        sx={
                            selected.includes(option.value)
                                ? {
                                      background: enerbitColors.primary.main,
                                      color: "white",
                                      ":hover": {
                                          background:
                                              enerbitColors.primary.main,
                                          color: "white",
                                      },
                                  }
                                : {}
                        }
                    >
                        {option.key}
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
};
